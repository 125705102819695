export type FormField = RadioField | SelectField | TextField | UploadField | NumberField | ButtonField | LabelField;

type BaseField = {
	type: string;
	label: string;
	grid?: {
		xs?: number;
		sm?: number;
		md?: number;
		lg?: number;
		xl?: number;
	};
	helperText?: string;
	required?: boolean;
	dependsOn?: {
		[key: string]: string | string[];
	};
	default?: string | number;
};

type LabelField = BaseField & {
	type: 'label';
};

type RadioField = BaseField & {
	type: 'radio';
	direction?: string;
	options: {
		label: string;
		value: string;
		dependsOn?: string | string[];
		default?: boolean;
	}[];
};

type SelectField = BaseField & {
	type: 'select';
	options: {
		label: string;
		value: string;
		dependsOn?: string | string[];
		default?: boolean;
	}[];
};

type TextField = BaseField & {
	type: 'text';
	suggestions?: {
		label: string;
		value: string;
	}[];
	multiline?: boolean;
	rows?: number;
	color?: 'primary' | 'secondary';
	variant?: 'contained' | 'outlined' | 'text';
};

type UploadField = BaseField & {
	type: 'upload';
	showChips?: boolean;
	accept?: string;
};

type NumberField = BaseField & {
	type: 'number';
	min?: number;
	max?: number;
};

type ButtonField = BaseField & {
	type: 'button';
	color?: 'primary' | 'secondary';
	variant?: 'contained' | 'outlined' | 'text';
	onClick?: () => void;
};

export const FormFields = {
	mvp: {
		meshType: {
			type: 'radio',
			label: 'What do you want to create?',
			grid: { xs: 12 },
			direction: 'row',
			options: [
				{
					label: 'Human',
					value: 'human',
					default: true,
				},
				{
					label: 'Animal',
					value: 'animal',
				},
				{
					label: 'Object',
					value: 'object',
				},
			],
		},
		baseMeshPath: {
			type: 'select',
			label: 'Select base model',
			grid: { xs: 12 },
			options: [],
		},
		looksLikePrompt: {
			type: 'text',
			label: 'What does your model look like?',
			grid: { xs: 12 },
			multiline: true,
			rows: 3,
			suggestions: [
				{
					label: 'Viking',
					value: 'Viking',
				},
				{
					label: 'Spiderman',
					value: 'Spiderman',
				},
				{
					label: 'Armor',
					value: 'Armor',
				},
			],
		},
		movesLikePrompt: {
			type: 'text',
			label: 'How does your model move?',
			grid: { xs: 12 },
			suggestions: [
				{
					label: 'Walking',
					value: 'Walking',
				},
				{
					label: 'Standing',
					value: 'Standing',
				},
				{
					label: 'Waving',
					value: 'Waving',
				},
				{
					label: 'Running',
					value: 'Running',
				},
				{
					label: 'Dancing',
					value: 'Dancing',
				},
			],
			dependsOn: {
				meshType: 'human',
			},
		},
		meshQuality: {
			type: 'radio',
			label: 'Mesh Quality',
			direction: 'row',
			grid: { xs: 12 },
			options: [
				{
					label: 'Low',
					value: 'low',
				},
				{
					label: 'Medium',
					value: 'medium',
					default: true,
				},
				{
					label: 'High',
					value: 'high',
				},
			],
		},
		estimationLabel: {
			type: 'label',
			label: 'Estimated Time: 1 minute',
			grid: { xs: 12 },
		},
	},
	textToMesh: {
		textPrompt: {
			label: 'Text prompt for making mesh',
			type: 'text',
			grid: { xs: 12 },
			multiline: true,
			rows: 4,
			default: '3D colorful figurine, detailed, soft lighting, soft diffuse lighting',
		},
		num_iters: {
			label: 'Iterations',
			type: 'number',
			grid: { xs: 12, md: 6 },
			default: 100,
			helperText: '10 = Fast, 100 = Medium, 400 = High',
		},
		seed: {
			label: 'Seed',
			type: 'number',
			grid: { xs: 12, md: 6 },
			helperText: 'Any unsigned integer',
			default: 1,
		},
		meshType: {
			label: 'Mesh type',
			type: 'radio',
			grid: { xs: 12 },
			direction: 'row',
			options: [
				{
					label: 'Human',
					value: 'human',
					default: true,
				},
				{
					label: 'Animal',
					value: 'animal',
				},
				{
					label: 'Object',
					value: 'object',
				},
			],
		},
		meshSelection: {
			label: 'How you want to select the mesh?',
			type: 'radio',
			grid: { xs: 12 },
			direction: 'row',
			options: [
				{
					label: 'Generate mesh',
					value: 'generateMesh',
				},
				{
					label: 'Select mesh',
					value: 'selectMesh',
					default: true,
				},
				{
					label: 'Auto Select',
					value: 'autoGenerate',
				},
			],
		},
		category: {
			label: 'Category',
			type: 'text',
			grid: { xs: 12 },
			dependsOn: {
				meshSelection: 'autoGenerate',
			},
		},
		uploadImage: {
			label: 'Upload image',
			type: 'upload',
			showChips: true,
			grid: { xs: 12 },
			dependsOn: {
				meshSelection: 'generateMesh',
			},
		},
		gridSize: {
			label: 'Grid size',
			type: 'number',
			grid: { xs: 12 },
			default: 64,
			dependsOn: {
				meshSelection: 'generateMesh',
			},
		},
		meshDensity: {
			label: 'Mesh density',
			type: 'number',
			grid: { xs: 12 },
			default: 0.5,
			dependsOn: {
				meshSelection: 'generateMesh',
			},
		},
		baseMeshPath: {
			label: 'Base mesh path',
			grid: { xs: 12 },
			options: [],
			type: 'select',
			dependsOn: {
				meshSelection: 'selectMesh',
			},
		},
		symmetry: {
			label: 'Symmetry',
			type: 'select',
			grid: { xs: 12 },
			options: [
				{
					label: 'True',
					value: 'true',
					default: true,
				},
				{
					label: 'False',
					value: 'false',
				},
			],
			helperText: 'Controls symmetric deformation',
		},

		sigma: {
			label: 'Sigma',
			type: 'number',
			default: 1.5,
			grid: { xs: 12 },
			helperText:
				'Controls the amount of deformation, should be between 0.1 <= 5, 0.1 = gentle deformation, 5 = rough deformation',
		},
	},
};
