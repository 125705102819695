import { accessTokenProvider } from '@mpx-sdk/api-client';
import firebase from '@mpx-sdk/helpers/firebase';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { urls } from '@mpx-sdk/shared/configs';
import { CookieNames } from '@mpx-sdk/shared/configs/cookies';
import Env from '@mpx-sdk/shared/configs/env';
import { convertKeysToCamelCase, isBrowser, stringToBase64 } from '@mpx-sdk/shared/utils';
import auth0, { type WebAuth } from 'auth0-js';
import axios from 'axios';
import { deleteCookie } from 'cookies-next';
import { IncomingMessage, ServerResponse } from 'http';
import Router from 'next/router';
import { DataLayer } from '../measurement';

type LoginOptions = { returnTo?: string; res?: ServerResponse<IncomingMessage>; screenHint?: 'signup' };

class AuthHelper {
	auth0: WebAuth;

	options: {
		domain: string;
		clientID: string;
		redirectUri: string;
		audience: string;
		responseType: string;
		scope: string;
	};

	constructor() {
		this.options = {
			domain: Env.AUTH0.DOMAIN,
			clientID: Env.AUTH0.CLIENT_ID,
			redirectUri: `${Env.AUTH0.BASE_URL}/api/auth/callback`,
			audience: Env.AUTH0.AUDIENCE,
			responseType: 'code',
			scope: 'openid profile email offline_access',
		};
		this.auth0 = new auth0.WebAuth(this.options);
	}

	async logout({ returnTo, res }: { returnTo: string; res?: ServerResponse }) {
		deleteCookie(CookieNames.JWT, { res, domain: Env.COOKIE_DOMAIN });
		deleteCookie(CookieNames.REFRESH_TOKEN, { res, domain: Env.COOKIE_DOMAIN });
		accessTokenProvider.setAccessToken('');

		if (isBrowser()) {
			clearAccessCookies();

			if (typeof window !== 'undefined' && window?.vuplex) {
				window.vuplex.postMessage({
					type: 'auth-logout',
					logout: true,
				});
			}

			firebase.logout();
			this.auth0.logout({
				returnTo,
			});
		} else if (res) {
			res.writeHead(307, {
				Location: this.auth0.client.buildLogoutUrl({
					...this.options,
					returnTo,
				}),
			}).end();
		}
	}

	getLoginUrl(options?: Exclude<LoginOptions, 'res'>) {
		return UIHelper.isInAppBrowser()
			? `${Env.MPX_WEBSITE}/${urls.routes.deviceLogin}`
			: this.auth0.client.buildAuthorizeUrl({
					...this.options,
					state: stringToBase64(JSON.stringify({ returnTo: options?.returnTo })),
			  });
	}

	getSignupUrl() {
		return this.login({
			screenHint: 'signup',
		});
	}

	login(options?: LoginOptions) {
		const { returnTo, res, screenHint } = options ?? {};
		const url = this.getLoginUrl({
			returnTo,
		});

		if (isBrowser()) {
			if (UIHelper.isInAppBrowser()) {
				Router.push(url);
			} else {
				this.auth0.authorize({
					appState: { returnTo },
					screen_hint: screenHint,
				});
			}
		} else if (res) {
			res
				?.writeHead(307, {
					Location: url,
				})
				?.end();
		}
	}

	async getDeviceLoginCode() {
		const url = `${Env.AUTH0.DOMAIN}/oauth/device/code`;
		const res = await axios.post(
			url,
			{
				client_id: Env.AUTH0.IN_APP_CLIENT_ID,
				audience: this.options.audience,
				scope: this.options.scope,
			},
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			},
		);

		return convertKeysToCamelCase(res.data);
	}
}
const Auth0Helper = new AuthHelper();

export default Auth0Helper;

export function clearAccessCookies() {
	if (isBrowser()) {
		// Remove the following cookies
		const cookiesToRemove = [CookieNames.JWT, CookieNames.REFRESH_TOKEN];

		cookiesToRemove.forEach((cookie) => {
			deleteCookie(cookie, {
				domain: Env.COOKIE_DOMAIN,
			});

			// TODO: Remove after month, 11 November 2023, otherwise existing user's won't be able to logout
			deleteCookie(cookie);
		});

		// Track the eventvscode-file://vscode-app/c:/Users/Brinx/AppData/Local/Programs/Microsoft%20VS%20Code/resources/app/out/vs/code/electron-sandbox/workbench/workbench.html
		DataLayer.triggerMeasurementEvent('authEvent', {
			event_name: 'clear_access_cookies',
		});
	}
}

export * from './sketchfab-oauth';
