import { CookieNames } from '@mpx-sdk/shared/configs/cookies';

export function getInAppDataCookie() {
	// Check if document cookie exists and if so, grab CookieNames.APP_DATA
	// Check if the cookie is a string, if so, check if it is a valid JSON string or just a string
	// If it is a valid JSON string, parse it and return it
	// If it is just a string, return it
	if (typeof window !== 'undefined' && typeof document !== 'undefined' && document.cookie) {
		const cookie = document.cookie.split('; ').find((cookie) => cookie.startsWith(CookieNames.APP_DATA));

		if (cookie) {
			const cookieValue = cookie.split('=')[1];

			if (cookieValue) {
				try {
					const parsedCookie = JSON.parse(cookieValue);
					return parsedCookie;
				} catch (e) {
					return cookieValue;
				}
			}
		}
	}

	return null;
}
