import { Chargebee } from '@mpx-sdk/helpers/chargebee';
import { getBalanceByCurrency, getMyBalance } from '@mpx-sdk/helpers/economy';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { CloseIcon, CreditsIcon } from '@mpx-sdk/images';
import { availableCreditsAtom, userAtom, userHistoryAtom } from '@mpx-sdk/shared/atoms';
import { ChargebeePlans } from '@mpx-sdk/shared/configs';
import { GradientTypography } from '@mpx-sdk/ui/components/core';
import { useDialogService } from '@mpx-sdk/ui/components/core/DialogService';
import IconText from '@mpx-sdk/ui/components/core/IconText';
import CreditPlanCard from '@mpx-sdk/ui/components/purchase/CreditPlanCard';
import PurchaseSuccess from '@mpx-sdk/ui/components/purchase/PurchaseSuccess';
import { Alert, Grid, Hidden, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { ReactElement, useEffect } from 'react';

export default function BuyMoreCredit({ flashMessage }: { flashMessage?: string }): ReactElement {
	const availableCredits = useAtomValue(availableCreditsAtom);
	const currentUser = useAtomValue(userAtom);
	const userHistory = useAtomValue(userHistoryAtom);
	const { hideDialog, showDialog } = useDialogService();

	useEffect(() => {
		Chargebee.init();
	}, []);

	const handleBuyCredit = async (planId: string, quantity: number) => {
		DataLayer.triggerMeasurementEvent('buyEvent', {
			event_name: 'add_to_Cart',
			bundle_type: planId,
			bundle_amount: quantity,
			user_id: currentUser?.id,
			user_number_of_created_models: userHistory?.length ?? 0,
			...availableCredits,
		});

		Chargebee.openCheckout(
			{
				planId,
				quantity,
			},
			{
				onCheckoutComplete: () => {
					getMyBalance({
						waitForNewBalance: true,
					});

					DataLayer.triggerMeasurementEvent('buyEvent', {
						event_name: 'incremental_credit',
						bundle_type: planId,
						bundle_amount: quantity,
						user_id: currentUser?.id,
						user_number_of_created_models: userHistory?.length ?? 0,
						...availableCredits,
					});

					showDialog({ title: '', content: <PurchaseSuccess /> });
				},
			},
		);
	};

	return (
		<Stack textAlign='left'>
			{/* Close button that exists in the top right of the dialog */}
			<Tooltip arrow describeChild title='Close dialog'>
				<IconButton
					disableRipple
					onClick={hideDialog}
					sx={{
						position: 'absolute',
						top: 10,
						right: 10,
						'&:hover': {
							color: 'primary.main',
							svg: {
								fill: 'primary.main',
							},
						},
					}}
				>
					<CloseIcon sx={{ mt: 1, fontSize: '2rem' }} />
				</IconButton>
			</Tooltip>

			<Typography fontWeight='bold' mb={2} textAlign='center' variant='h4'>
				Unlock{' '}
				<GradientTypography component='span' variant='inherit'>
					Limitless Creativity!
				</GradientTypography>
			</Typography>

			<IconText
				flexDirection={{
					xs: 'column',
					sm: 'row',
				}}
				rowGap={0.5}
				sx={{
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<b>You currently have :</b>
				<span>
					<Hidden smDown> &nbsp;</Hidden>
				</span>
				<IconText fontWeight='500'>
					<CreditsIcon /> &nbsp;{getBalanceByCurrency('credits', availableCredits) || 0}&nbsp; credits left
				</IconText>
			</IconText>

			{flashMessage && (
				<Alert severity='error' sx={{ mt: 2, mr: 'auto' }}>
					{flashMessage}
				</Alert>
			)}

			<Grid alignItems='stretch' columnSpacing={3} container pb={2} pt={2} rowSpacing={1}>
				<Grid item xs={12}>
					<Typography fontWeight='bold' textAlign='center'>
						Monthly Credit Packs
					</Typography>
					<Typography mb={1} textAlign='center'>
						<i> All prices in USD</i>
					</Typography>
				</Grid>
				{[
					ChargebeePlans.GENAI.SMALL_MONTHLY,
					ChargebeePlans.GENAI.MEDIUM_MONTHLY,
					ChargebeePlans.GENAI.LARGE_MONTHLY,
				].map((plan) => (
					<Grid item lg={4} xs={12}>
						<CreditPlanCard onButtonClick={handleBuyCredit} plan={plan} />
					</Grid>
				))}
				<Grid item xs={12}>
					<Typography fontWeight='bold' mb={2} mt={2} textAlign='center'>
						On-demand Credit Packs
					</Typography>
				</Grid>
				<Grid item lg={4} mx='auto' xs={12}>
					<CreditPlanCard onButtonClick={handleBuyCredit} plan={ChargebeePlans.GENAI.PAY_AS_YOU_GO} />
				</Grid>
			</Grid>
		</Stack>
	);
}

BuyMoreCredit.getAsDialog = (props?: { flashMessage?: string }) => ({
	content: <BuyMoreCredit flashMessage={props?.flashMessage} />,
	maxWidth: {
		xs: '90%',
		sm: '80%',
		md: '67%',
	},
	maxHeight: {
		xs: '90%',
	},
});
