import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export class VERSION {
	static readonly MONO = publicRuntimeConfig?.version ?? 'unknown';

	static readonly API_DB = '2.2.4';

	static readonly MPX_WEB = '1.6.3';

	static readonly GENAI_WEB = '1.0.4';

	static readonly FIREBASE_FUNCTIONS = '2.0.2';
}
