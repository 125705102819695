import { FormControl, InputLabel, MenuItem, Select as MuiSelect } from '@mui/material';

interface Option {
	value: string;
	name: string;
}

interface ISelectProps {
	label: string;
	options: Option[];
}

export default function Select({ label, options, ...props }: ISelectProps) {
	return (
		<FormControl fullWidth>
			<InputLabel sx={{ color: '#A9B1C7' }}>{label}</InputLabel>
			<MuiSelect label={label} {...props}>
				{options?.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.name}
					</MenuItem>
				))}
			</MuiSelect>
		</FormControl>
	);
}
