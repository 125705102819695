/**
 * Main Purpose: This component should center the data vertically and horizontally on page.
 */
import classNames from 'classnames';

type Props = {
	className?: string;
	children: React.ReactNode;
};

function Page({ className, children }: Props) {
	return <div className={classNames('mps-page', className)}>{children}</div>;
}

export default Page;
