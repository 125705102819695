export function downloadFile(url: string, fileName?: string) {
	// Create a link element
	const link = document.createElement('a');
	link.href = url;
	if (fileName) {
		link.download = fileName;
	}

	// Dispatch a click event on the link element
	// This triggers the download
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}
