import { inAppBrowserAtom, store } from '@mpx-sdk/shared/atoms';
import { isEmpty, isEqual, isString } from 'lodash';

export function cleanURLSearchParams(urlData?: URL) {
	const url = urlData || new URL(window.location.href);

	// If only search param that exists is 'f', remove it
	if (url.searchParams.has('f') && Object.keys(url.searchParams).length === 1) {
		url.searchParams.delete('f');
	}

	// If URL has no search params but ends with '?', remove it
	if (url.href.endsWith('?') && isEmpty(url.searchParams)) {
		url.href = url.href.slice(0, -1);
	}

	if (typeof window !== 'undefined' && window.history) {
		window.history.replaceState({}, '', url.toString());
	}
}

/**
 * Cleans the inputted query from the URL's search param, if exists
 * @example <caption>Example case if the URL includes c=rig and you want that remove</caption>
 * cleanSearchParamFromURLQuery('c'); // or cleanSearchParamFromURLQuery(['c']); - string or array of strings are accepted
 * // returns null (does not return anything but removes the c query param from the URL)
 */
export function cleanSearchParamFromURLQuery(query: string | string[]) {
	if (query) {
		const url = new URL(window.location.href);

		if (Array.isArray(query)) {
			query.forEach((q) => {
				url.searchParams.delete(q);
			});
		} else {
			url.searchParams.delete(query);
		}

		cleanURLSearchParams(url);
	}
}

export function cleanDefaultCategoryFromURLQuery(newCategory?: string | string[], inApp = store.get(inAppBrowserAtom)) {
	const defaultCategory = inApp ? ['model'] : ['model', 'rig', 'animation', 'material'];
	const url = new URL(window.location.href);

	if (newCategory || url.searchParams.has('c')) {
		let category: string | string[] | null = newCategory || url.searchParams.get('c');

		if (isString(category)) {
			category = category.toLowerCase().trim().split(',');
		}

		category = category?.map((item) => item.trim()).filter((item) => item !== '') || [];
		category = [...new Set(category)];

		if (isEqual(category?.sort(), defaultCategory?.sort())) {
			url.searchParams.delete('c');
		}
	}

	cleanURLSearchParams(url);
}

/** Removes the trailing slash and normal an URL string */
export function normalizeHrefString(href: string): string {
	if (!href) {
		return '/';
	}

	if (href.length === 1) {
		return href;
	}

	return href.endsWith('/') ? href.slice(0, -1) : href;
}
