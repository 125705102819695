/* eslint-disable react/no-invalid-html-attribute */
import { notificationCountAtom } from '@mpx-sdk/shared/atoms';
import { useAtomValue } from 'jotai';
import { isEmpty, uniq } from 'lodash';
import Head from 'next/head';
import { ReactNode } from 'react';

interface MetaHeadProps {
	children?: ReactNode;
	keywords?: string[];
	title?: string;
	description?: string;
	/** URL string for the page */
	url?: string;
	/** URL string for image banner */
	image?: string;
	/**
	 * @default 'jpg'
	 */
	imageType?: string;
	/**
	 * Date in string format
	 * @default '2020-02-25'
	 */
	publishDate?: string;
}

const defaultMetaHeadProps: MetaHeadProps = {
	description: `Masterpiece X is the 3D creation platform for modern creators. Generate. Edit. Share & Use.`,
	image: '/icon/og.png',
	imageType: 'jpg',
	keywords: [],
	publishDate: '2020-02-25',
	title: '',
	url: 'https://www.masterpiecex.com/',
};

/** Generate meta tags for the document's head */
function MetaHead({ children, description, image, imageType, keywords, publishDate, title, url }: MetaHeadProps) {
	const notificationCount = useAtomValue(notificationCountAtom);

	// Add default keywords with the ones passed in props
	keywords = uniq([
		'Creator',
		'Masterpiece Creator',
		'Masterpiece',
		'Indie',
		'Masterpiece Studio',
		'Masterpiece X',
		'Studio',
		'VR 3D',
		'indie creators',
		'Creating 3D',
		'Creative Suite',
		'Creative',
		'Masterpiece Motion',
		'professional',
		'Suite',
		'full 3D pipeline',
		'up professional',
		'intuitive and easy',
		'rig',
		'tools',
		'intuitive',
		'Professional Intuitive',
		'animate',
		'texture',
		'material',
		'skin',
		'sculpt',
		...(keywords ?? []),
	]);

	title = !isEmpty(title)
		? `${notificationCount > 0 ? `(${notificationCount}) ` : ''}${title} | Masterpiece X`
		: 'Masterpiece X';
	description = !isEmpty(description) ? description : defaultMetaHeadProps.description;
	image = !isEmpty(image) ? image : defaultMetaHeadProps.image;
	imageType = !isEmpty(imageType) ? imageType : defaultMetaHeadProps.imageType;
	publishDate = !isEmpty(publishDate) ? publishDate : defaultMetaHeadProps.publishDate;
	url = !isEmpty(url) ? url : defaultMetaHeadProps.url;

	return (
		<Head>
			<title key='title'>{title}</title>

			<meta key='meta-description' content={description} name='description' />
			<meta key='meta-keywords' content={keywords.join(', ')} name='keywords' />

			<meta key='apple-mobile-web-app-title' content={title} name='apple-mobile-web-app-title' />

			<meta key='og:description' content={description} property='og:description' />
			<meta key='og:image' content={image} property='og:image' />
			<meta key='og:image:alt' content={description ?? title} property='og:image:alt' />
			<meta key='og:image:type' content={`image/${imageType}`} property='og:image:type' />
			<meta key='og:image:url' content={image} property='og:image:url' />
			<meta key='og:image:url:secure_url' content={image} property='og:image:url:secure_url' />
			<meta key='og:site_name' content={title} property='og:site_name' />
			<meta key='og:title' content={title} property='og:title' />
			<meta key='og:type' content='website' property='og:type' />
			<meta key='og:url' content={url} property='og:url' />

			<meta
				key='article-published_time'
				content={`${publishDate}T00:00:00+00:00`}
				name='article-published_time'
				property='article:published_time'
			/>
			<meta
				key='og:publish_date'
				content={`${publishDate}00:00:00Z`}
				name='publish_date'
				property='og:publish_date'
			/>

			<link key='rel-image_src' href={image} rel='image_src' />
			<meta key='itemProp:description' content={description} itemProp='description' />
			<meta key='itemProp:image' content={image} itemProp='image' />
			<meta key='itemProp:name' content={title} itemProp='name' />
			<meta key='itemProp:thumbnailUrl' content={image} itemProp='thumbnailUrl' />
			<meta key='itemProp:url' content={url} itemProp='url' />

			<meta key='twitter:card' content='summary_large_image' property='twitter:card' />
			<meta key='twitter:creator' content='@withMPStudio' name='twitter:creator' />
			<meta key='twitter:description-name' content={description} name='twitter:description' />
			<meta key='twitter:description-property' content={description} property='twitter:description' />
			<meta key='twitter:domain' content='masterpiecex.com' name='twitter:domain' />
			<meta key='twitter:image-name' content={image} name='twitter:image' />
			<meta key='twitter:image-property' content={image} property='twitter:image' />
			<meta key='twitter:site' content='@withMPStudio' name='twitter:site' />
			<meta key='twitter:title-name' content={title} name='twitter:title' />
			<meta key='twitter:title-property' content={title} property='twitter:title' />
			{/* <meta key='twitter:url-name' content='https://twitter.com/withMPStudio' name='twitter:url' />
			<meta key='twitter:url-property' content={url} property='twitter:url' /> */}

			{children}

			<script
				key='structured-data'
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{
					__html: JSON.stringify({
						'@context': 'http://schema.org',
						'@type': 'WebSite',
						url,
						name: title,
						description,
						publisher: {
							'@type': 'Organization',
							name: 'Masterpiece X',
						},
						image,
					}),
				}}
				type='application/ld+json'
			/>
		</Head>
	);
}

export default MetaHead;
