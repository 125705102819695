import { DialogServiceProvider } from '@mpx-sdk/ui/components/core/DialogService';
import GlobalDialogs from '@mpx-sdk/ui/components/dialogs/GlobalDialogs';
import MainNavbar from '@mpx-sdk/ui/components/navigation/MainNavbar';
import SubNavbar from '@mpx-sdk/ui/components/navigation/SubNavbar';
import { Box } from '@mui/material';
import { useRouter } from 'next/router';
import { ToastContainer } from 'react-toastify';

function Layout({ children }: { children: React.ReactNode }) {
	const router = useRouter();

	return (
		<div
			className='main-content'
			id='content'
			style={{
				display: 'flex',
				flexDirection: 'column',
				maxWidth: '100vw',
				height: '100vh !important',
				scrollBehavior: 'smooth',
				overflow: 'hidden',
			}}
		>
			<DialogServiceProvider>
				<>
					{!router.pathname.includes('/auth') && (
						<header>
							<MainNavbar appType='genai' />
						</header>
					)}

					<Box
						component='main'
						sx={{
							height: '100% !important',
							maxHeight: '100% !important',
							flex: '1 1 auto !important',
							'& ::-webkit-scrollbar': {
								width: '8px !important',
							},
						}}
					>
						<ToastContainer
							aria-live='polite'
							autoClose={5000}
							closeOnClick
							draggable
							newestOnTop={false}
							pauseOnFocusLoss={false}
							position='bottom-right'
							role='status'
							toastStyle={{ backgroundColor: 'white' }}
						/>

						{children}

						<GlobalDialogs />
					</Box>

					{router.pathname.startsWith('/genai') && <SubNavbar />}
				</>
			</DialogServiceProvider>
		</div>
	);
}

export default Layout;
