import { PlanOptions } from '@api-client/models/subscription.model';
import BaseService from '@api-client/services/base.service';

class EconomyService extends BaseService {
	async getHostedPageUrl(planOptions: PlanOptions) {
		return this.post(`/subscription/generate-hp-url`, planOptions);
	}

	async getManagePortalUrl() {
		return this.post(`/subscription/portal-session`);
	}
}

const economyService = new EconomyService();

export default economyService;
