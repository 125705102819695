import { userService } from '@mpx-sdk/api-client';
import { availableCreditsAtom, store } from '@mpx-sdk/shared/atoms';
import { wait } from '@mpx-sdk/shared/utils';
import { find, isEqual } from 'lodash';

export const getBalanceByCurrency = (currency: string, balances: any) => {
	if (!balances) {
		return null;
	}
	// console.log(balances);
	const { balance } = find(balances.currencies, { currency }) || {};

	return balance ?? null;
};

export const getMyBalance = async (options?: { waitForNewBalance?: boolean }) => {
	let { waitForNewBalance = false } = options ?? {};
	const initialBalance = store.get(availableCreditsAtom);
	// Adding loop count to prevent infinite loop
	let loopCount = 0;
	let balance = await userService.getMyBalance();

	if (waitForNewBalance) {
		store.set(availableCreditsAtom, null);
	}

	do {
		loopCount += 1;

		if (!isEqual(balance, initialBalance)) {
			waitForNewBalance = false;
			store.set(availableCreditsAtom, balance);
			break;
		}
		// eslint-disable-next-line no-await-in-loop
		await wait(1000);
		// eslint-disable-next-line no-await-in-loop
		balance = await userService.getMyBalance();
	} while (waitForNewBalance && loopCount < 15);

	// if (loopCount >= 10) {
	// 	console.log('Balance not updated', balance, initialBalance);
	// }
	return balance;
};
