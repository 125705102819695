import { accessTokenProvider, rolesService, userService } from '@api-client/index';
import AdminLayout from '@genai/components/layouts/AdminLayout';
import Layout from '@genai/components/layouts/Layout';
import api from '@genai/services/api';
import firebase from '@mpx-sdk/helpers/firebase';
import {
	adminFeaturesAtom,
	availableCreditsAtom,
	singleAssetViewAtom,
	store,
	userAtom,
	userRolesAtom,
} from '@mpx-sdk/shared/atoms';
import { CookieNames } from '@mpx-sdk/shared/configs';
import Env from '@mpx-sdk/shared/configs/env';
import AnalyticsWrapper from '@mpx-sdk/ui/components/analytics/AnalyticsWrapper';
import MetaHead from '@mpx-sdk/ui/components/core/MetaHead';
import '@mpx-sdk/ui/styles';
import { darkTheme } from '@mpx-sdk/ui/themes';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { Provider } from 'jotai';
import { NextPage } from 'next';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import { useRouter } from 'next/router';
import { ReactElement, ReactNode, useEffect } from 'react';

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
	getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
	const router = useRouter();
	const { initialState } = pageProps || {};

	useEffect(() => {
		firebase.initialize();
		if (initialState?.firebaseCustomToken) {
			firebase.signInWithCustomToken(initialState?.firebaseCustomToken);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const theme = createTheme(darkTheme, {
		components: {
			MuiTextField: {
				styleOverrides: {
					root: {
						'& .MuiOutlinedInput-root': {
							backgroundColor: '#ffffff',
							color: 'black',
						},
					},
				},
			},
		},
	});

	store.set(adminFeaturesAtom, initialState?.adminView);
	store.set(availableCreditsAtom, initialState?.balance);
	store.set(singleAssetViewAtom, null);
	store.set(userAtom, initialState?.currentUser);
	store.set(userRolesAtom, initialState?.currentUser?.roles || {});

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />

			<MetaHead />

			<Provider store={store}>
				<AnalyticsWrapper />

				<Layout>
					{router.pathname.includes('/admin') ? (
						<AdminLayout>
							<div>
								<Component {...pageProps} />
							</div>
						</AdminLayout>
					) : (
						<Component {...pageProps} />
					)}
				</Layout>
			</Provider>
		</ThemeProvider>
	);
}

MyApp.getInitialProps = async (appContext: AppContext) => {
	const { ctx } = appContext;
	const { req, res } = ctx;

	const appProps = await App.getInitialProps(appContext);
	const pageProps: any = {
		...appProps,
	};

	// Code is running on client side
	if (!req) {
		return { pageProps };
	}

	pageProps.initialState = {};

	Env.setEnvVariables(process.env);

	const refreshToken = getCookie(CookieNames.REFRESH_TOKEN, { req, res });
	const accessToken = getCookie(CookieNames.JWT, { req, res }) as string;
	const deleteAuthCookies = () => {
		deleteCookie(CookieNames.JWT, { req, res, domain: Env.COOKIE_DOMAIN });
		deleteCookie(CookieNames.REFRESH_TOKEN, { req, res, domain: Env.COOKIE_DOMAIN });
		// TODO: Remove after month, 11 November 2023, otherwise existing user's won't be able to logout
		deleteCookie(CookieNames.JWT, { req, res });
		deleteCookie(CookieNames.REFRESH_TOKEN, { req, res });
		accessTokenProvider.setAccessToken('');
	};

	if (!accessToken && !refreshToken) {
		return {};
	}

	accessTokenProvider.setAccessToken(accessToken);

	try {
		const isValidToken = await accessTokenProvider.isValidToken();

		if (!isValidToken) {
			if (refreshToken) {
				const accessToken = await accessTokenProvider.getNewAccessToken(req.headers.cookie);

				if (accessToken) {
					setCookie(CookieNames.JWT, accessToken, {
						httpOnly: false,
						req,
						res,
						domain: Env.COOKIE_DOMAIN,
					});

					accessTokenProvider.setAccessToken(accessToken);
				} else {
					deleteAuthCookies();
					return {};
				}
			} else {
				deleteAuthCookies();
				return {};
			}
		}

		const [currentUser, balance, firebaseCustomToken] = await Promise.all([
			userService.getMyProfile(),
			userService.getMyBalance(),
			api.getFirebaseToken(accessToken),
		]);

		pageProps.initialState.currentUser = currentUser;
		pageProps.initialState.balance = balance;
		pageProps.initialState.firebaseCustomToken = firebaseCustomToken;

		const roles = await rolesService.getMyRoles(pageProps.initialState.currentUser.id);

		if (roles) {
			pageProps.initialState.currentUser.roles = roles;

			// Admin view
			let adminView = false;
			const allowedRoles = ['labs', 'library', 'giveRoles', 'inDevelopment'];

			// Check in roles and see if any of the `allowedRoles` booleans are true
			adminView = allowedRoles.some((role) => roles[role]);
			pageProps.initialState.adminView = adminView;
		}
	} catch (err) {
		console.error(err);
		deleteAuthCookies();
		return {};
	}

	return {
		pageProps,
	};
};

export default MyApp;
