export const socialConfig = [
	{
		name: 'twitter',
		label: 'Twitter',
		placeholder: 'username',
		maxLength: 15,
	},
	{
		name: 'instagram',
		label: 'Instagram',
		placeholder: 'username',
		maxLength: 30,
	},
	{
		name: 'facebook',
		label: 'Facebook',
		placeholder: 'username OR ID',
		maxLength: 50,
	},
	{
		name: 'youtube',
		label: 'YouTube',
		placeholder: 'username',
		maxLength: 20,
	},
	{
		name: 'linkedin',
		label: 'LinkedIn',
		placeholder: 'username',
		maxLength: 60,
	},
	{
		name: 'github',
		label: 'GitHub',
		placeholder: 'username',
		maxLength: 39,
	},
	{
		name: 'sketchfab',
		label: 'Sketchfab',
		placeholder: 'username',
		maxLength: 128,
	},
	{
		name: 'artstation',
		label: 'ArtStation',
		placeholder: 'username',
		maxLength: 60,
	},
	{
		name: 'website',
		label: 'Website',
		placeholder: 'https://www.example.com',
		maxLength: 128,
	},
];
