import { LinkProps } from '@mui/material';
import MuiLink from '@mui/material/Link';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

function Link({ children, href, ...rest }: LinkProps & NextLinkProps) {
	return (
		<MuiLink color='secondary.contrastText' component={NextLink} href={href} underline='hover' {...rest}>
			{children}
		</MuiLink>
	);
}

export default Link;
