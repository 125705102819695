/* eslint-disable no-nested-ternary */
import { convertBytesToSize } from '@mpx-sdk/helpers/assets';
import { singleAssetViewAtom, singleAssetViewMetadataAtom } from '@mpx-sdk/shared/atoms';
import LinkWrapper from '@mpx-sdk/ui/components/core/LinkWrapper';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Tooltip, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { isEmpty } from 'lodash';
import { ReactElement, useMemo } from 'react';

/** A component that displays the metrics of an asset. */
export default function Information(): ReactElement | null {
	const projectData = useAtomValue(singleAssetViewAtom);
	const savMetaData = useAtomValue(singleAssetViewMetadataAtom);

	function getMadeInLink(source: string) {
		if (['Masterpiece X', 'MPX', 'pc'].includes(source)) {
			return 'https://www.masterpiecex.com/';
		}

		if (source === 'Masterpiece Studio') {
			return 'https://www.masterpiecex.com/masterpiece-studio-pro';
		}

		if (source === 'MPX GenAI') {
			return 'https://www.masterpiecex.com/generate';
		}

		if (source === 'https://thebasemesh.com/') {
			return 'https://thebasemesh.com/';
		}

		return null;
	}

	function getMadeInReadable(source: string) {
		if (['Masterpiece X', 'MPX'].includes(source)) {
			return 'Masterpiece X';
		}

		if (source === 'Masterpiece Studio') {
			return 'Masterpiece Studio Pro';
		}

		if (source === 'MPX GenAI') {
			return 'Masterpiece X - Generate';
		}

		if (source === 'pc') {
			return 'Masterpiece X - PC Import';
		}

		if (source === 'https://thebasemesh.com/') {
			return 'THE BASE MESH';
		}

		return null;
	}

	/**
	 * Computes the metrics for a project based on its data.
	 * @returns An object containing various metrics of the project.
	 */
	const metrics = useMemo(() => {
		if (!projectData) {
			return {};
		}

		/** Specify the file types of interest */
		const fileTypes = ['glb', 'gltf', 'fbx', 'obj', 'stl'];

		/** Find the file of the specified file type */
		const fileType = projectData.files?.find((asset: { name: string }) =>
			fileTypes.includes(asset.name?.split('.').pop() ?? ''),
		)?.name;

		/** Compute the size of the 3D model file */
		const objSize = fileType
			? projectData.files?.find((asset: { name: any }) => asset.name === fileType)?.size ?? 0
			: 0;

		/** Get the metadata files and extract the metadata */
		const metadataFiles: any = projectData.files?.find((asset) => asset.metadata)?.metadata ?? {};

		/** Get the metadata */
		const metadata: any = {
			...projectData?.metadata,
			...savMetaData,
		};

		let madeIn: string | null = null;
		if (projectData?.madeIn) {
			if (Array.isArray(projectData.madeIn)) {
				// eslint-disable-next-line prefer-destructuring
				madeIn = projectData.madeIn[0];
			} else {
				madeIn = projectData.madeIn;
			}
		} else if (projectData?.source) {
			madeIn = projectData.source;
		} else if (projectData?.path) {
			madeIn = 'MPX';
		}

		return {
			'Project ID': {
				value: projectData?.id,
				tooltip: 'Unique ID of the project',
			},
			Category: {
				value: projectData.category ?? null,
				tooltip: 'Category the project is listed under',
			},
			'Made In': {
				value: getMadeInReadable(madeIn ?? ''),
				tooltip:
					'Where the project was made (e.g. Masterpiece X, Masterpiece Gen AI, Masterpiece Studio, etc.)',
				href: getMadeInLink(madeIn ?? ''),
			},
			'3D Model File Size': {
				value: convertBytesToSize(objSize),
				tooltip: 'Size of the 3D model file(s) (the .glb or .gltf file) in the project',
			},
			'Number of files': {
				value: projectData.files?.length ?? null,
				tooltip: 'Total number of files in the project',
			},
			'Number of vertices': {
				value: metadataFiles?.num_verts ?? metadata?.num_verts ?? null,
				tooltip:
					'The number of vertices (points) that make up the 3D model. Vertices define the shape of the model and are connected by edges and faces to form a mesh',
			},
			'Number of faces': {
				value: metadataFiles?.num_faces ?? null,
				tooltip:
					'The number of faces (polygons) that make up the 3D model. Faces are flat surfaces defined by three or more vertices and are used to create the surface of the 3D model',
			},
			'Number of triangles': {
				value: metadata?.num_triangles ?? null,
				tooltip:
					'The number of triangles that make up the 3D model. Triangles are the simplest polygon and are made up of three vertices and three edges',
			},
			'Number of unique materials': {
				value: metadataFiles?.num_unique_mats ?? null,
				tooltip:
					'The number of unique materials (textures, colors, or other properties) that are applied to the surfaces of the 3D model to create its appearance. Materials can be combined and modified to achieve a wide range of visual effects',
			},
			'Number of rigs': {
				value: metadataFiles?.num_rigs ?? null,
				tooltip:
					'The number of rigs (skeletal structures) that are used to animate the 3D model. A rig defines the movement and behavior of the 3D model and is made up of bones, joints, and other elements',
			},
			'Animation prompt': {
				value: metadata?.genAIData?.Animation ?? null,
				tooltip: 'The animation prompt used to generate the animation',
			},
			'Paint negative prompt': {
				value: metadata?.genAIData?.['Paint Does Not Look Like'] ?? null,
				tooltip: 'The negative paint prompt used to generate the 3D model',
			},
		};
	}, [projectData, savMetaData]);

	return !isEmpty(metrics) ? (
		<Accordion
			className='sav-metrics-accordion'
			sx={{
				backgroundColor: 'transparent',
				border: 'none !important',
				position: 'inherit !important',
			}}
		>
			<AccordionSummary
				aria-controls='panel1a-content'
				expandIcon={<ExpandMoreIcon />}
				id='panel1a-header'
				sx={{
					flexDirection: 'row-reverse ',
					'& .MuiAccordionSummary-content': {
						marginLeft: '5% !important',
					},
				}}
			>
				<Typography gutterBottom variant='h6'>
					More Information
				</Typography>
			</AccordionSummary>

			<AccordionDetails>
				<Stack alignItems='flex-start' direction='column' justifyContent='center' spacing={1}>
					{Object.entries(metrics).map(([key, data]) => {
						const { value, tooltip, hide, href } = data;

						if (value !== null && !hide) {
							return (
								<LinkWrapper
									key={`info-metric-${key}-project-${projectData?.id}-tooltip-link`}
									href={href ?? ''}
									openNewTab
								>
									<Tooltip
										key={`info-metric-${key}-project-${projectData?.id}-tooltip`}
										arrow
										describeChild
										placement='bottom'
										title={tooltip}
									>
										<Stack direction='row'>
											{/* Should be a bold key, followed-up by the value */}
											<Typography
												sx={{
													fontWeight: '500',
													color: (theme) => theme.palette.text.subheader,
													marginRight: '0.5rem',
												}}
											>
												{key}:{' '}
											</Typography>

											<Typography
												sx={{
													fontWeight: '500',
													color: (theme) =>
														href ? theme.palette.text.action : theme.palette.text.primary,
												}}
											>
												{typeof value === 'string' ? value?.trim() : value}
											</Typography>
										</Stack>
									</Tooltip>
								</LinkWrapper>
							);
						}

						return null;
					})}
				</Stack>
			</AccordionDetails>
		</Accordion>
	) : null;
}
