declare module '@mui/material/styles' {
	interface PaletteOptions {
		original?: {
			main?: string;
			contrastText?: string;
			light?: string;
		};

		icons?: {
			active?: string;
			edit?: string;
			home?: string;
			inactive?: string;
			greyed?: string;
		};

		assetOverlay?: {
			bg?: string;
			bgTransparent?: string;
		};

		gradient?: {
			main?: string;
			hover?: string;
			error?: string;
			home?: string;
			modal?: string;
			private?: string;
		};
		badge?: {
			notification?: string;
		};
		step?: {
			active?: string;
			completed?: string;
		};
		beta?: {
			text?: string;
		};
	}

	interface Palette {
		original: {
			main: string;
			contrastText: string;
			light: string;
		};

		icons: {
			active: string;
			edit: string;
			home: string;
			inactive: string;
			greyed: string;
		};

		assetOverlay: {
			bg: string;
			bgTransparent: string;
		};

		gradient: {
			main: string;
			hover: string;
			error: string;
			home: string;
			modal: string;
			private: string;
		};
		badge: {
			notification: string;
		};
		step: {
			active: string;
			completed: string;
		};
		beta: {
			text: string;
		};
	}

	interface TypeText {
		primary: string;
		action?: string;
		contrast?: string;
		disabled: string;
		secondary: string;
		subheader?: string;
		subtext?: string;
	}

	interface TypeBackground {
		default: string;
		offBlack?: string;
		assets?: string;
		cards?: string;
		dark?: string;
		info?: string;
		paper: string;
		border?: string;
	}
}

export * from './base';
export * from './dark';
export * from './light';
