import { UIHelper } from '@mpx-sdk/helpers/ui';
import Env from '@mpx-sdk/shared/configs/env';
import LinkWrapper from '@mpx-sdk/ui/components/core/LinkWrapper';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';

interface MPXLogoProps {
	/** What to do when a tab change button was pressed */
	handleTabChange?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newValue: number) => void;
}

export default function MPXLogo({ handleTabChange }: MPXLogoProps): ReactElement {
	const router = useRouter();
	const responsiveView = UIHelper.isResponsiveView();
	const { f: phaseDependentState } = router.query;

	return (
		<LinkWrapper href={Env.MPX_WEBSITE} preventNewTab>
			<Tooltip arrow describeChild title='Return to Masterpiece X home page'>
				<Stack
					alignItems='center'
					direction='row'
					justifyContent='flex-start'
					spacing={2}
					sx={{
						width: '100%',
						position: 'relative',
					}}
				>
					<IconButton
						className='navbar-logo-container'
						onClick={() => {
							// Redirect to library
							if (!phaseDependentState && handleTabChange) {
								handleTabChange(null, 0);
							}
						}}
						sx={{
							position: 'relative',
							cursor: 'pointer',
						}}
					>
						<Image
							alt='Masterpiece X'
							className='navbar-logo'
							height={45}
							loading='lazy'
							src='/logos/brand_x.svg'
							width={45}
						/>
					</IconButton>

					{responsiveView && (
						<Typography
							sx={{
								display: { xs: 'none', lg: 'initial' },
								fontFamily: 'Circe',
								fontSize: 'clamp(1rem, 1.5rem, 1.5rem)',
								margin: 'auto !important',
								paddingLeft: '0.25rem !important',
								textDecoration: 'none',
								textTransform: 'uppercase',
								whiteSpace: 'nowrap',
								'&::before': {
									content: '""',
									display: 'inline-block',
									height: '100%',
									verticalAlign: 'middle',
								},
							}}
						>
							Masterpiece X
						</Typography>
					)}
				</Stack>
			</Tooltip>
		</LinkWrapper>
	);
}
