import Auth0Helper from '@mpx-sdk/helpers/auth';
import { CookieNames } from '@mpx-sdk/shared/configs/cookies';
import Env from '@mpx-sdk/shared/configs/env';
import axios from 'axios';
import { getCookie } from 'cookies-next';

class AccessTokenProvider {
	private accessToken: string | undefined;

	getAccessToken(): string | undefined {
		return this.accessToken || getCookie(CookieNames.JWT) || '';
	}

	setAccessToken(accessToken: string) {
		this.accessToken = accessToken;
	}

	async isValidToken() {
		const accessToken = this.getAccessToken();
		if (!accessToken) {
			return false;
		}
		try {
			await axios.get(`${Env.AUTH0.DOMAIN}/userinfo`, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});
			return true;
		} catch (err) {
			return false;
		}
	}

	async getNewAccessToken(cookies?: string) {
		const headers = cookies ? { Cookie: cookies } : {};
		try {
			const {
				data: { accessToken },
			} = await axios.get(`${Env.AUTH0.BASE_URL}/api/auth/get-new-token`, {
				withCredentials: true,
				headers,
			});
			if (accessToken) {
				this.setAccessToken(accessToken);
			}
			return accessToken;
		} catch (err) {
			if (typeof window !== 'undefined') {
				Auth0Helper.login();
			}
			await new Promise((resolve) => {
				setTimeout(resolve, 2000);
			});
			return null;
		}
	}
}

export const accessTokenProvider = new AccessTokenProvider();
