import { inAppBrowserAtom, store } from '@mpx-sdk/shared/atoms';
import { AssetSlots, PublicAsset } from '@mpx-sdk/types';
import { downloadProjectFiles, isProjectDownloadable } from './download';
import { getUserSlots } from './getData';
import { updateUserProjectSlots } from './updateData';

interface HandleOpeningAssetParams {
	/** Project data to open and open/download */
	projectData: PublicAsset;
	/** Current user slots used */
	slots?: AssetSlots | null;
	/** Updater function to handle taking updated slots data */
	setSlots?: (data: AssetSlots | null) => void;
	/** Function to handle opening the max slots dialog */
	setMaxSlotsOpen?: (changeTo: boolean) => void;
	/** Whether the user is anonymous or not */
	anonymousUser?: boolean;
}

/**
 * Handle opening/downloading an asset
 */
export async function handleOpeningAsset({
	projectData,
	setMaxSlotsOpen,
	setSlots,
	slots,
}: HandleOpeningAssetParams): Promise<any> {
	// Only download if project is downloadable
	if (isProjectDownloadable(projectData)) {
		/** Is the files downloadable [true, default] or not [false] */
		let downloadFiles = true;
		/** Whether the slots should be updated/counted [true] or not [false, default] */
		let contProject = false;

		if (
			projectData?.category === 'model' &&
			!projectData?.tags?.includes('stamp') &&
			!Number.isNaN(Number(projectData?.id)) &&
			store.get(inAppBrowserAtom)
		) {
			// See if they have enough slots available
			if (slots?.slotsProjectsUsed && slots?.slotsProjects) {
				if (slots.slotsProjectsUsed < slots.slotsProjects) {
					contProject = true;

					setMaxSlotsOpen?.(false);
				} else if (slots.slotsProjectsUsed >= slots.slotsProjects) {
					downloadFiles = false;

					setMaxSlotsOpen?.(true);
				}
			}
		}

		if (downloadFiles) {
			// Download files
			await downloadProjectFiles(projectData, store.get(inAppBrowserAtom));

			// Update slots used locally
			if (contProject) {
				// Update slots used on server
				if (slots?.slotsProjectsUsed) {
					await updateUserProjectSlots(slots.slotsProjectsUsed + 1);
				}

				const newSlots = await getUserSlots();

				if (setSlots && newSlots) {
					setSlots(newSlots);
				}
			}
		}
	}
}
