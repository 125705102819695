import Auth0Helper from '@mpx-sdk/helpers/auth';
import { urls } from '@mpx-sdk/shared/configs/urls';
import { FirebaseCustomTokenResponse } from '@mpx-sdk/types';
import { accessTokenProvider } from './access-token.provider';
import BaseService from './base.service';

class AuthService extends BaseService {
	async getFirebaseCustomToken() {
		return (await this.get<FirebaseCustomTokenResponse>(urls.api.auth.getFirebaseCustomToken)).token;
	}

	async isValidToken() {
		const accessToken = accessTokenProvider.getAccessToken();
		if (!accessToken) {
			return false;
		}
		try {
			await Auth0Helper.auth0.client.userInfo(accessToken);
			return true;
		} catch (err) {
			return false;
		}
	}
}

const authService = new AuthService();

export default authService;
