import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactComponent as AlertCircle } from './icons/alert-circle.svg';
import { ReactComponent as AlertTriangle } from './icons/alert-triangle.svg';
import { ReactComponent as Arrow } from './icons/arrow.svg';
import { ReactComponent as ArtStationLogo } from './icons/artstation-logo.svg';
import { ReactComponent as Back } from './icons/back.svg';
import { ReactComponent as Billing } from './icons/billing.svg';
import { ReactComponent as Bookmark } from './icons/bookmark.svg';
import { ReactComponent as Close } from './icons/close.svg';
import { ReactComponent as Collaborate } from './icons/collaborate.svg';
import { ReactComponent as Credits } from './icons/credits-icon.svg';
import { ReactComponent as Delete } from './icons/delete.svg';
import { ReactComponent as Discord } from './icons/discord.svg';
import { ReactComponent as Download } from './icons/download.svg';
import { ReactComponent as Edit2 } from './icons/edit2.svg';
import { ReactComponent as Edit } from './icons/edit.svg';
import { ReactComponent as Export } from './icons/export.svg';
import { ReactComponent as Filter } from './icons/filter.svg';
import { ReactComponent as Flag } from './icons/flag.svg';
import { ReactComponent as History } from './icons/history.svg';
import { ReactComponent as Home } from './icons/home.svg';
import { ReactComponent as Import } from './icons/import.svg';
import { ReactComponent as Info } from './icons/info.svg';
import { ReactComponent as Learn } from './icons/learn.svg';
import { ReactComponent as Library } from './icons/library.svg';
import { ReactComponent as Lightbulb } from './icons/lightbulb.svg';
import { ReactComponent as Load } from './icons/load.svg';
import { ReactComponent as Login } from './icons/login.svg';
import { ReactComponent as LogoM } from './icons/logo-m.svg';
import { ReactComponent as LogoX } from './icons/logo-x.svg';
import { ReactComponent as Logout } from './icons/logout.svg';
import { ReactComponent as MagicWand } from './icons/magic_wand.svg';
import { ReactComponent as MagnifyingGlass } from './icons/magnifying-glass.svg';
import { ReactComponent as Maximize } from './icons/maximize.svg';
import { ReactComponent as Notifications } from './icons/notifications.svg';
import { ReactComponent as Open } from './icons/open.svg';
import { ReactComponent as Phase } from './icons/phase.svg';
import { ReactComponent as Play } from './icons/play.svg';
import { ReactComponent as Profile } from './icons/profile.svg';
import { ReactComponent as PurchaseSuccessful } from './icons/purchase-complete-check.svg';
import { ReactComponent as Refresh } from './icons/refresh.svg';
import { ReactComponent as RemixAI } from './icons/remix-ai.svg';
import { ReactComponent as Remix } from './icons/remix.svg';
import { ReactComponent as Search } from './icons/search.svg';
import { ReactComponent as Settings } from './icons/settings.svg';
import { ReactComponent as Share } from './icons/share.svg';
import { ReactComponent as SketchFabLogo } from './icons/sketchfab-logo.svg';
import { ReactComponent as Storage } from './icons/storage.svg';
import { ReactComponent as ThumbsDown } from './icons/thumbs-down.svg';
import { ReactComponent as ThumbsUp } from './icons/thumbs-up.svg';
import { ReactComponent as Upload } from './icons/upload.svg';
import { ReactComponent as Warning } from './icons/warning.svg';

function generateIconJSX(icon: any, props: any) {
	return <SvgIcon className='mpx-icons' component={icon} inheritViewBox {...props} />;
}

export const AlertCircleIcon = (props: SvgIconProps) => generateIconJSX(AlertCircle, props);
export const AlertTriangleIcon = (props: SvgIconProps) => generateIconJSX(AlertTriangle, props);
export const ArrowIcon = (props: SvgIconProps) => generateIconJSX(Arrow, props);
export const ArtStationLogoIcon = (props: SvgIconProps) => generateIconJSX(ArtStationLogo, props);
export const BackIcon = (props: SvgIconProps) => generateIconJSX(Back, props);
export const BillingIcon = (props: SvgIconProps) => generateIconJSX(Billing, props);
export const BookmarkIcon = (props: SvgIconProps) => generateIconJSX(Bookmark, props);
export const CloseIcon = (props: SvgIconProps) => generateIconJSX(Close, props);
export const CollaborateIcon = (props: SvgIconProps) => generateIconJSX(Collaborate, props);
export const DeleteIcon = (props: SvgIconProps) => generateIconJSX(Delete, props);
export const DiscordIcon = (props: SvgIconProps) => generateIconJSX(Discord, props);
export const DownloadIcon = (props: SvgIconProps) => generateIconJSX(Download, props);
export const Edit2Icon = (props: SvgIconProps) => generateIconJSX(Edit2, props);
export const EditIcon = (props: SvgIconProps) => generateIconJSX(Edit, props);
export const ExportIcon = (props: SvgIconProps) => generateIconJSX(Export, props);
export const FilterIcon = (props: SvgIconProps) => generateIconJSX(Filter, props);
export const FlagIcon = (props: SvgIconProps) => generateIconJSX(Flag, props);
export const HistoryIcon = (props: SvgIconProps) => generateIconJSX(History, props);
export const HomeIcon = (props: SvgIconProps) => generateIconJSX(Home, props);
export const ImportIcon = (props: SvgIconProps) => generateIconJSX(Import, props);
export const InfoIcon = (props: SvgIconProps) => generateIconJSX(Info, props);
export const LearnIcon = (props: SvgIconProps) => generateIconJSX(Learn, props);
export const LibraryIcon = (props: SvgIconProps) => generateIconJSX(Library, props);
export const LightbulbIcon = (props: SvgIconProps) => generateIconJSX(Lightbulb, props);
export const LoadIcon = (props: SvgIconProps) => generateIconJSX(Load, props);
export const LoginIcon = (props: SvgIconProps) => generateIconJSX(Login, props);
export const LogoMIcon = (props: SvgIconProps) => generateIconJSX(LogoM, props);
export const LogoXIcon = (props: SvgIconProps) => generateIconJSX(LogoX, props);
export const LogoutIcon = (props: SvgIconProps) => generateIconJSX(Logout, props);
export const MagnifyingGlassIcon = (props: SvgIconProps) => generateIconJSX(MagnifyingGlass, props);
export const MaximizeIcon = (props: SvgIconProps) => generateIconJSX(Maximize, props);
export const NotificationsIcon = (props: SvgIconProps) => generateIconJSX(Notifications, props);
export const OpenIcon = (props: SvgIconProps) => generateIconJSX(Open, props);
export const PhaseIcon = (props: SvgIconProps) => generateIconJSX(Phase, props);
export const PlayIcon = (props: SvgIconProps) => generateIconJSX(Play, props);
export const ProfileIcon = (props: SvgIconProps) => generateIconJSX(Profile, props);
export const RefreshIcon = (props: SvgIconProps) => generateIconJSX(Refresh, props);
export const RemixAIIcon = (props: SvgIconProps) => generateIconJSX(RemixAI, props);
export const RemixIcon = (props: SvgIconProps) => generateIconJSX(Remix, props);
export const SearchIcon = (props: SvgIconProps) => generateIconJSX(Search, props);
export const SettingsIcon = (props: SvgIconProps) => generateIconJSX(Settings, props);
export const ShareIcon = (props: SvgIconProps) => generateIconJSX(Share, props);
export const SketchFabLogoIcon = (props: SvgIconProps) => generateIconJSX(SketchFabLogo, props);
export const StorageIcon = (props: SvgIconProps) => generateIconJSX(Storage, props);
export const ThumbsDownIcon = (props: SvgIconProps) => generateIconJSX(ThumbsDown, props);
export const ThumbsUpIcon = (props: SvgIconProps) => generateIconJSX(ThumbsUp, props);
export const UploadIcon = (props: SvgIconProps) => generateIconJSX(Upload, props);
export const CreditsIcon = (props: SvgIconProps) => generateIconJSX(Credits, props);
export const PurchaseSuccessfulIcon = (props: SvgIconProps) => generateIconJSX(PurchaseSuccessful, props);
export const MagicWandIcon = (props: SvgIconProps) => generateIconJSX(MagicWand, props);
export const WarningIcon = (props: SvgIconProps) => generateIconJSX(Warning, props);
