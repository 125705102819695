/* eslint-disable no-nested-ternary */
import { normalizeHrefString } from '@mpx-sdk/helpers/url';
import { inAppBrowserAtom } from '@mpx-sdk/shared/atoms';
import Env from '@mpx-sdk/shared/configs/env';
import { useAtomValue } from 'jotai';
import { isEmpty } from 'lodash';
import Link, { LinkProps } from 'next/link';
import React, { ReactElement, ReactNode, Ref, forwardRef, useMemo } from 'react';

interface LinkWrapperProps extends LinkProps {
	/** Child elements to wrap */
	children: ReactNode | string;
	/** Whether to ignore the link [true] or not [false, default] */
	ignoreLink?: boolean;
	/** Whether to open the link in a new tab [true] or not [false, default] */
	openNewTab?: boolean;
	/** Whether to forcefully prevent the link from opening in a new tab [true] or not [false, default] */
	preventNewTab?: boolean;
	/** The URL for the link */
	href: string | URL | Url | LinkProps['href'];
}

/**
 * A wrapper component for the Next.js Link component.
 * @returns {ReactElement | null} The rendered component wrapped in a NextJS Link or just the children if there is no href
 */
const LinkWrapper = forwardRef(
	(
		{ children, ignoreLink = false, openNewTab = false, preventNewTab = false, href, ...props }: LinkWrapperProps,
		/** Ref for the wrapped component */
		ref: Ref<HTMLAnchorElement>,
	): ReactElement | null => {
		const memoizedChildren = useMemo(() => {
			if (typeof children === 'string') {
				return <span>{children}</span>;
			}
			return children;
		}, [children]);

		const inApp = useAtomValue(inAppBrowserAtom);

		// If there is no href, return the children or null
		if (!href || isEmpty(href)) {
			return React.cloneElement(children as React.ReactElement, { ref }) || null;
		}

		let shouldOpenInNewTab: boolean = openNewTab || !preventNewTab;
		let normalizedHref = href;

		const hrefString = href.pathname || href.toString();
		if (hrefString && hrefString !== '[object Object]') {
			// Check accepted in-house URLs:
			const acceptedInHouseUrls = [
				Env.MPX_WEBSITE,
				Env.MPX_GENAI_WEBSITE,
				Env.AUTH0.AUDIENCE,
				Env.AUTH0.BASE_URL,
				Env.AUTH0.DOMAIN,
			];
			if (typeof window !== 'undefined') {
				acceptedInHouseUrls.push(window.location.hostname);
			}

			// Remove any undefined URLs from the list
			acceptedInHouseUrls.filter((url) => url);

			// Check if the link should open in a new tab
			shouldOpenInNewTab = preventNewTab
				? false
				: openNewTab ||
				  Boolean(
						typeof window !== 'undefined' &&
							!ignoreLink &&
							href &&
							!acceptedInHouseUrls.some((url) => url && hrefString.startsWith(url.toString())),
				  );
		}

		if (typeof normalizedHref === 'string') {
			normalizedHref = normalizeHrefString(normalizedHref);
		} else if (normalizedHref.pathname) {
			normalizedHref.pathname = normalizeHrefString(normalizedHref.pathname);
		}

		// If there is a href, return a Link
		return (
			<Link
				{...props}
				href={normalizedHref}
				onClick={(event) => {
					if (ignoreLink || (inApp && shouldOpenInNewTab)) {
						event.preventDefault();
					}

					if (props.onClick) {
						props.onClick(event);
					}
				}}
				passHref
				rel={shouldOpenInNewTab ? 'noopener noreferrer' : undefined}
				shallow={false}
				style={{
					textDecoration: 'none',
					color: 'inherit',
				}}
				target={shouldOpenInNewTab ? '_blank' : undefined}
			>
				{React.cloneElement(memoizedChildren as React.ReactElement, { ref })}
			</Link>
		);
	},
);

LinkWrapper.defaultProps = {
	ignoreLink: false,
	openNewTab: false,
	preventNewTab: false,
};

LinkWrapper.displayName = 'LinkWrapper';

export default LinkWrapper;
