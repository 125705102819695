import Env from '@mpx-sdk/shared/configs/env';
import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { FirebaseStorage, ref, uploadBytes } from 'firebase/storage';

class Firebase {
	auth: app.auth.Auth;

	app: app.app.App;

	storage: FirebaseStorage;

	constructor() {
		this.app = this.initialize();
		this.auth = app.auth(this.app);
		this.storage = app.storage(this.app);
	}

	initialize() {
		if (!this.app && Env.FIREBASE_CONFIG) {
			return app.initializeApp(Env.FIREBASE_CONFIG);
		}

		return this.app;
	}

	async uploadFileToStorage(
		/**
		 *  Path to directory, without the filename, filename is added automatically by provided file
		 * 	Use options.customFileName to provide custom filename
		 */
		path: string,
		file: File,
		options: {
			customFileName?: string;
		} = {},
	) {
		const { customFileName } = options;
		const storageRef = ref(this.storage, `${path}/${customFileName || file.name}`);
		return uploadBytes(storageRef, file);
	}

	async signInWithCustomToken(token) {
		return this.auth.signInWithCustomToken(token);
	}

	getCurrentUser() {
		return this.auth.currentUser;
	}

	async logout() {
		return this.auth.signOut();
	}
}

const firebase = new Firebase();
export default firebase;
