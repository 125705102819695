import { accessTokenProvider } from '@mpx-sdk/api-client';
import {
	downloadProjectFiles,
	getProjectData,
	isProjectDownloadable,
	toggleLikeProject,
} from '@mpx-sdk/helpers/assets';
import { getMyBalance } from '@mpx-sdk/helpers/economy';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { BookmarkIcon, EditIcon, LoadIcon, OpenIcon, RemixIcon, ShareIcon } from '@mpx-sdk/images';
import {
	adminFeaturesAtom,
	availableCreditsAtom,
	inAppBrowserAtom,
	openShareDialogAtom,
	savEditMode,
	singleAssetViewAtom,
	userAtom,
	userHistoryAtom,
	userRolesAtom,
} from '@mpx-sdk/shared/atoms';
import Env from '@mpx-sdk/shared/configs/env';
import { downloadFile } from '@mpx-sdk/shared/utils';
import DeleteAsset from '@mpx-sdk/ui/components/delete-asset/DeleteAsset';
import SaveToGalleryButton from '@mpx-sdk/ui/components/single-asset-view/components/SaveToGalleryButton';
import { Grid, IconButton, Tooltip } from '@mui/material';
import axios from 'axios';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { findIndex, isEmpty, isNumber } from 'lodash';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

const moreInformationMapping = [
	{
		key: 'looksLikePrompt',
		label: 'Shape',
	},
	{
		key: 'promptPos',
		label: 'Paint Looks Like',
	},
	{
		key: 'promptNeg',
		label: 'Paint Does Not Look Like',
	},
	{
		key: 'movesLikePrompt',
		label: 'Animation',
	},
];

interface InteractionsProps {
	/** Use this to override the default download function */
	onDownload?: () => void;
}

/** A component that displays the interactions (download, share, etc...) for a project. */
export default function Interactions({ onDownload }: InteractionsProps): ReactElement | null {
	const [editMode, setEditMode] = useAtom(savEditMode);
	const [isBookmarked, setIsBookmarked] = useState(false);
	const [projectData, setProjectData] = useAtom(singleAssetViewAtom);
	const adminView = useAtomValue(adminFeaturesAtom);
	const availableCredits = useAtomValue(availableCreditsAtom);
	const currentUser = useAtomValue(userAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);
	const inApp = useAtomValue(inAppBrowserAtom);
	const responsiveView = UIHelper.isResponsiveView();
	const setOpenSocialSharing = useSetAtom(openShareDialogAtom);
	const userHistory = useAtomValue(userHistoryAtom);

	/** If the project is downloadable or not. */
	const projectDownloadable = useMemo(() => isProjectDownloadable(projectData ?? null) ?? false, [projectData]);

	/** The different options for the download button. */
	const displayOptions = useMemo(
		() => ({
			remix: {
				icon: <RemixIcon />,
				text: 'Remix',
				title: `Remix ${projectData?.title ?? 'this asset'} and make it your own`,
			},
			load: {
				icon: <LoadIcon />,
				text: 'Load',
				title: `Load ${projectData?.title ?? 'this asset'} type into your project`,
			},
			open: {
				icon: <OpenIcon />,
				text: 'Open',
				title: `Open and continue editing ${projectData?.title ?? 'this asset'}`,
			},
			download: {
				icon: <LoadIcon />,
				text: 'Download',
				title: `Download ${projectData?.title ?? 'this asset'} locally to your computer`,
			},
		}),
		[projectData?.title],
	);

	/** The button option to display. */
	const buttonOption = useMemo(() => {
		if (projectDownloadable && projectData) {
			if (inApp) {
				if (projectData.category === 'model') {
					if (projectData.tags?.includes('stamp')) {
						return displayOptions.load;
					}

					return displayOptions.remix;
				}

				if (projectData.path) {
					return displayOptions.open;
				}

				if (projectData.category) {
					return displayOptions.load;
				}
			} else if (!projectData.path) {
				return displayOptions.download;
			}
		}

		return null;
	}, [displayOptions, inApp, projectData, projectDownloadable]);

	async function downloadHistoryAsset(data: any) {
		try {
			const res = await axios.get(`${Env.MPS_API_HOST}/genai/download/${data.id}`, {
				headers: {
					Authorization: `Bearer ${accessTokenProvider.getAccessToken()}`,
				},
			});

			if (!res.data.skipDeduct) {
				await getMyBalance();
			}

			downloadFile(res?.data?.url, `${data?.projectTitle}.glb`);
			// downloadProjectFiles(projectData);

			DataLayer.triggerMeasurementEvent('downloadEvent', {
				event_name: 'download',
				status: 'success',
				id: data.id,
				user_id: currentUser?.id,
				user_number_of_created_models: userHistory?.length ?? 0,
				...availableCredits,
			});
		} catch (error: any) {
			console.error(error?.message || error);
			toast.error(error?.message || error);

			DataLayer.triggerMeasurementEvent('downloadEvent', {
				event_name: 'download',
				status: 'failed',
				id: data.id,
				user_id: currentUser?.id,
			});
		}
	}

	async function getBookmarkData() {
		if (projectData && currentUser && isNumber(projectData?.id)) {
			if (projectData.bookmarked) {
				setIsBookmarked(projectData.bookmarked);
			} else {
				const newProjectData: any = await getProjectData(
					{
						maxResults: 1,
						projectId: projectData.id,
					},
					currentUser?.id,
				);

				if (!isEmpty(newProjectData) && newProjectData?.[0]?.bookmarked !== isBookmarked) {
					setIsBookmarked(newProjectData[0].bookmarked);
				}
			}
		} else {
			setIsBookmarked(false);
		}
	}

	useEffect(() => {
		if (isNumber(projectData?.id)) {
			getBookmarkData();
		}
		if (isEmpty(projectData)) {
			setIsBookmarked(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectData]);

	return (
		<Grid
			alignItems='center'
			container
			data-testid='sav-interactions-button'
			direction='row'
			item
			justifyContent='flex-start'
			spacing={1}
			sx={{
				button: {
					fontSize: 'clamp(0.8rem, 1.2rem, 1.2rem)',
					height: '100%',
					margin: 'auto 3%',
					minWidth: responsiveView ? '100%' : 'auto',
					svg: {
						fontSize: 'clamp(0.8rem, 1.2rem, 1.2rem)',
					},
					'&.MuiIconButton-root': {
						backgroundColor: 'background.dark',
						border: (theme) => `1px solid ${theme.palette.background.border}`,
						borderRadius: '6px',
						'&:hover': {
							backgroundColor: 'primary.light',
							svg: {
								color: '#000 !important',
							},
						},
					},
				},
				'.MuiGrid-item': {
					marginRight: '1rem',
				},
			}}
		>
			{isNumber(projectData?.id) && (
				<Grid item>
					<Tooltip arrow describeChild title='Share this project with others'>
						<IconButton aria-label='Share project' onClick={() => setOpenSocialSharing(true)}>
							<ShareIcon />
						</IconButton>
					</Tooltip>
				</Grid>
			)}

			{buttonOption && projectDownloadable && (
				<Grid item>
					<Tooltip arrow describeChild title={buttonOption.title}>
						<IconButton
							aria-label={buttonOption.title}
							onClick={() => {
								if (projectData?.type === 'history') {
									downloadHistoryAsset(projectData);
								} else if (onDownload) {
									onDownload();
								} else {
									downloadProjectFiles(projectData ?? null, inApp);
								}
							}}
						>
							{buttonOption.icon}
						</IconButton>
					</Tooltip>{' '}
				</Grid>
			)}

			{projectData?.type === 'history' && (
				<Grid item>
					<SaveToGalleryButton
						metadata={Object.keys(projectData?.originalData?.data)
							.map((key) => {
								const index = findIndex(moreInformationMapping, { key });

								if (index !== -1) {
									return `${moreInformationMapping[index].label}: ${projectData?.originalData.data[key]}`;
								}

								return null;
							})
							.filter(Boolean)}
						src={
							projectData?.originalData?.steps?.find((step: { stage: string }) => step.stage === 'export')
								?.outputFile
						}
						title={projectData?.title || String(projectData?.id)}
					/>
				</Grid>
			)}

			{currentUser && projectData && (
				<>
					{currentUser?.id !== projectData?.user?.id &&
						currentUser?.id !== projectData?.owner?.id &&
						!inApp && (
							<Grid item>
								<Tooltip
									arrow
									describeChild
									title={`${isBookmarked ? 'Unbookmark' : 'Bookmark'} project`}
								>
									<IconButton
										aria-label={`${isBookmarked ? 'Unbookmark' : 'Bookmark'} project`}
										onClick={() => {
											toggleLikeProject(Number(projectData?.id), 'bookmark');

											setProjectData({
												...projectData,
												bookmarked: !isBookmarked,
											});
											setIsBookmarked(!isBookmarked);
										}}
										sx={{
											svg: {
												fill: isBookmarked ? 'text.primary' : 'transparent',
											},
										}}
									>
										<BookmarkIcon />
									</IconButton>
								</Tooltip>
							</Grid>
						)}

					{(projectData?.type === 'history' ||
						projectData?.user?.id === currentUser?.id ||
						projectData?.owner?.id === currentUser?.id ||
						(currentUserRoles?.library && adminView)) && (
						<>
							{(isNumber(projectData?.id) || projectData?.type === 'history') && (
								<Grid item>
									<Tooltip
										arrow
										describeChild
										title={`${editMode ? `Cancel editing ` : `Edit `} "${
											projectData?.title || 'Untitled Project'
										}"`}
									>
										<IconButton
											aria-label='Edit project'
											onClick={() => {
												setEditMode(!editMode);
											}}
										>
											<EditIcon />
										</IconButton>
									</Tooltip>
								</Grid>
							)}

							<Grid item>
								<DeleteAsset projectData={projectData} />
							</Grid>
						</>
					)}

					{/* {projectData?.type === 'history' && (
						<>
							<Grid item>
								<Tooltip
									arrow
									describeChild
									title="If you like your generated model, gives it a like to tell us you're happy with it!"
								>
									<IconButton
										aria-label='Liked generated model'
										onClick={async () => {
											await request(`/genai/like/${projectData.id}`, 'POST');
										}}
									>
										<ThumbsUpIcon />
									</IconButton>
								</Tooltip>
							</Grid>

							<Grid item>
								<Tooltip
									arrow
									describeChild
									title='If you dis-like your generated model, gives it a thumbs down to tell us we can do better!'
								>
									<IconButton
										aria-label='Disliked generated model'
										onClick={async () => {
											await request(`/genai/like/${projectData.id}`, 'DELETE');
										}}
									>
										<ThumbsDownIcon />
									</IconButton>
								</Tooltip>
							</Grid>
						</>
					)} */}
				</>
			)}
		</Grid>
	);
}
