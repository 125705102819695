import { CloseIcon } from '@mpx-sdk/images';
import { adminFeaturesAtom, singleAssetViewAtom, userAtom, userRolesAtom } from '@mpx-sdk/shared/atoms';
import WarningSnackbar from '@mpx-sdk/ui/components/admin/WarningSnackbar';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';

interface HeaderProps {
	onClose?: () => void;
}

export default function SAVHeader({ onClose }: HeaderProps): ReactElement | null {
	const router = useRouter();
	const { query } = router;

	const [projectData, setProjectData] = useAtom(singleAssetViewAtom);
	const adminView = useAtomValue(adminFeaturesAtom);
	const currentUser = useAtomValue(userAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);

	// If last page in history is a library page (indicated by /library in url), then show back button
	const [showBackButton, setShowBackButton] = useState<boolean>(false);

	useEffect(() => {
		if (typeof window !== 'undefined' && window.history?.length > 1) {
			setShowBackButton(true);
		}
	}, []);

	const [adminDisplayWarning, setAdminDisplayWarning] = useState<boolean>(false);

	if (!projectData) return <>No Data</>;

	// Handle functionality of component
	function handleBackButton() {
		if (onClose) {
			onClose();
		} else if (!query?.id) {
			setProjectData(null);
		} else {
			router.push('/library');
		}
	}

	return showBackButton ? (
		<>
			{currentUserRoles?.library && adminView && currentUser?.id !== projectData.user?.id && (
				<WarningSnackbar
					isOpen={adminDisplayWarning}
					message={`You are currently an admin and editing another user's project details. Any saved edits will override the original project's details.`}
					onClose={() => setAdminDisplayWarning(false)}
				/>
			)}

			{showBackButton && (
				<Stack alignItems='center' direction='row' justifyContent='flex-end' spacing={0.5}>
					<Tooltip arrow describeChild title='Back to library'>
						<IconButton
							aria-label='Back to library'
							id='close-sav-button'
							onClick={() => handleBackButton()}
							role='button'
							tabIndex={0}
						>
							<CloseIcon />
						</IconButton>
					</Tooltip>
				</Stack>
			)}
		</>
	) : null;
}
