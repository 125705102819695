import { adminFeaturesAtom, store, userAtom } from '@mpx-sdk/shared/atoms';
import { PublicAsset } from '@mpx-sdk/types';
import { intersection, orderBy } from 'lodash';
import { request } from '../api';
import { fuzzyGenerator } from '../library';

export function cleanProjectData(data: PublicAsset[]): PublicAsset[] {
	// Go through array of objects, and remove any ID keys that are duplicates
	const cleanedData: PublicAsset[] = [];
	const ids: Array<number | string> = [];

	// eslint-disable-next-line no-restricted-syntax
	for (const i in data) {
		if (data[i] && (!ids.includes(data[i].id) || data[i].id === 'empty')) {
			ids.push(data[i].id);

			// Trim the project title
			if (data[i].title) {
				data[i].title = data[i].title.trim();
			}

			cleanedData.push(data[i]);
		}
	}

	return [...new Set(cleanedData)];
}

/** An object containing sorting options for project data. */
const sortAlgo = {
	trend: { comparator: 'weight', order: 'desc' },
	update: { comparator: 'updatedAt', order: 'desc' },
	old: { comparator: 'createdAt', order: 'asc' },
	new: { comparator: 'createdAt', order: 'desc' },
	alpha: { comparator: 'title', order: 'asc' },
	bookmark: { comparator: 'bookmarks', order: 'desc' },
	view: { comparator: 'views', order: 'desc' },
	download: { comparator: 'downloads', order: 'desc' },
	remix: { comparator: 'remixes', order: 'desc' },
};

/**
 * Sorts project data by the given sorting algorithm.
 * @returns The sorted project data.
 */
export function sortProjectData(
	/** The active sorting algorithm. */
	activeSorting: string,
	/** The array of project data to sort. */
	data: PublicAsset[],
): PublicAsset[] {
	// Check if data is an array and is not empty.
	if (Array.isArray(data) && data.length > 0 && activeSorting) {
		let dataSorted = [...data];

		let sortOptions;

		// Find the sort options for the given sorting algorithm.
		// eslint-disable-next-line no-restricted-syntax
		for (const key in sortAlgo) {
			if (activeSorting.toLowerCase().includes(key)) {
				sortOptions = sortAlgo[key];
				break;
			}
		}

		if (sortOptions) {
			// Set comparator values for projects that don't have them.
			dataSorted = dataSorted.map((obj) => ({
				...obj,
				[sortOptions.comparator]:
					obj[sortOptions.comparator] ?? (activeSorting.toLowerCase().includes('alpha') ? '' : 0),
			}));

			// Set sort comparator based on the sorting algorithm.
			const sortComparator = activeSorting.toLowerCase().includes('alpha')
				? [
						(o: PublicAsset) =>
							o[sortOptions.comparator] && typeof o[sortOptions.comparator] === 'string'
								? o[sortOptions.comparator]?.toLowerCase() || ''
								: '',
				  ]
				: [sortOptions.comparator, 'title'];

			// Sort the data based on the sort comparator.
			dataSorted = orderBy(dataSorted, sortComparator, [sortOptions.order]);
		}

		return dataSorted;
	}

	return data;
}

export function filterDataByQuery(data, query, limit) {
	let returnData = data;

	if (query && data?.length > 0 && limit > 0) {
		const fuzzyWordOptions = fuzzyGenerator(query);

		// Go through the data, and filter out projects that don't have one of the fuzzy words in their projectName
		const filteredData = data.filter((project) => {
			const projectName = project.title;
			let projectNameMatches = false;

			fuzzyWordOptions.forEach((fuzzyWord) => {
				if (projectName?.match(fuzzyWord)) {
					projectNameMatches = true;
				}
			});

			return projectNameMatches;
		});

		if (filteredData) {
			returnData = filteredData;
		}
	}

	return returnData.slice(0);
}

// Functions related to removing projects that should be ignore from the project list
export const tagIgnoreList = {
	qa: ['qa', 'qatest', 'qa-test', 'qa-test-tag'],
	broken: ['broke', 'broken', 'broken-remix', 'brokenloading'],
	nsfw: ['nsfw'],
};
/** Filters and hide projects that should be ignored */
export function filterIgnoreProjects(data: Array<PublicAsset>, query?: string[] | null): Array<PublicAsset> {
	const adminFeatures = store.get(adminFeaturesAtom);
	const currentUser = store.get(userAtom);

	if (!query) {
		const searchParams = new URLSearchParams(window.location.search);
		if (searchParams.get('q') || searchParams.get('t')) {
			query = [
				searchParams.get('q')?.toLowerCase()?.trim() ?? '',
				searchParams.get('t')?.toLowerCase()?.trim() ?? '',
			];

			query = query.filter((q) => q.length > 0);
		}
	}

	const filteredData = data?.filter((project) => {
		const projectUser = project?.user ?? project?.owner;

		if ((currentUser?.isAdmin || currentUser?.roles?.library) && adminFeatures) {
			return true;
		}

		if (projectUser?.id === currentUser?.id) {
			return true;
		}

		if (project.isPublic === false) {
			return false;
		}

		const ignoreTags = [...tagIgnoreList.qa, ...tagIgnoreList.broken];
		if (currentUser?.matureFilter) {
			ignoreTags.push(...tagIgnoreList.nsfw);
		}

		if (project.tags) {
			// Find any matching tags
			const matchingTags = project.tags.filter((tag) => ignoreTags.includes(tag.toLowerCase()));

			if (matchingTags.length > 0) {
				// Check if the query contains any of the matching tags
				if (query && intersection(query, matchingTags).length > 0) {
					return true;
				}

				return false;
			}
		}

		return true;
	});

	return filteredData;
}

// Functions related to modifying tags
/**
 * Toggle featured status of a tag
 * @returns The updated tag object, or null if not found
 */
export async function toggleFeaturedTags(
	/** Tag being toggled */
	tag: string | number,
): Promise<object | null> {
	const toggledTag = await request(`/project/toggleFeaturedTags/${tag}`, 'POST');

	return toggledTag || null;
}
