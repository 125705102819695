import { accessTokenProvider } from '@mpx-sdk/api-client';
import { store, userAtom } from '@mpx-sdk/shared/atoms';
import Env from '@mpx-sdk/shared/configs/env';
import axios, { Method, RawAxiosRequestHeaders } from 'axios';

export const request = (
	url: string,
	method: Method,
	data?: Record<string, unknown> | FormData | string,
	headers: RawAxiosRequestHeaders = {},
) => {
	let dataOrParam: { data?: any; params?: any } = { data };

	if (method.toLowerCase() === 'get') {
		dataOrParam = { params: data };
	}

	return axios({
		url: Env.MPS_API_HOST + url,
		method,
		...dataOrParam,
		headers: {
			...headers,
			Authorization: headers.Authorization ?? `Bearer ${accessTokenProvider.getAccessToken()}`,
		},
	}).catch((err) => {
		if (err?.response?.status === 401 && store.get(userAtom)) {
			return accessTokenProvider.getNewAccessToken();
		}

		return Promise.reject(err);
	});
};
