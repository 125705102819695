import BaseService from '@api-client/services/base.service';
import { urls } from '@mpx-sdk/shared/configs/urls';
import {
	AssetId,
	AssetSlots,
	PrivateAsset,
	PrivateAssetDownloadUrl,
	RequestFileUploadData as RequestPrivateAssetUploadData,
	RequestPublicAssetUploadData,
} from '@mpx-sdk/types';

class ProjectService extends BaseService {
	flagProject(id: AssetId, reason: string) {
		return this.post(urls.api.assets.public.flagAsset(id), { reason });
	}

	unflagProject(id: AssetId) {
		return this.delete(urls.api.assets.public.unflagAsset(id));
	}

	getFlaggedProjects() {
		return this.get(urls.api.assets.public.getFlaggedProjects);
	}

	togglePublic(id: AssetId) {
		return this.post(urls.api.assets.public.togglePublic(id));
	}

	deletePrivateProject(id: AssetId) {
		return this.delete(urls.api.assets.private.deleteProject(id));
	}

	deletePublicProject(id: AssetId) {
		return this.delete(urls.api.assets.public.deleteAsset(id));
	}

	importSketchfabModel(glbUrl: string) {
		return this.post(urls.api.assets.private.importSketchfabModel, {
			glbUrl,
		});
	}

	updatePublicProject(id: AssetId, data: Partial<PrivateAsset>) {
		return this.patch(urls.api.assets.private.updateProject(id), data);
	}

	adminUpdatePublicProject(id: AssetId, data: any) {
		return this.patch(urls.api.assets.admin.updateProject(id), data);
	}

	updatePublicThumbnail(id: AssetId, formData: FormData) {
		return this.multipartPost(urls.api.assets.public.updateThumbnail(id), formData);
	}

	updatePrivateThumbnail(id: AssetId, formData: FormData) {
		return this.multipartPost(urls.api.assets.private.updateThumbnail(id), formData);
	}

	requestPrivateFileUpload(data: RequestPrivateAssetUploadData): Promise<{
		reference: string;
		bucket: string;
	}> {
		return this.post(urls.api.assets.private.requestFileUpload, data);
	}

	requestPublicFileUpload(data: RequestPublicAssetUploadData): Promise<{
		reference: string;
		id: string;
	}> {
		return this.post(urls.api.assets.public.requestFileUpload, data);
	}

	toggleFeaturedTags(tag: string) {
		return this.post(urls.api.assets.private.toggleFeaturedTags(tag));
	}

	getUserLikedProjects(userId: string, projectIdList: string) {
		return this.get(urls.api.assets.private.getUserLikedProjects(userId, projectIdList));
	}

	getPublicProjects() {
		return this.get(urls.api.assets.public.getProjects);
	}

	getProjectById(id: AssetId) {
		return this.get(urls.api.assets.public.getProjectById(id));
	}

	getMyLikedProjects(userId: string) {
		return this.get(urls.api.assets.public.likedProjects, { userId });
	}

	getSlots() {
		return this.get<AssetSlots>(urls.api.assets.private.getSlots);
	}

	downloadAsset(id: AssetId) {
		return this.get<PrivateAssetDownloadUrl>(urls.api.assets.private.downloadProject(id));
	}

	getMyProjects() {
		return this.get<PrivateAsset[]>(urls.api.assets.private.getMyProjects);
	}

	getRecommendedTags(howMany: number, tags: string) {
		return this.get(urls.api.assets.public.getRecommendedTags(howMany, tags));
	}

	getShowcaseTags(howMany: number) {
		return this.get<string[]>(urls.api.assets.public.getShowcaseTags(howMany));
	}

	getFeaturedTags(howMany: number) {
		return this.get<string[]>(urls.api.assets.public.getFeaturedTags(howMany));
	}

	updatePublicAssetTag(assetId: number, tag: string) {
		return this.post(`/project/updateProjectTags/${assetId}/${tag}`);
	}
}

const projectService = new ProjectService();

export default projectService;
