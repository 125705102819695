import { urls } from '@mpx-sdk/shared/configs/urls';
import BaseService from './base.service';

class GenAIService extends BaseService {
	async generate(data: any): Promise<{
		requestId: string;
		reference: string;
	}> {
		return this.post(urls.api.genai.generate, data);
	}

	async getAccess() {
		return this.get(urls.api.genai.access);
	}

	async addAccess(data: any) {
		return this.patch(urls.api.genai.access, data);
	}

	async deleteAccess(data: any) {
		return this.delete(urls.api.genai.access, data);
	}

	async downloadGeneratedModel(modelId: string): Promise<string> {
		return this.get(urls.api.genai.downloadGeneratedModel(modelId));
	}
}

const genAIService = new GenAIService();

export default genAIService;
