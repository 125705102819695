/* eslint-disable no-nested-ternary */
import Auth0Helper, { clearAccessCookies } from '@mpx-sdk/helpers/auth';
import firebase from '@mpx-sdk/helpers/firebase';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import {
	CreditsIcon,
	LoginIcon,
	LogoXIcon,
	LogoutIcon,
	NotificationsIcon,
	ProfileIcon,
	SettingsIcon,
} from '@mpx-sdk/images';
import {
	adminFeaturesAtom,
	inAppBrowserAtom,
	inAppVersionAtom,
	notificationCountAtom,
	userAtom,
	userHistoryAtom,
	userRolesAtom,
} from '@mpx-sdk/shared/atoms';
import { VERSION } from '@mpx-sdk/shared/configs';
import Env from '@mpx-sdk/shared/configs/env';
import { urls } from '@mpx-sdk/shared/configs/urls';
import { useDialogService } from '@mpx-sdk/ui/components/core/DialogService';
import LinkWrapper from '@mpx-sdk/ui/components/core/LinkWrapper';
import { drawerStyles } from '@mpx-sdk/ui/components/navigation/MainNavbar';
import NotificationCenter from '@mpx-sdk/ui/components/notifications/NotificationCenter';
import VerifiedAvatar from '@mpx-sdk/ui/components/profile/VerifiedAvatar';
import BuyMoreCredit from '@mpx-sdk/ui/components/purchase/BuyMoreCredit';
import { AdminPanelSettings as AdminPanelSettingsIcon, BugReport as BugReportIcon } from '@mui/icons-material';
import {
	Avatar,
	Badge,
	Box,
	Button,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItemIcon,
	Menu,
	MenuItem,
	Stack,
	Switch,
	Toolbar,
	Tooltip,
	Typography,
} from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { ReactElement, useEffect, useMemo, useState } from 'react';

interface NavbarAccountIconProps {
	/** Whether the icon should display phase-dependent options. */
	phaseDependent?: boolean;
	/** Whether the icon should be disabled. */
	disabled?: boolean;
	/** The type of app that the navbar is being used in */
	appType?: 'default' | 'genai';
}

/** A button that displays the user's profile image and links to various account settings and pages. */
export default function NavbarAccountIcon({
	phaseDependent = false,
	disabled = false,
	appType = 'default',
}: NavbarAccountIconProps): ReactElement | null {
	const [adminToggle, setAdminToggle] = useAtom(adminFeaturesAtom);
	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const [drawerMenu, setDrawerMenu] = useState(false);
	const [notificationsAnchorEl, setNotificationsAnchorEl] = useState<null | HTMLElement>(null);
	const [permissionText, setPermissionText] = useState<string | null>(null);
	const [profileDisplayName, setProfileDisplayName] = useState('Guest');
	const currentUser = useAtomValue(userAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);
	const inApp = useAtomValue(inAppBrowserAtom);
	const inAppVersion = useAtomValue(inAppVersionAtom);
	const notificationCount = useAtomValue(notificationCountAtom);
	const responsiveView = UIHelper.isResponsiveView();
	const userHistory = useAtomValue(userHistoryAtom);
	const { showDialog } = useDialogService();

	function getPermissionsText(currentUserRoles: any): string | null {
		if (!currentUserRoles) {
			return null;
		}

		if (currentUserRoles?.roles?.includes('admin')) {
			return 'Admin Features';
		}
		if (currentUserRoles?.roles?.includes('dev')) {
			return 'Developer Features';
		}
		if (currentUserRoles?.roles?.includes('marketing')) {
			return 'Marketing Features';
		}
		if (currentUserRoles?.roles?.includes('beta')) {
			return 'Beta Features';
		}
		if (currentUserRoles?.labs) {
			return 'Labs Features';
		}

		return null;
	}

	const settings = useMemo(
		() => [
			{
				title: 'Gen AI Admin',
				icon: <AdminPanelSettingsIcon />,
				href: `${Env.MPX_GENAI_WEBSITE?.replace('/genai', '')}/admin`,
				hide: !currentUserRoles?.giveRoles || appType !== 'genai',
			},
			{
				title: 'Admin',
				icon: <AdminPanelSettingsIcon />,
				href: `${Env.MPX_WEBSITE}/admin`,
				hide: !currentUserRoles?.library && !currentUserRoles?.giveRoles,
			},
			{
				title: 'Notifications',
				icon: <NotificationsIcon />,
				onClick: () => {
					const avatarButton = document.querySelector('[data-avatar-icon-id="navbar-avatar-button"]');
					if (avatarButton) {
						setNotificationsAnchorEl(avatarButton as HTMLElement);
					}
				},
			},
			{
				title: 'Get More Credits',
				icon: <CreditsIcon />,
				onClick: () => {
					showDialog(BuyMoreCredit.getAsDialog());

					DataLayer.triggerMeasurementEvent('buyEvent', {
						event_name: 'click_get_more_credits',
						user_id: currentUser?.id,
						user_number_of_created_models: userHistory?.length ?? 0,
					});
				},
				hide: appType !== 'genai',
			},
			{
				title: 'View Profile',
				icon: <ProfileIcon />,
				href: currentUser?.username
					? `${Env.MPX_WEBSITE}/user/${currentUser?.username}`
					: `${Env.MPX_WEBSITE}/profile`,
				hide: !currentUser?.email,
			},
			{
				title: 'Settings',
				icon: <SettingsIcon />,
				href: `${Env.MPX_WEBSITE}/profile`,
				hide: !currentUser?.email || inApp,
			},
			{
				title: 'Feedback & Support',
				icon: <BugReportIcon />,
				href: urls.routes.feedback,
			},
			{
				title: 'Download MetaQuest App',
				icon: <LogoXIcon />,
				href: urls.app.oculus,
				hide: inApp,
			},
			{
				title: currentUser?.email ? 'Sign Out' : 'Login',
				icon: currentUser?.email ? <LogoutIcon /> : <LoginIcon />,
				onClick: async () => {
					if (currentUser?.email) {
						await firebase.logout();
						Auth0Helper.logout({
							returnTo: `${window.location.origin}/api/auth/logout`,
						});
					} else {
						Auth0Helper.login({
							returnTo: window.location.pathname !== '/' ? window.location.pathname : undefined,
						});
					}
				},
				id: 'navbar-logout-button',
				hide: inApp,
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[currentUserRoles, appType, currentUser, inApp, responsiveView, userHistory],
	);

	const styles = useMemo(
		() => ({
			avatar: {
				height: '45px',
				width: '45px',
			},
			menu: {
				borderRadius: '28px !important',
				minWidth: '365px',
				overflow: 'auto',
				marginTop: responsiveView ? '45px' : '2.5%',
			},
			menuItem: {
				color: 'text.primary',
				'&:hover': {
					backgroundColor: 'primary.main',
					color: 'primary.contrastText',
					svg: {
						color: 'primary.contrastText',
						fill: 'primary.contrastText',
						path: {
							fill: 'primary.contrastText',
						},
					},
				},
			},
			button: {
				backgroundColor: 'original.main',
				'&:hover': {
					backgroundColor: 'original.light',
				},
			},
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[responsiveView],
	);

	function displayMenuContent() {
		return (
			<Box>
				<Stack alignItems='center' direction='row' justifyContent='flex-start' spacing={2}>
					<Avatar
						alt={`${profileDisplayName}'s profile picture`}
						imgProps={{
							loading: 'lazy',
						}}
						src={currentUser?.photoUrl || `${currentUser?.firstName} ${currentUser?.lastName}`}
					/>
					<Typography className='navbar-menu-title'>{profileDisplayName}</Typography>
				</Stack>

				{!responsiveView && (
					<Divider
						// Increase thickness of divider
						sx={{
							width: '95%',
							borderColor: 'icons.greyed',
							margin: 'auto',
						}}
					/>
				)}

				{permissionText && (
					<MenuItem
						className='navbar-menu-item'
						onClick={() => {
							setAdminToggle(!adminToggle);
						}}
						sx={{
							...styles.menuItem,

							svg: {
								fill: (theme) =>
									responsiveView ? theme.palette.icons.greyed : theme.palette.primary.contrastText,
							},
							justifyContent: 'space-between !important',
							'&:hover': {
								backgroundColor: 'transparent',
							},
						}}
					>
						<Typography sx={{ py: 2 }} textAlign='center'>
							{permissionText}
						</Typography>
						<ListItemIcon
							sx={{
								justifyContent: 'center',
							}}
						>
							<Switch checked={adminToggle} color='primary' />
						</ListItemIcon>
					</MenuItem>
				)}

				{settings.map((setting) => {
					if (!setting.hide) {
						return (
							<LinkWrapper key={`${setting.title}-navbar-item`} href={setting.href}>
								<MenuItem
									key={setting.title}
									className='navbar-menu-item'
									id={setting?.id ?? `${setting?.title}-navbar-item`}
									onClick={async () => {
										await setting?.onClick?.();
										setAnchorElUser(null);
										setDrawerMenu(false);
									}}
									sx={styles.menuItem}
								>
									<ListItemIcon
										sx={{
											justifyContent: 'center',
										}}
									>
										{setting.icon}
									</ListItemIcon>
									<Typography sx={{ py: 2 }} textAlign='center'>
										{setting.title}
									</Typography>
								</MenuItem>
							</LinkWrapper>
						);
					}

					return null;
				})}

				{adminToggle && (
					<Box
						sx={{
							marginTop: '0.5rem',
						}}
					>
						<Divider
							// Increase thickness of divider
							sx={{
								width: '95%',
								borderColor: 'icons.greyed',
								margin: 'auto',
							}}
						/>

						<Typography
							className='navbar-menu-item'
							sx={{
								color: 'text.disabled',
								padding: '1rem',
							}}
						>
							{appType === 'genai'
								? `Gen AI version: ${VERSION.GENAI_WEB}`
								: `Web version: ${VERSION.MPX_WEB}`}
							<br />
							API & db version: {VERSION.API_DB}
							{inAppVersion && (
								<>
									<br />
									In-app version: {inAppVersion}
								</>
							)}
						</Typography>
					</Box>
				)}
			</Box>
		);
	}

	useEffect(() => {
		let name = 'Guest';

		if (currentUser?.firstName && currentUser?.lastName && currentUser?.useName) {
			name = `${currentUser.firstName} ${currentUser.lastName}`;
		} else if (currentUser?.username) {
			name = currentUser.username;
		} else if (currentUser?.id) {
			name = String(currentUser?.id);
		}

		if (name !== profileDisplayName) {
			setProfileDisplayName(name);
		}

		setPermissionText(getPermissionsText(currentUserRoles));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentUser?.firstName,
		currentUser?.id,
		currentUser?.lastName,
		currentUser?.useName,
		currentUser?.username,
		currentUserRoles,
	]);

	return currentUser ? (
		<>
			<NotificationCenter
				autoOpenOnNewNotification={false}
				customAnchorEl={notificationsAnchorEl}
				emptyCustomAnchorEl={() => setNotificationsAnchorEl(null)}
				showIcon={false}
			/>

			{/* Profile menu  */}
			<Box sx={{ flexGrow: 0, display: { sm: 'flex' } }}>
				<Tooltip arrow describeChild title={!phaseDependent ? 'Open settings' : ''}>
					<IconButton
						aria-label='Open settings'
						data-avatar-icon-id='navbar-avatar-button'
						disabled={disabled}
						disableRipple
						onClick={(event) => {
							if (drawerMenu) {
								setAnchorElUser(null);
								setDrawerMenu(false);
							} else {
								setAnchorElUser(event.currentTarget || null);
								setDrawerMenu(true);
							}
						}}
						sx={{ p: 0, maxWidth: '325px' }}
					>
						<Badge
							anchorOrigin={{
								horizontal: 'left',
								vertical: 'bottom',
							}}
							badgeContent={notificationCount}
							overlap='circular'
							sx={{
								'& .MuiBadge-badge': {
									backgroundColor: 'badge.notification',
								},
							}}
						>
							<VerifiedAvatar styles={styles.avatar} user={currentUser} />
						</Badge>
					</IconButton>
				</Tooltip>

				{/* Menu for large screens */}
				{responsiveView && (
					<Menu
						anchorEl={anchorElUser}
						anchorOrigin={{
							horizontal: 'right',
							vertical: responsiveView ? 'top' : 'bottom',
						}}
						className='navbar-menu'
						disableScrollLock
						id='menu-appbar'
						keepMounted
						onClose={() => {
							setAnchorElUser(null);
							setDrawerMenu(false);
						}}
						open={Boolean(anchorElUser)}
						sx={styles.menu}
						transformOrigin={{
							horizontal: 'right',
							vertical: responsiveView ? 'top' : 'bottom',
						}}
					>
						{displayMenuContent()}
					</Menu>
				)}

				{!responsiveView && (
					<Drawer
						anchor='right'
						className='navbar-drawer'
						onClose={() => {
							setAnchorElUser(null);
							setDrawerMenu(false);
						}}
						open={drawerMenu}
						sx={drawerStyles}
					>
						<Toolbar
							sx={{
								backgroundColor: 'background.dark',
								minHeight: '5rem !important',
							}}
						/>

						{/* Add the pages as options, icon first then text */}
						<List>{displayMenuContent()}</List>
					</Drawer>
				)}
			</Box>
		</>
	) : (
		<Stack direction='row' spacing={2}>
			<Button
				className='navbar-signup-button'
				data-cypress='login'
				onClick={async () => {
					clearAccessCookies();

					await Auth0Helper.login();
				}}
				sx={{
					...styles.button,
					background: (theme) => (inApp ? theme.palette.gradient.main : 'none'),
					color: inApp ? 'initial' : 'text.primary',
					borderRadius: '100px',
					fontSize: 'clamp(0.5rem, 1rem, 1rem)',
					fontWeight: '700',
					letterSpacing: '0.0025em',
					lineHeight: '32px',
					paddingLeft: '1.25rem',
					paddingRight: '1.25rem',
					textTransform: 'none',
					whiteSpace: 'nowrap',
					'&:hover': {
						background: (theme) => theme.palette.gradient.hover,
						color: inApp ? 'initial' : 'primary.contrastText',
					},
				}}
				variant='contained'
			>
				{inApp ? 'Link Account' : 'Login'}
			</Button>

			{!inApp && (
				<Button
					className='navbar-signup-button'
					data-cypress='signup'
					onClick={async () => {
						clearAccessCookies();

						await Auth0Helper.getSignupUrl();
					}}
					sx={{
						...styles.button,
						background: (theme) => theme.palette.gradient.main,
						borderRadius: '100px',
						fontSize: 'clamp(0.5rem, 1rem, 1rem)',
						fontWeight: '700',
						letterSpacing: '0.0025em',
						lineHeight: '32px',
						paddingLeft: '1.25rem',
						paddingRight: '1.25rem',
						textTransform: 'none',
						whiteSpace: 'nowrap',
						'&:hover': {
							background: (theme) => theme.palette.gradient.hover,
						},
					}}
					variant='contained'
				>
					Sign Up
				</Button>
			)}
		</Stack>
	);
}
