import { fastCommentsSSOAtom, singleAssetViewAtom } from '@mpx-sdk/shared/atoms';
import Env from '@mpx-sdk/shared/configs/env';
import { FastCommentsCommentWidget } from 'fastcomments-react';
import { useAtomValue } from 'jotai';

export default function Comments() {
	const projectData = useAtomValue(singleAssetViewAtom);
	const fastCommentsSSO = useAtomValue(fastCommentsSSOAtom);

	return (
		<div style={{ width: '100%' }}>
			{projectData?.id && (
				<FastCommentsCommentWidget
					disableSuccessMessage
					hasDarkBackground
					sso={fastCommentsSSO}
					tenantId={Env.FASTCOMMENTS.CLIENT_ID}
					urlId={projectData.id?.toString()}
				/>
			)}
		</div>
	);
}
