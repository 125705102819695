import { UIHelper } from '@mpx-sdk/helpers/ui';
import { HistoryIcon, LightbulbIcon, RemixAIIcon } from '@mpx-sdk/images';
import { genAiResponsiveViewAtom } from '@mpx-sdk/shared/atoms';
import { AppBar, IconButton, Stack, Toolbar } from '@mui/material';
import { useAtom } from 'jotai';
import { ReactElement } from 'react';

export default function SubNavbar(): ReactElement | null {
	const responsiveView = UIHelper.isResponsiveView();
	const [genAiResponsiveView, setGenAiResponsiveView] = useAtom(genAiResponsiveViewAtom);

	const navOptions = [
		{
			title: 'Text-to-3D',
			icon: <RemixAIIcon />,
			id: 'create-display',
		},
		{
			title: 'Inspiration',
			icon: <LightbulbIcon />,
			id: 'gallery-display',
		},
		{
			title: 'History',
			icon: <HistoryIcon />,
			id: 'history-display',
		},
	];

	return !responsiveView ? (
		<AppBar
			className='navbar-appbar'
			position='static'
			sx={{
				backgroundColor: 'background.dark',
				backgroundImage: 'none !important',
				boxShadow: 'none !important',
				borderRadius: '0px !important',
				maxWidth: '100vw',
				top: 'auto !important',
				bottom: 0,
				borderTop: (theme) => `1px solid ${theme.palette.background.offBlack}`,
			}}
		>
			<Toolbar
				className='navbar-container'
				disableGutters
				sx={{
					justifyContent: 'space-between',
					margin: 'min(3%, 10px) min(calc(8% / 4 * 2.5), 45px) !important',
					maxWidth: '100vw',
					padding: 0,
				}}
			>
				{/* Icon Buttons for nav options with icon above, text under icon */}
				{navOptions.map(({ title, icon, id }) => (
					<IconButton
						key={`sub-navbar-${title}-${id}`}
						onClick={() => {
							setGenAiResponsiveView(id);
						}}
						sx={{
							color: genAiResponsiveView === id ? 'primary.main' : 'text.primary',
							textAlign: 'center',
							leadingTrim: 'both',
							textEdge: 'cap',
							fontSize: '1rem',
							fontStyle: 'normal',
							fontWeight: 600,
							lineHeight: '28px',
							borderRadius: '15%',
							svg: {
								stroke: 'text.primary',
							},
						}}
					>
						<Stack alignItems='center' direction='column' justifyContent='center' spacing={2}>
							{icon}
							{title}
						</Stack>
					</IconButton>
				))}
			</Toolbar>
		</AppBar>
	) : null;
}
