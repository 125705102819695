import { rolesService } from '@mpx-sdk/api-client';
import { singleAssetViewAtom, userAtom } from '@mpx-sdk/shared/atoms';
import Env from '@mpx-sdk/shared/configs/env';
import LinkWrapper from '@mpx-sdk/ui/components/core/LinkWrapper';
import VerifiedAvatar from '@mpx-sdk/ui/components/profile/VerifiedAvatar';
import { Avatar, Stack, Typography } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { isEmpty } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';

export default function User(): ReactElement | null {
	const [projectData, setProjectData] = useAtom(singleAssetViewAtom);
	const [userData, setUserData] = useState<any>(null);
	const currentUser = useAtomValue(userAtom);
	const hideUsername = userData?.username === 'devops+delete';

	function generateUsernameInfo() {
		let userData = projectData?.user ?? projectData?.owner ?? null;

		if (!userData) {
			return null;
		}
		if (userData?.id === currentUser?.id) {
			userData = currentUser;
		}

		let displayName = userData?.displayName;

		if (!displayName) {
			if (userData?.useName && (userData?.firstName || userData?.lastName)) {
				displayName = `${userData?.firstName} ${userData?.lastName}`.trim();
			} else if (userData?.username) {
				displayName = userData?.username;
			} else {
				displayName = String(userData?.id);
			}
		}

		return (
			<Stack alignItems='flex-start' direction='column' justifyContent='flex-start'>
				<Typography variant='h6'>{displayName}</Typography>

				{!hideUsername && userData?.username && (
					<Typography variant='subtitle1'>@{userData?.username}</Typography>
				)}
			</Stack>
		);
	}

	async function generateDisplay() {
		if (!isEmpty(projectData)) {
			const userDataDisplay = projectData?.user ?? projectData?.owner ?? null;

			setUserData(userDataDisplay);

			if (userDataDisplay?.id && !userDataDisplay?.roles) {
				try {
					await rolesService.getUserRoles(userDataDisplay?.id).then((roles) => {
						userDataDisplay.roles = roles;
						setUserData(userDataDisplay);
					});
				} catch (error) {
					console.error(error);
				}
			}
		}

		return null;
	}

	useEffect(() => {
		generateDisplay();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectData]);

	useEffect(() => {
		generateDisplay();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!userData) {
		return null;
	}

	return (
		<LinkWrapper
			href={!hideUsername && userData?.username ? `${Env.MPX_WEBSITE}/user/${userData?.username}` : ''}
			onClick={() => {
				setProjectData(null);
			}}
			openNewTab={false}
			preventNewTab
		>
			<Stack alignItems='center' direction='row' justifyContent='flex-start' spacing={1}>
				<VerifiedAvatar publicFacing user={userData}>
					{/* Avatar */}
					<Avatar
						alt={userData?.name}
						aria-label={userData?.name}
						data-testid='sav-user-avatar'
						src={userData?.photoUrl}
					/>
				</VerifiedAvatar>

				{/* Username */}
				{generateUsernameInfo()}
			</Stack>
		</LinkWrapper>
	);
}
