import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { subscriptionAtom, userAtom } from '@mpx-sdk/shared/atoms';
import { CookieNames } from '@mpx-sdk/shared/configs';
import Env from '@mpx-sdk/shared/configs/env';
import { setCookie } from 'cookies-next';
import { useAtomValue } from 'jotai';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function AnalyticsWrapper() {
	const currentUser = useAtomValue(userAtom);
	const subscription = useAtomValue(subscriptionAtom);
	const router = useRouter();

	useEffect(() => {
		DataLayer.init({
			user_id: currentUser?.auth0Id,
		});

		if (currentUser) {
			// set 1st party cookie to track user in GTM
			setCookie(CookieNames.USER_ID, currentUser.auth0Id, { path: '/', domain: Env.COOKIE_DOMAIN });
			DataLayer.setVariable('user_id', currentUser.auth0Id);
		}

		if (subscription) {
			// Update the analytics data layer with the subscription ID
			let planId = 'free';
			if (subscription && subscription.plan_id) {
				planId = subscription.plan_id;
			}
			DataLayer.setVariable('plan', planId);
		}

		const { method, action, ...rest } = router.query;

		if (method && action) {
			DataLayer.triggerMeasurementEvent('authEvent', {
				event_name: action,
				login_method: method,
			});

			// Remove both params from query string but keep the rest

			router.replace(
				{
					pathname: router.pathname,
					query: rest,
				},
				undefined,
				{ shallow: true },
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser, router.query.action, router.query.method, subscription]);

	return null;
}
