/**
 * Sanitize the search query for the API endpoint to prevent XSS, SQL injection, etc.
 * @returns {String} The sanitized string
 * @example <caption>The intent of this function is to be a very simplified version of a sanitization script to remove any malicious or potential harmful inputs that may be prompted by the an end user. Accent characters will be converted to non-accented counterparts and URI encoding will be performed. Important to note, this script is still vulnerable to prototype pollution security risks</caption>
 * sanitizeString(`<p><img src=x" onerror="alert('XSS Attack')"></p>
<p><a href="javascript:alert('Another XSS Attack')">View My Profile at https://stackoverflow.com/questions/23187013/is-there-a-better-way-to-sanitize-input-with-javascript</a></p>`)
 * // returns "pimg srcx onerroralert'XSS Attack'p pa hrefjavascript:alert'Another XSS Attack'View My Profile at https:stackoverflow.comquestions23187013is-there-a-better-way-to-sanitize-input-with-javascriptap"
 * sanitizeString(`Robert'); DROP TABLE Students;--`)
 * // returns "Robert'; Students;--"
 * sanitizeString(`Nice site,  I think I'll take it. <script>alert('Executing JS')</script>`)
 * // returns "Nice site, I think I'll take it. scriptalert'Executing JS'script"
 * sanitizeString(`éíó         eio`)
 * // returns "eio eio"
 * sanitizeString(`Iñtërnâtiônàlizætiøn☃💪`)
 * // returns "Internationaliztin"
 * @see {@link [Prototype pollution](https://www.whitesourcesoftware.com/resources/blog/prototype-pollution-vulnerabilities/)}
 */
export function sanitizeString(
	/** The string to sanitize */
	string: string | number,
): string {
	// Convert to string and trim
	let cleanString: string = string?.toString()?.trim() || '';

	// If string exists, perform sanitization
	if (cleanString) {
		// Decode URI component
		cleanString = decodeURIComponent(cleanString) || cleanString;

		// Replace accent characters with their non-accented counterparts
		cleanString = cleanString.normalize('NFD').replace(/[\u0300-\u036f]/gi, '');

		// Convert double quotes to single quotes
		cleanString = cleanString.replace(/"/g, "'");

		// Remove special characters like * or ^ that are not grammatically relevant. Keep periods, comma, apostrophe, question mark, exclamation mark, colon, semicolon, at, slash and dash
		// eslint-disable-next-line no-useless-escape
		cleanString = cleanString.replace(/[^a-zA-Z0-9\s_\.,@+?\!\:\;\-'/]/gi, '');

		// Remove SQL injection likes "DROP TABLE", "DELETE FROM", "INSERT INTO", "ALTER TABLE", "CREATE TABLE", "CREATE FUNCTION", "UPDATE TABLE", "UPDATE FUNCTION", etc.
		cleanString = cleanString.replace(/DROP TABLE/gi, '');
		cleanString = cleanString.replace(/DELETE FROM/gi, '');
		cleanString = cleanString.replace(/INSERT INTO/gi, '');
		cleanString = cleanString.replace(/ALTER TABLE/gi, '');
		cleanString = cleanString.replace(/CREATE TABLE/gi, '');
		cleanString = cleanString.replace(/CREATE FUNCTION/gi, '');
		cleanString = cleanString.replace(/UPDATE TABLE/gi, '');
		cleanString = cleanString.replace(/UPDATE FUNCTION/gi, '');

		// Remove multiple spaces
		cleanString = cleanString.replace(/\s+/gi, ' ');

		// Trim
		cleanString = cleanString.trim();

		// If string is empty, return undefined
		if (cleanString === '' || cleanString?.length <= 0) {
			cleanString = '';
		}
	}

	return cleanString;
}
