import { urls } from '@mpx-sdk/shared/configs/urls';
import BaseService from './base.service';

class RolesService extends BaseService {
	async getRoles() {
		return this.get(urls.api.roles.getRoles);
	}

	async getUserRoles(userId: number) {
		return this.get(urls.api.roles.getRoleByUser(userId));
	}

	getMyRoles = async (userId: number) => {
		const response = await this.get(urls.api.roles.getRoleByUser(userId));
		return response;
	};

	checkAccessAllowed = async (email: string) => {
		const response = await this.get(`/genai/access/${email}`);
		return response;
	};

	async createRole(name: string) {
		return this.post(urls.api.roles.createRole(name));
	}

	async editRoles(name: string, permissions: any) {
		return this.patch(urls.api.roles.editRoles(name), permissions);
	}

	async deleteRole(name: string) {
		return this.delete(urls.api.roles.deleteRole(name));
	}
}

const rolesService = new RolesService();

export default rolesService;
