import { BookmarkIcon, FlagIcon, LoadIcon, RemixAIIcon, ShareIcon } from '@mpx-sdk/images';
import {
	adminFeaturesAtom,
	inAppBrowserAtom,
	singleAssetViewAtom,
	userAtom,
	userRolesAtom,
} from '@mpx-sdk/shared/atoms';
import { VisibilityOutlined as VisibilityOutlinedIcon } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useAtomValue } from 'jotai';

export default function Metrics() {
	const adminView = useAtomValue(adminFeaturesAtom);
	const currentUser = useAtomValue(userAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);
	const inApp = useAtomValue(inAppBrowserAtom);
	const projectData = useAtomValue(singleAssetViewAtom);
	const theme = useTheme();

	const metricData = [
		{
			label: 'Views',
			value: projectData?.views,
			icon: <VisibilityOutlinedIcon />,
		},
		{
			label: 'Remixes',
			value: projectData?.remixes,
			icon: <RemixAIIcon />,
		},
		{
			label: 'Downloads',
			value: projectData?.downloads,
			hide: inApp,
			icon: <LoadIcon />,
		},
		{
			label: 'Bookmarks',
			value: projectData?.bookmarks,
			icon: <BookmarkIcon />,
		},
		{
			label: 'Shares',
			value: projectData?.shares,
			hide: currentUser?.id !== projectData?.user?.id && !(currentUserRoles?.library && adminView),
			icon: <ShareIcon />,
		},
		{
			label: 'Reports',
			value: projectData?.flags,
			hide: !(currentUserRoles?.library && adminView),
			icon: <FlagIcon />,
		},
	];

	return (
		<Stack
			alignItems='center'
			direction='row'
			justifyContent='flex-start'
			spacing={2.5}
			sx={{
				marginBottom: '1rem',
				color: 'text.subheader',
				svg: {
					fill: `${theme.palette.text.subheader} !important`,
					color: `${theme.palette.text.subheader} !important`,
				},
			}}
		>
			{metricData.map((metric) => {
				if (metric.hide) return null;

				return (
					<Tooltip
						key={`metric-${metric.label}-${metric.value}-tooltip`}
						arrow
						describeChild
						title={metric.label}
					>
						<Stack
							key={`metric-${metric.label}-${metric.value}`}
							alignItems='center'
							direction='row'
							justifyContent='flex-start'
							spacing={1}
						>
							{metric.icon}

							<Typography>{metric.value ?? 0}</Typography>
						</Stack>
					</Tooltip>
				);
			})}
		</Stack>
	);
}
