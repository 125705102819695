import { getInAppDataCookie } from '@mpx-sdk/helpers/inApp';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { userAtom } from '@mpx-sdk/shared/atoms';
import { CookieNames } from '@mpx-sdk/shared/configs/cookies';
import { urls } from '@mpx-sdk/shared/configs/urls';
import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useRef, useState } from 'react';

/** A dialog that is displayed during the tutorial hand-off */
export default function TutorialHandoffDialog(): ReactElement | null {
	const router = useRouter();

	const [isOpen, setIsOpen] = useState(false);
	const appData = useRef<any>(null);
	const currentUser = useAtomValue(userAtom);

	useEffect(() => {
		if (isOpen) {
			let measurementVariable = {
				event_name: 'tutorial_handoff',
				user_id: currentUser?.id ?? null,
			};

			if (appData.current) {
				measurementVariable = {
					...measurementVariable,
					...appData.current,
				};
			}

			DataLayer.triggerMeasurementEvent('tutorial', measurementVariable);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	useEffect(() => {
		// Check if the cookie CookieNames.FIRST_PROJECT is set to true and the localStorage for CookieNames.FIRST_PROJECT is not set
		const appDataCookie = getInAppDataCookie();
		if (appDataCookie) {
			appData.current = JSON.parse(appDataCookie);
		}

		const firstProject =
			(document.cookie.includes(`${CookieNames.FIRST_PROJECT}=true`) || appData?.current?.firstProject) &&
			!localStorage.getItem(CookieNames.FIRST_PROJECT);
		// Check if URL is /library
		const isLibrary = router.pathname === urls.routes.library;

		if (firstProject && isLibrary) {
			setIsOpen(true);

			// Set localStorage for CookieNames.FIRST_PROJECT to true
			localStorage.setItem(CookieNames.FIRST_PROJECT, 'true');
		}
	}, [router.pathname]);

	return (
		<Dialog
			onClose={() => {
				setIsOpen(false);
			}}
			open={isOpen}
			PaperProps={{
				sx: {
					backgroundColor: '#fff',
					color: 'original.contrastText',
				},
			}}
			sx={{
				textAlign: 'center',
			}}
		>
			<DialogTitle
				sx={{
					fontSize: '1.9rem',
					fontWeight: 600,
					letterSpacing: '-0.04em',
					lineHeight: '2.34375rem',
					textAlign: 'center',
					width: '100%',
				}}
				variant='h4'
			>
				This is the Community Gallery!
			</DialogTitle>

			<DialogContent>
				<Stack alignItems='center' direction='column' justifyContent='flex-start' spacing={2}>
					<Typography
						style={{
							fontSize: '1rem',
							fontWeight: '500',
							letterSpacing: '-0.02rem',
							lineHeight: '160%',
							margin: 'auto',
							textAlign: 'center',
						}}
						variant='body1'
					>
						To start a new project, hover over a model and click the Remix option.
					</Typography>

					<Button
						onClick={() => {
							setIsOpen(false);
						}}
						sx={{
							alignItems: 'center',
							flexShrink: 0,
							fontSize: '1rem',
							fontWeight: '700',
							gap: '1.25rem',
							justifyContent: 'center',
							letterSpace: '-0.001rem',
							lineHeight: '1.375rem',
							minHeight: '3rem',
							padding: '0.75rem 1.125rem',
							textTransform: 'none',
							width: '50%',
						}}
						variant='contained'
					>
						Okay
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}
