import { isEqual } from 'lodash';

// Functions related to understanding the library's URL
/** Already found/cached search parameters from the URL */
let cachedSearchParams = {};
/**
 * Process the URL for the library
 * @param {String} [url] URL to process
 * @param {Boolean} [forceUpdate=false] Whether to force to not use cached data [true] or not [false, default]
 * @returns {Object} Object containing the library's search parameters
 * @example <caption>Use this function to process the URL for the library to determine what should immediately be displayed on the library</caption>
 * processLibraryURL('/library?q=tanks')
 * // returns {q: "tanks"}
 * @export
 */
export function processLibraryURL(url: string | undefined = undefined, forceUpdate = false): any {
	if (!cachedSearchParams || Object.keys(cachedSearchParams)?.length === 0 || forceUpdate) {
		/** Object containing the library's search parameters  */
		const searchData = {};
		/** URL to process */
		let urlSearch: string | string[] | undefined =
			url || document?.location?.search || window?.location?.search || undefined;

		// Retrieving what content should be shown based on URL
		if (urlSearch) {
			// You only want things after the ?
			if (urlSearch.includes('?') && !urlSearch.endsWith('?')) {
				urlSearch = urlSearch.split('?')?.[1];

				// Convert to object
				urlSearch = urlSearch.split('&');

				// Find each key/value pair
				urlSearch.forEach((item) => {
					const [key, value] = item.split('=');
					searchData[key] = value;
				});
			}
		}

		cachedSearchParams = searchData;
		return searchData || {};
	}

	return cachedSearchParams;
}

/**
 * Update document history state and URL
 * @param {Object} queries Object containing the query parameters
 * @param {String} [queries.q] Query to search for
 * @param {String} [queries.c] Category to search for
 * @param {String} [queries.pf] What personal filters to apply
 * @param {String} [queries.s] Sort order
 * @param {Boolean} [queries.f] Force a state
 * @param {String | Number} [queries.id] ID of the project to display
 * @param {String | Number} [queries.deleted] ID of the project been deleted
 * @example <caption>Use this function to update the document history state and URL</caption>
 * newLibraryState({q: "tanks"})
 * // returns null (Pushes the state with the search query of tanks)
 * newLibraryState({id: 86})
 * // returns null (Pushes the state with the project ID of 86)
 * @export
 */
export function newLibraryState(queries: any | null): any {
	// First ensure that the queries are valid and does not already exist in URL
	/** Existing URL parameters */
	const urlParams = processLibraryURL(undefined, true) || {};

	// Go through queries and convert all values to strings
	if (queries) {
		Object.keys(queries).forEach((key) => {
			queries[key] = String(queries[key])?.trim();
		});
	}

	// Only proceed if the URL is not already set to the same values as queries
	if (!isEqual(queries, urlParams)) {
		/** New state */
		let newState = `${window?.location?.pathname || '/library'}?`;

		/** Combined queries and URL param */
		const params = { ...urlParams, ...queries };

		// Filter out empty values
		Object.keys(params).forEach((key) => {
			if (params[key]?.trim()?.length === 0) {
				delete params[key];
			}
		});

		if (queries?.id) {
			newState += `id=${queries.id}`;
		} else {
			if (params.id) {
				delete params.id;
			}

			// Add each query to the URL
			Object.keys(params).forEach((key) => {
				if (params[key]?.trim()?.length > 0) {
					newState += `${key}=${params[key]}&`;
				}
			});

			// Remove last &
			newState = newState.substring(0, newState.length - 1);
		}

		if (
			document?.location?.pathname &&
			newState !== `${document?.location.pathname}${document?.location?.search || ''}`
		) {
			if (typeof window !== 'undefined' && window.history) {
				window.history.pushState({}, document.title, newState);
			}

			return params;
		}
	}

	return {};
}

// Functions related to creating a fuzzy query
export function fuzzyGenerator(query: string): Array<RegExp | string> {
	// Trim query
	query = query.trim();

	/** List of possible fuzzy words */
	let fuzzyQueryList: Array<string | RegExp> = [];
	fuzzyQueryList.push(query);

	// Generate fuzzy words
	if (query?.length > 2) {
		// Replace special characters and whitespace with percentage sign (%) - PostgreSQL's any character
		fuzzyQueryList.push(`${query.replace(/[^\w\s]/gi, '%').replace(/\s+/g, '%')}`);

		// Find duplicate letters and replace them with percentage sign (%) - PostgreSQL's any character
		fuzzyQueryList.push(`${query.replace(/(.)\1+/g, '%')}`);

		// Go through query and put an underscore and replace the letter between each letter, one at a time
		for (let i = 0; i < query.length; i += 1) {
			// If next letter is a letter or number
			if (query?.[i + 1]?.match(/[a-zA-Z0-9]/) || query?.[i + 1] === '_') {
				// Add underscore
				fuzzyQueryList.push(`${query.substring(0, i + 1)}.${query.substring(i + 1)}`);

				if (i !== 0) {
					// Replace existing letter with underscore
					fuzzyQueryList.push(`${query.substring(0, i)}.${query.substring(i + 1)}`);

					// If word longer than 5 letters, have 1 previous and next letter and is at least 3rd character into word
					if (
						query?.length >= 5 &&
						query?.[i + 1]?.match(/[a-zA-Z0-9]/) &&
						query?.[i - 1]?.match(/[a-zA-Z0-9]/) &&
						i > 1
					) {
						// Delete previous letter and add one underscore before next letter
						fuzzyQueryList.push(`${query.substring(0, i - 1)}${query[i]}.${query.substring(i + 1)}`);
					}
				}
			}
		}
		// The word itself
		fuzzyQueryList.push(`${query}`);

		// Remove whitespace and special characters and combine words
		fuzzyQueryList.push(`${query.replace(/[^\w\s]/gi, '').replace(/\s+/g, '')}`);
	}

	// Go through all words in fuzzy query list and remove any single or double quotes, colon, semi-colon, comma, period, and question mark
	fuzzyQueryList = fuzzyQueryList.map((value: string | RegExp) => {
		if (typeof value === 'string') {
			return value.replace(/['":;,?!_]/g, '');
		}

		return value;
	});

	// Clean up list
	fuzzyQueryList = [...new Set(fuzzyQueryList)];

	// Convert to RegExp
	fuzzyQueryList = fuzzyQueryList.map((word) => new RegExp(word, 'i'));

	// Clean up list to contain only unique words and return
	return fuzzyQueryList;
}

// Don't delete, useless but fun to include:
export function consoleLogLogo() {
	// Logo
	console.log(
		`\n
          .~YGGP?.
        .Y#&&&&&&B~.:~7?7^
       :#&&#####BPP?~~!?YPP!
       !:.!#&#BG5YYY!:^~7?JY!
           :BBPYYYYYJ^:^~^..:.
            ^PYJYYYYY?:.
            .?YYYYYJJJ^
   ^7JJ7~  :~~JYYYJJ?7!
 :J555PGBG77!~!YYJ?7!~^:
.JJJJJJJYYJ?7~^7Y?7!^:...
:~^^~!?555Y?7~^^7?!^........
.!~^!YGGP5Y?!~^:.:~.......
 .YB##BGP5J7~:.    ....
   :7YYY?!^.

Welcome to Masterpiece X! Interested in working with us?
https://www.masterpiecex.com/careers`,
	);

	// Also, include a warning to tell people who don't know what they're doing to not to put anything into the console that someone else told them to put in there
	console.log(
		`Don't know what this is? This is a developer console. Do not put anything here if you do not know what you are doing, especially if someone told you to put something here.`,
	);
}
