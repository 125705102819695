import { getUserSlots } from '@mpx-sdk/helpers/assets';
import { openSlotsDialog, slotsType, userAtom, userSlotsAtom } from '@mpx-sdk/shared/atoms';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';

interface MaxSlotsProps {
	/** If you want to trigger opening and closing this dialog without using openSlotsDialog state variable, set to true here */
	isOpen?: boolean;
	/** If you want to set a custom max slots instead of using userSlotsAtom state variable, set a number here */
	maxSlots?: number;
	/** Custom close function that overrides just closing this dialog */
	onClose?: () => void;
	/** Which slots to display. If you want to forcefully display a specific userSlotsAtom data, give "import", "export" or "project" */
	slotType?: 'import' | 'export' | 'project';
	/** If you want to set a custom used slots instead of using userSlotsAtom state variable, set a number here */
	slotsUsed?: number;
}

/** A dialog that displays a message when the user has reached the maximum number of storage slots. */
export default function MaxSlots({
	isOpen = false,
	maxSlots,
	onClose,
	slotType,
	slotsUsed,
}: MaxSlotsProps): ReactElement {
	const router = useRouter();

	const [slots, setSlots] = useAtom(userSlotsAtom);
	const [open, setOpen] = useAtom(openSlotsDialog);
	const type = useAtomValue(slotsType);

	const [currentUser] = useAtom(userAtom);

	const [displaySlotsData, setDisplaySlotsData] = useState<{
		/** Current number of slots used per type */
		used?: number;
		/** Current number of slots available per type */
		max?: number;
		/** Human readable title for type */
		title?: string;
	}>({});

	useEffect(() => {
		if (slots) {
			/** Determine which slots data will be used */
			const useType = slotType && ['import', 'export', 'project'].includes(slotType) ? slotType : type;
			const {
				slotsPrivateExports,
				slotsPrivateExportsUsed,
				slotsPrivateImports,
				slotsPrivateImportsUsed,
				slotsProjects,
				slotsProjectsUsed,
			} = slots || {};

			// Set display slots data
			if (useType === 'import') {
				setDisplaySlotsData({
					used: slotsPrivateImportsUsed,
					max: slotsPrivateImports,
					title: 'Private Imports',
				});
			} else if (useType === 'export') {
				setDisplaySlotsData({
					used: slotsPrivateExportsUsed,
					max: slotsPrivateExports,
					title: 'Private Imports',
				});
			} else if (useType === 'project') {
				setDisplaySlotsData({
					used: slotsProjectsUsed,
					max: slotsProjects,
					title: 'Projects',
				});
			}
		}
	}, [type, slots, slotType]);

	useEffect(() => {
		async function updateSlotsData() {
			const slotsData = await getUserSlots();
			if (slotsData) {
				setSlots(slotsData);
			}
		}

		if ((!slots || isEmpty(slots)) && currentUser) {
			updateSlotsData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Dialog
			maxWidth='md'
			onClose={() => {
				if (onClose) {
					onClose();
				} else {
					setOpen(false);
				}
			}}
			open={isOpen || open}
			role='dialog'
			sx={{ textAlign: 'center' }}
		>
			<DialogTitle>
				Max Slots Reached
				<IconButton
					aria-label='close'
					onClick={onClose ?? (() => setOpen(false))}
					sx={{
						color: 'primary.main',
						position: 'absolute',
						right: 8,
						top: 8,
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<p>
					You have reached{' '}
					<span className='my-storage-near-cap'>
						{slotsUsed ?? displaySlotsData.used ?? 0}/{maxSlots ?? displaySlotsData.max ?? 0}
					</span>{' '}
					{`"${displaySlotsData.title ?? 'used'}"`} slots for your account. <br />
					<br />
					Please remove a project from {`"${displaySlotsData.title ?? 'your account'}"`} to add or create a
					new one.
				</p>
				<p>
					<Button
						color='primary'
						onClick={() => {
							if (onClose) {
								onClose();
							} else if (window.location.pathname !== '/storage') {
								router.push('/storage');

								setOpen(false);
							} else {
								setOpen(false);
							}
						}}
						sx={{
							'&:hover': {
								backgroundColor: 'primary.light',
							},
						}}
						variant='contained'
					>
						Manage Storage
					</Button>
				</p>
			</DialogContent>
		</Dialog>
	);
}
