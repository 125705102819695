export enum MLTypes {
	MESH_TO_ANIMATION = 'mesh_to_animation',
	ADVANCED_MESH = 'advanced_mesh',
	ADVANCED_TEXTURE = 'advanced_texture',
	ADVANCED_ANIMATION = 'advanced_animation',
	TEX_REPROJ_PREPROCESS = 'tex_reproj_preprocess',
	TEX_REPROJ_FUSION = 'tex_reproj_fusion',
	TEX_REPROJ_TEX_GEN = 'tex_reproj_tex_gen',
	ADVANCED_MESH_GEN_BASEMESH = 'advanced_mesh_gen_basemesh',
	MESH_TO_ANIMATION_ANIMAL = 'mesh_to_animation_animal',
	MESH_TO_ANIMATION_OBJECT = 'mesh_to_animation_object',
	MESH_TO_ANIMATION_AUTO = 'mesh_to_animation_auto',
	MESH_TO_ANIMATION_ANIMAL_AUTO = 'mesh_to_animation_animal_auto',
	MESH_TO_ANIMATION_OBJECT_AUTO = 'mesh_to_animation_object_auto',
	MESH_TO_ANIMATION_BASE_GEN = 'mesh_to_animation_base_gen',
	MESH_TO_ANIMATION_ANIMAL_BASE_GEN = 'mesh_to_animation_animal_base_gen',
	MESH_TO_ANIMATION_OBJECT_BASE_GEN = 'mesh_to_animation_object_base_gen',
	AUTO_SELECT_MESH_GEN = 'auto_select_mesh_gen',
	ADVANCED_TEXTURE_SEM = 'advanced_texture_sem',
	REANIMATE = 'reanimate',
	REPAINT = 'repaint',
}
