import {
	type AssetSlots,
	AssetType,
	type LibraryUrlStates,
	type PublicAsset,
	type PublicUserProfile,
	type Subscription,
	type Team,
	type UserBalance,
	type UserProfile,
} from '@mpx-sdk/types';
import { darkTheme } from '@mpx-sdk/ui/themes';
import { createTheme } from '@mui/material/styles';
import type { FastCommentsSSO } from 'fastcomments-react';
import type { FormApi } from 'final-form';
import { atom, getDefaultStore } from 'jotai';
import { find } from 'lodash';

export const store = getDefaultStore();

// User related atoms

export const userAtom = atom<UserProfile | null>(null);
export const userRolesAtom = atom<any>({});
export const userSlotsAtom = atom<AssetSlots | null>(null);

export const userHistoryAtom = atom<any>([]);

// In-app related atoms

export const inAppBrowserAtom = atom<boolean>(false);
export const inAppVersionAtom = atom<string | null>(null);

// Styling related atoms

export const themeAtom = atom<'light' | 'dark'>(
	(typeof window !== 'undefined' && (localStorage.getItem('theme') as 'light' | 'dark')) || 'dark',
);

/** Which responsive tab will be displayed on landing on Gen AI on mobile */
export const genAiResponsiveViewAtom = atom<string>('create-display');

export const pageThemeAtom = atom(createTheme(darkTheme));

// Following related to general use of the app

export const teamsAtom = atom<Team[] | []>([]);
export const openSlotsDialog = atom<boolean>(false);
export const slotsType = atom<AssetType>(AssetType.PROJECT);

export const subscriptionAtom = atom<Subscription | null>(null);

export const activeTeamAtom = atom(
	(get) => {
		const teams = get(teamsAtom);
		const activeTeam = find(teams, 'isActive');
		return activeTeam || teams?.[0];
	},
	(get, set, teamId) => {
		const newTeams = get(teamsAtom).map((team) => {
			const updatedTeam = team;

			if (teamId === team.id) {
				updatedTeam.isActive = true;
			} else {
				delete updatedTeam.isActive;
			}

			return updatedTeam;
		});

		return set(teamsAtom, newTeams);
	},
);
/** Whether the login prompt dialog should be open [true] or not [false, default] */
export const openLoginPrompt = atom<boolean>(false);
/** What caused the login prompt (empty string for unknown) */
export const loginPrompt = atom<string>('');

export const mainForm = atom<FormApi | null>(null); /** The current queue status for the Gen AI */
export const queueStatus = atom<any | null>(null);

export const reShowMobileOnboardingAtom = atom(false);

export const availableCreditsAtom = atom<UserBalance | null>(null);

// FastComments related atoms
export const fastCommentsSSOAtom = atom<FastCommentsSSO | undefined>(undefined);

// Following related to search bar
export const searchBarValue = atom('');
export const activeSearchQuery = atom('');

export const libraryActiveCategories = atom(['model', 'material', 'rig', 'animation']);

// Following related to library content
export const libraryActiveSorting = atom('Trending');

export const libraryURLParamsAtom = atom<LibraryUrlStates>({});

export const libraryDisplayNavbarSearchBar = atom(false);

export const libraryDisplayType = atom('explore');

/** When a library item is deleted, set this to true and it will refresh library contents [default: false] */
export const libraryItemDelete = atom<boolean>(false);

export const libraryNewDisplayData = atom<boolean>(false);

/** When the private cloud data needs to be updated, it can be done by setting this to true */
export const updatePrivateData = atom(false);

export const savEditMode = atom(false);

// Following related to single asset view
/** Library's single project view (library modal or full page project details)
 *  Atom stores the project that is currently being displayed as modal or single page
 *  may user have clicked on asset card or directly came to project url, and the project that will be shown to user, it's data will come from this atom
 */
export const singleAssetViewAtom = atom<PublicAsset | null | undefined>(null);

/** Library's single project view metadata */
export const singleAssetViewMetadataAtom = atom<PublicAsset['metadata'] | null>(null);

// Following related to user page
/** Which user is currently being displayed through the user page */
export const userProfileDisplayAtom = atom<PublicUserProfile | null>(null);

// Following related to project exports
export const projectExportsAtom = atom<any | null>(null);

// Following related to flagging a project
export const flagDialogOpenAtom = atom<boolean>(false);
export const flagProjectDataAtom = atom<PublicAsset | null>(null);

// Following related to social sharing
export const openShareDialogAtom = atom<boolean>(false);

// Following related to admin features
export const adminFeaturesAtom = atom<boolean>(false);

// Following related to notifications
export const notificationCountAtom = atom<number>(0);
