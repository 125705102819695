var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9pL0UnJW1k80xucSQBx0v"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';
import { Replay } from '@sentry/react';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
	dsn: SENTRY_DSN || 'https://1b28b3dc0e250ad9bd8b447ae62daf7c@o256814.ingest.sentry.io/4505710342832128',

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	integrations: [
		new CaptureConsole({
			levels: ['error'],
		}),
		new Replay(),
	],

	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 1.0,
	environment: process.env.NEXT_PUBLIC_SENTRY_ENV || 'production',

	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps
});

Sentry.configureScope((scope) => {
	scope.setTag('version', publicRuntimeConfig?.version ?? 'unknown');
});
