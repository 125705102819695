import { economyService } from '@mpx-sdk/api-client';
import Env from '@mpx-sdk/shared/configs/env';

export class Chargebee {
	static init() {
		if (typeof window !== 'undefined') {
			window.Chargebee.init({
				site: Env.CB.SITE,
				publishableKey: Env.CB.PUBLISHABLE_KEY,
			});
		}
	}

	static openCheckout(
		planOptions: {
			planId: string;
			quantity?: number;
		},
		{ onCheckoutComplete }: { onCheckoutComplete: () => void },
	) {
		if (typeof window !== 'undefined') {
			window.Chargebee.getInstance().openCheckout({
				hostedPage() {
					return economyService.getHostedPageUrl(planOptions);
				},
				success() {
					onCheckoutComplete();
				},
			});
		}
	}

	static async openManagePortal() {
		const cbInstance = window.Chargebee.getInstance();
		cbInstance.setPortalSession(async () => {
			try {
				return await economyService.getManagePortalUrl();
			} catch (error) {
				console.error(error);
			}
		});
		const cbPortal = cbInstance.createChargebeePortal();
		cbPortal.open({
			close: () => {
				cbInstance.logout();
			},
		});
	}
}
