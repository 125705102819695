import Link from '@genai/core/Link';
import { userAtom } from '@mpx-sdk/shared/atoms';
import { urls } from '@mpx-sdk/shared/configs/urls';
import { Animation, DiscFull, Home as HomeIcon, Language, LockClock, Texture, ViewComfyAlt } from '@mui/icons-material';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useRouter } from 'next/router';

export const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(() => ({
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	'& .MuiDrawer-paper': {
		width: 68,
		top: 65,
		borderRadius: 0,
	},
}));

export default function Navbar() {
	const open = false;
	const router = useRouter();
	const [user] = useAtom(userAtom);
	const links = [
		{
			name: 'Sessions',
			href: urls.pages.sessions,
			icon: <LockClock />,
		},
		{
			name: 'Dashboard',
			href: urls.pages.dashboard,
			icon: <HomeIcon />,
		},
		{
			name: 'Text to Mesh',
			href: urls.pages.textToMesh,
			icon: <Language />,
		},
		{
			name: 'Text to Texture',
			href: urls.pages.textToTexture,
			icon: <Texture />,
		},
		{
			name: 'Text to Animation',
			href: urls.pages.textToAnimation,
			icon: <Animation />,
		},
		{
			name: 'Mesh to Animation Full',
			href: '/admin/mesh-to-animation',
			icon: <DiscFull />,
		},
		{
			name: 'Demo',
			href: urls.pages.mvp,
			icon: <ViewComfyAlt />,
		},
	];

	return (
		<Drawer open={open} variant='permanent'>
			<List>
				{links.map((link) => (
					<ListItem key={link.name} disablePadding sx={{ display: 'block' }}>
						<Link
							href={user ? link.href : '#'}
							sx={{
								cursor: user && link.name !== 'Dashboard' ? 'pointer' : 'not-allowed',
							}}
						>
							<ListItemButton
								disabled={!user && link.name !== 'Dashboard'}
								selected={router.pathname === link.href}
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
									'&.Mui-selected': {
										color: 'primary.main',
									},
								}}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									<Stack alignItems='center'>
										{link.icon}
										{!open && (
											<Typography variant='caption'>{link.name.split(' ').pop()}</Typography>
										)}
									</Stack>
								</ListItemIcon>

								<ListItemText primary={`${link.name}`} sx={{ opacity: open ? 1 : 0 }} />
							</ListItemButton>
						</Link>
					</ListItem>
				))}
			</List>
		</Drawer>
	);
}
