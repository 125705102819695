import { Typography, type TypographyProps } from '@mui/material';
import { ReactNode } from 'react';

function GradientTypography({
	children,
	sx,
	...props
}: TypographyProps & {
	children: ReactNode;
}) {
	return (
		<Typography
			sx={{
				fontWeight: 'bold',
				backgroundImage: 'linear-gradient(91.02deg, #00EAB7 10.41%, #FF9B53 49.23%, #FF268E 89.7%)',
				WebkitBackgroundClip: 'text',
				color: 'transparent',
				...sx,
			}}
			textAlign='center'
			{...props}
		>
			{children}
		</Typography>
	);
}

export default GradientTypography;
