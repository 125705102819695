import { PublicAsset, UserProfile } from '@mpx-sdk/types';
import { getAuth } from 'firebase/auth';
import { collection, getDocs, getFirestore, limit, orderBy, query, startAfter } from 'firebase/firestore';

let auth;
let db;

export function getCurrentProjectsSortMethod(sortBy?: string) {
	let toSortBy;
	const sortMethod = sortBy?.toLowerCase() || 'new';

	if (sortMethod.includes('old')) {
		toSortBy = {
			fieldPath: 'lastUpdated',
			direction: 'asc',
		};
	} else if (sortMethod.includes('alpha')) {
		toSortBy = {
			fieldPath: 'projectName',
			direction: 'asc',
		};
	} else {
		toSortBy = {
			fieldPath: 'createdAt',
			direction: 'desc',
		};
	}

	return toSortBy;
}

export function processFirestoreDataToMPSProjects(data, currentUser) {
	const processedData: Array<PublicAsset> = [];

	if (currentUser) {
		data.forEach((project) =>
			processedData.push({
				id: project.id,
				user: {
					id: currentUser.id,
					photoUrl: currentUser?.photoUrl,
					name: currentUser?.useName
						? `${currentUser?.firstName} ${currentUser?.lastName}`
						: currentUser?.username || currentUser.id.toString(),
					username: currentUser?.username,
				},
				title: project.projectName?.trim(),
				path: project.path,
				createdAt: project?.createdAt?.seconds
					? new Date(project.createdAt.seconds * 1000).toISOString()
					: undefined,
				updatedAt: project?.lastUpdated?.seconds
					? new Date(project.lastUpdated.seconds * 1000).toISOString()
					: undefined,
			}),
		);
	}

	return processedData;
}

// Getting user's Current Projects from Firebase Firestore
export async function getCurrentProjects(
	userId,
	options: {
		sort?: string;
		limit?: number;
		pagination?: number;
	},
	convertToMPSProjectFormat = true,
	currentUser?: UserProfile | null,
) {
	if (!auth) {
		auth = getAuth();
	}
	if (!db) {
		db = getFirestore();
	}

	const sortBy = getCurrentProjectsSortMethod(options.sort);

	let q = query(collection(db, `projects/${userId}/team`), orderBy(sortBy.fieldPath, sortBy.direction));

	if (options.limit) {
		q = query(q, limit(options.limit));
	}

	if (options.pagination) {
		q = query(q, startAfter(options.pagination));
	}

	const snapshot = await getDocs(q);

	const snapshotDoc = snapshot.docs;
	let snapshotProcessed: any[] = [];

	snapshotProcessed = snapshotDoc.map((innerDoc) => ({
		id: innerDoc.id,
		...(innerDoc.data() as any),
	}));

	if (convertToMPSProjectFormat) {
		snapshotProcessed = processFirestoreDataToMPSProjects(snapshotProcessed, currentUser);
	}

	return snapshotProcessed;
}
