type Validator = (value: any) => string | undefined;

export class FormHelpers {
	static composeValidators =
		(...validators: Array<Validator>) =>
		(value: any): string | undefined =>
			validators.reduce(
				(error: string | undefined, validator: Validator) => error ?? validator(value),
				undefined,
			);

	static required = (value: any) => (value ? undefined : 'Required');

	static maxLength = (max: number) => (value: any) =>
		value && value.length > max ? `Must be ${max} characters or less` : undefined;
}
