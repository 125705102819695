import { store, userAtom } from '@mpx-sdk/shared/atoms';

export function matchBucketToPostgresDataFormat(data: any) {
	if (!data || typeof data !== 'object') {
		return null;
	}

	const convertedData: any = {};
	const currentUser = store.get(userAtom);

	// User data:
	if (currentUser) {
		convertedData.user = currentUser;
	}

	// Object ID:
	if (data.id) {
		convertedData.id = data.id.toString();
		convertedData.objectID = data.id.toString();
	}

	// Title, description and other text fields:
	if (data.projectTitle) {
		convertedData.title = data.projectTitle;
	}

	// Made in:
	convertedData.madeIn = ['MPX GenAI'];

	// Gen AI metadata:
	if (data.data) {
		convertedData.metadata = {};
		convertedData.metadata.genAIData = {
			'Paint Does Not Look Like': data.data.promptNeg,
			Animation: data.data.movesLikePrompt,
			Shape: data.data.category,
			'Paint Looks Like': data.data.promptPos,
		};
	}

	// Time:
	// if (data.createdAt) {
	// 	const creationDate = new Date(data.createdAt);

	// 	convertedData.createdAt = creationDate;
	// }
	// if (data.updatedAt) {
	// 	const updateDate = new Date(data.updatedAt);

	// 	convertedData.updatedAt = updateDate;
	// }

	// Files
	if (data.steps) {
		// Go through each step, find ones that end with a .glb, or an image file extension (jpg, png, etc)
		// Once found, grab its file name and add it to the files array in addition to the file URL
		convertedData.files = [];

		const fileExtensions = ['.glb', '.jpg', '.jpeg', '.png', '.gif'];

		data.steps.forEach((step: any) => {
			if (step.outputFile) {
				// If the file is a glb or image file, add it to the files array
				const fileExtension = step.outputFile.substring(step.outputFile.lastIndexOf('.'));

				if (fileExtensions.includes(fileExtension)) {
					convertedData.files.push({
						name: step.outputFile.substring(step.outputFile.lastIndexOf('/') + 1),
						downloadUrl: step.outputFile,
					});

					if (step.type === 'thumbnail') {
						convertedData.thumbnailUrl = step.outputFile;
					}
				}
			}
		});
	}

	// Category
	convertedData.category = 'model';

	// Indicate that this is a GenAI model
	convertedData.type = 'history';

	// Original data
	convertedData.originalData = data;

	return convertedData;
}
