/* eslint-disable no-nested-ternary */
import { request } from '@mpx-sdk/helpers/api/request';
import { matureWordsFilter } from '@mpx-sdk/helpers/input';
import { openFullscreen } from '@mpx-sdk/helpers/screen';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { MaximizeIcon } from '@mpx-sdk/images';
import {
	adminFeaturesAtom,
	inAppBrowserAtom,
	singleAssetViewAtom,
	userAtom,
	userRolesAtom,
} from '@mpx-sdk/shared/atoms';
import { urls } from '@mpx-sdk/shared/configs/urls';
import ToggleFeaturedAssets from '@mpx-sdk/ui/components/admin/ToggleFeaturedAsset';
import TogglePublicAssets from '@mpx-sdk/ui/components/admin/TogglePublicAsset';
import Flag from '@mpx-sdk/ui/components/flag/Flag';
import { AddAPhoto as AddAPhotoIcon, Settings as SettingsIcon } from '@mui/icons-material';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { isNumber } from 'lodash';
import Image from 'next/image';
import { ReactElement } from 'react';
import { toast } from 'react-toastify';

interface ThumbnailButtonsProps {
	/** Whether this is for a Google Model Viewer (gmv) or a NextJS Image (image) */
	type?: string;
	/** Whether to adjust the UI for GMV controls or not */
	displayControls?: boolean;
	/** Function to open the GMV controls */
	setDisplayControls?: (value: boolean) => void;
	/** Whether to show the admin warning when hovering over the thumbnail save button */
	setShowAdminWarning?: (value: boolean) => void;
}

/** Buttons for the thumbnail of a project */
export default function ThumbnailButtons({
	displayControls = false,
	setDisplayControls,
	setShowAdminWarning,
	type = 'gmv',
}: ThumbnailButtonsProps): ReactElement | null {
	const adminView = useAtomValue(adminFeaturesAtom);
	const currentUser = useAtomValue(userAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);
	const inApp = useAtomValue(inAppBrowserAtom);
	const projectData = useAtomValue(singleAssetViewAtom);
	const projectPublic = isNumber(projectData?.id);
	const responsiveView = UIHelper.isResponsiveView();

	const gmvButtonStyles = {
		right: !displayControls ? '4%' : 'initial',

		'.MuiIconButton-root': {
			backgroundColor: !responsiveView ? 'background.paper' : 'inherit',
			'&:hover': {
				backgroundColor: !responsiveView ? 'primary.light' : 'inherit',
				svg: {
					color: !responsiveView ? 'primary.contrastText' : 'primary.light',
				},
			},
		},
	};

	/** Toggle full screen */
	const handleFullscreen = () => {
		/** Parent container element for Google Model Viewer */
		const modelContainer = document.getElementsByClassName('asset-thumbnail-modal-container')?.[0] as any;
		/** Google Model Viewer element */
		const model = document.getElementById('project-model-viewer') as any;

		if (model) {
			/** Whether the element went into full screen [true] or not [false] */
			const fullscreen = openFullscreen(modelContainer || model);

			if (fullscreen) {
				// Remove max and min height from modelContainer and make height 100%
				model.style.maxHeight = 'none';
				model.style.minHeight = 'none';
				model.style.height = '100%';
			} else {
				// Add max and min height back to modelContainer and make height 100%
				model.style.maxHeight = '50vh';
				model.style.minHeight = '50vh';
			}
		}
	};

	const handleThumbnailSave = async (): Promise<void> => {
		if (projectData) {
			const toastID = toast.loading(`Updating thumbnail for ${projectData?.title || 'Untitled Project'}`, {
				closeOnClick: true,
				draggable: true,
				autoClose: 5000,
			});

			const modelViewerElement = document.getElementById('project-model-viewer') as any;

			if (!modelViewerElement) {
				toast.update(toastID, {
					render: `Failed to update thumbnail - element error`,
					type: toast.TYPE.ERROR,
					isLoading: false,
					autoClose: 60000,
				});

				return;
			}

			const thumbnail = await modelViewerElement?.toBlob((blob: Blob | null) => blob, 'image/png');

			if (!thumbnail) {
				toast.update(toastID, {
					render: `Failed to update thumbnail - unable to create`,
					type: toast.TYPE.ERROR,
					isLoading: false,
					autoClose: 60000,
				});

				return;
			}

			const formData = new FormData();
			formData.append('file', thumbnail);

			if (projectPublic) {
				await request(urls.api.assets.public.updateThumbnail(projectData.id), 'POST', formData, {
					'Content-Type': 'multipart/form-data',
				})
					.then((res) => {
						toast.update(toastID, {
							render: (
								<Stack
									alignItems='flex-start'
									direction='column'
									justifyContent='flex-start'
									spacing={1}
									style={{
										position: 'relative',
									}}
								>
									<span>Thumbnail updated!</span>
									<Image
										alt={`New thumbnail for ${projectData?.title || 'Untitled Project'}`}
										className='toast-thumbnail-image'
										height={192}
										loading='lazy'
										src={res.data?.downloadUrl}
										width={192}
									/>
								</Stack>
							),
							type: toast.TYPE.SUCCESS,
							isLoading: false,
							autoClose: 5005555550,
						});
					})
					.catch((err) => {
						console.error(err);
						toast.update(toastID, {
							render: `Failed to update thumbnail`,
							type: toast.TYPE.ERROR,
							isLoading: false,
						});
					});
			} else {
				await request(urls.api.assets.private.updateThumbnail(projectData.id), 'POST', formData, {
					'Content-Type': 'multipart/form-data',
				})
					.then((res) => {
						toast.update(toastID, {
							render: (
								<Stack
									alignItems='flex-start'
									direction='column'
									justifyContent='flex-start'
									spacing={1}
									style={{
										position: 'relative',
									}}
								>
									<span>Thumbnail updated!</span>
									<Image
										alt={`New thumbnail for ${projectData?.title || 'Untitled Project'}`}
										className='toast-thumbnail-image'
										height={192}
										loading='lazy'
										src={res.data?.downloadUrl}
										width={192}
									/>
								</Stack>
							),
							type: toast.TYPE.SUCCESS,
							isLoading: false,
							autoClose: 5000,
						});
					})
					.catch((err) => {
						console.error(err);
						toast.update(toastID, {
							render: `Failed to update thumbnail`,
							type: toast.TYPE.ERROR,
							isLoading: false,
							autoClose: 60000,
						});
					});
			}
		}
	};

	return (
		<>
			{/* Project title */}
			<Typography
				aria-label='Project title'
				data-testid='sav-project-title'
				sx={{
					bottom: '2%',
					display: displayControls ? 'none' : 'initial',
					fontSize: '1.5rem',
					left: '4%',
					maxWidth: '45% !important',
					overflowWrap: 'break-word',
					position: 'absolute',
					width: '100%',
					zIndex: 10001,
				}}
			>
				{projectData?.title
					? !currentUser?.matureFilter
						? projectData?.title
						: matureWordsFilter(projectData?.title)
					: 'Untitled Project'}
			</Typography>

			{/* GMV top buttons */}
			<Stack
				alignItems='center'
				className='asset-thumbnail-icons'
				direction='row'
				justifyContent={displayControls && responsiveView ? 'flex-start' : 'flex-end'}
				spacing={1}
				sx={{
					...gmvButtonStyles,
					top: '2%',
					marginRight: responsiveView ? 'auto' : '3%',
				}}
			>
				{/* Toggle full screen */}
				{!inApp && (
					<Tooltip arrow describeChild title='Toggle full screen'>
						<IconButton data-testid='sav-fullscreen-button' onClick={handleFullscreen}>
							<MaximizeIcon />
						</IconButton>
					</Tooltip>
				)}
			</Stack>

			{/* GMV bottom buttons */}
			<Stack
				alignItems='center'
				className='asset-thumbnail-icons'
				direction='row'
				justifyContent={displayControls && responsiveView ? 'flex-start' : 'flex-end'}
				spacing={1}
				sx={{
					...gmvButtonStyles,
					bottom: '2%',
					marginRight: responsiveView ? 'auto' : '3%',
				}}
			>
				{currentUserRoles?.library && adminView && projectData && isNumber(projectData?.id) && (
					<>
						{/* Toggle public asset */}
						<TogglePublicAssets
							key={`toggle-public-${projectData.id}`}
							hideWarning
							projectData={projectData}
						/>

						{/* Toggle featured asset */}
						<ToggleFeaturedAssets projectData={projectData} />
					</>
				)}

				{/* Settings button */}
				{!inApp && responsiveView && setDisplayControls && type === 'gmv' && (
					<Tooltip
						arrow
						describeChild
						title={`${displayControls ? 'Close' : 'Open'} 3D model viewer settings`}
					>
						<IconButton
							aria-label='Open 3D model viewer settings'
							className='google-model-viewer-controls-button'
							onClick={() => setDisplayControls(!displayControls)}
						>
							<SettingsIcon />
						</IconButton>
					</Tooltip>
				)}

				{/* Flag button */}
				{currentUser &&
					projectData &&
					currentUser?.id !== projectData?.user?.id &&
					isNumber(projectData?.id) && <Flag projectData={projectData} />}

				{/* Save thumbnail button */}
				{projectData &&
					setShowAdminWarning &&
					type === 'gmv' &&
					typeof projectData?.id === 'number' &&
					currentUser &&
					((currentUserRoles?.library && adminView) ||
						currentUser.id === projectData.user?.id ||
						currentUser.id === projectData.owner?.id) && (
						<Tooltip
							arrow
							describeChild
							title={`Save as thumbnail ${
								currentUserRoles?.library && adminView && currentUser?.id !== projectData?.user?.id
									? "- Warning! You are currently an admin and will be overriding the user's original thumbnail"
									: ''
							}`}
						>
							<IconButton
								className={
									currentUserRoles?.library && adminView && currentUser?.id !== projectData?.user?.id
										? 'admin-edit'
										: ''
								}
								onClick={handleThumbnailSave}
								onMouseEnter={() => setShowAdminWarning(true)}
								onMouseLeave={() => setShowAdminWarning(false)}
							>
								<AddAPhotoIcon
									sx={{
										fontSize: 28,
									}}
								/>
							</IconButton>
						</Tooltip>
					)}
			</Stack>
		</>
	);
}
