import { assetsService } from '@mpx-sdk/api-client';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { StorageIcon } from '@mpx-sdk/images';
import { availableCreditsAtom, userAtom, userHistoryAtom } from '@mpx-sdk/shared/atoms';
import { basename, poll } from '@mpx-sdk/shared/utils';
import { useDialogService } from '@mpx-sdk/ui/components/core/DialogService';
import ReusableForm from '@mpx-sdk/ui/components/core/form/Fields';
import { Button, IconButton, LinearProgress, Stack, Tooltip } from '@mui/material';
import { getApp } from 'firebase/app';
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { useAtomValue } from 'jotai';
import { capitalize } from 'lodash';
import { type ReactElement } from 'react';

export default function SaveToGalleryButton({
	src,
	metadata,
	title,
}: {
	title: string;
	src: string;
	metadata: any;
}): ReactElement {
	const availableCredits = useAtomValue(availableCreditsAtom);
	const currentUser = useAtomValue(userAtom);
	const userHistory = useAtomValue(userHistoryAtom);

	const { showDialog, hideDialog } = useDialogService();

	const handleSaveToGallery = async ({
		description,
		galleryTarget: target,
		title,
	}: {
		description: string;
		galleryTarget: string;
		title: string;
	}) => {
		DataLayer.triggerMeasurementEvent('saveToGallery', {
			event_name: `save_to_${target}`,
			user_id: currentUser?.id,
			status: 'initiated',
			user_number_of_created_models: userHistory?.length ?? 0,
			title,
		});

		showDialog({
			title: `Saving to ${capitalize(target)} Gallery`,
			content: (
				<>
					<div>
						Please wait while we are exporting to {capitalize(target)} gallery. Do not close the window
					</div>
					<br />

					<LinearProgress sx={{ maxWidth: '70%', m: 'auto' }} />
				</>
			),
		});

		try {
			// Download the file into variable
			const response = await fetch(src);
			const blob = await response.blob();
			// eslint-disable-next-line no-useless-escape
			const forbiddenCharsRegex = /[/?*:|"<>\\\n\r]/g;

			const fileName = `${title.replace(forbiddenCharsRegex, '_')}.glb`;
			let data;

			let cleanedMetadata: any = {};
			if (Array.isArray(metadata)) {
				// Go through the array and split the value by ':' and assign the first value as key and second as value
				// If it doesn't have ':' then just assign the key as 'extra' (if doesn't exist, create it), and value as the value
				metadata.forEach((item) => {
					const [key, value] = item.split(':');
					if (key && value) {
						cleanedMetadata[key.trim()] = value.trim();
					} else {
						if (!cleanedMetadata.extra) {
							cleanedMetadata.extra = [];
						}

						cleanedMetadata.extra.push(item);
					}
				});
			} else if (typeof metadata === 'object') {
				cleanedMetadata = metadata;
			} else {
				cleanedMetadata.extra = metadata;
			}

			if (target === 'private') {
				data = await assetsService.requestPrivateFileUpload({
					files: [
						{
							name: fileName,
							size: blob.size,
							extension: 'glb',
						},
					],
					description: description || '',
					madeIn: ['MPX GenAI'],
					metadata: {
						genAIData: cleanedMetadata,
					},
				});
			} else {
				data = await assetsService.requestPublicFileUpload({
					title,
					description: description || '',
					category: 'model',
					madeIn: ['MPX GenAI'],
					files: [
						{
							name: fileName,
							size: blob.size,
							extension: 'glb',
						},
					],
					metadata: {
						genAIData: cleanedMetadata,
					},
				});
			}

			const { reference, bucket } = data;
			const id = data.id || basename(reference);

			DataLayer.triggerMeasurementEvent('saveToGallery', {
				event_name: `save_to_${target}`,
				user_id: currentUser?.id,
				user_number_of_created_models: userHistory?.length ?? 0,
				status: 'success',
				id,
				title,
			});

			const storageRef = ref(getStorage(getApp(), bucket), `${reference}/${fileName}`);

			// const metaData = {};

			await uploadBytesResumable(storageRef, blob, metadata);

			if (target === 'public') {
				await assetsService.updatePublicAssetTag(id, 'gen ai');
				// Poll and await until the project is ready without loop
				await poll(
					() => assetsService.getProjectById(id),
					(project: { projectFiles: any[] }) =>
						project.projectFiles.every((file: any) => file.downloadUrl === null),
					1500,
				);
			}

			showDialog({
				title: `Saved to ${capitalize(target)} Gallery`,
				actions: (
					<Stack direction='row' spacing={2}>
						<Button color='primary' onClick={hideDialog}>
							Close
						</Button>
						<Button
							href={
								target === 'public'
									? `${process.env.NEXT_PUBLIC_MPX_WEBSITE}/library/${id}`
									: `${process.env.NEXT_PUBLIC_MPX_WEBSITE}/storage?tab=my-private-exports`
							}
							sx={{ color: 'text.contrast' }}
							target='_blank'
							variant='contained'
						>
							View
						</Button>
					</Stack>
				),
				content: (
					<>
						Your project has been saved to {capitalize(target)} gallery.
						{target === 'private' &&
							' Please wait for a few minutes for the project to be ready for download. Look for the notification on MPX website.'}
					</>
				),
			});
		} catch (err: any) {
			showDialog({
				title: 'Error',
				content: (
					<>
						<div>There was an error while saving to {target}gallery. Please try again later</div>
						<br />
						{err?.message ?? JSON.stringify(err)}
					</>
				),
			});
		}
	};

	return (
		<Tooltip title='Save to Gallery'>
			<IconButton
				onClick={async () => {
					DataLayer.triggerMeasurementEvent('saveToGallery', {
						event_name: 'save_to_gallery',
						status: 'opened',
						user_id: currentUser?.id,
						user_number_of_created_models: userHistory?.length ?? 0,
						...availableCredits,
					});

					showDialog({
						title: "Enter project's title",
						actions: (
							<Stack direction='row' spacing={2}>
								<Button color='primary' onClick={hideDialog}>
									Close
								</Button>
								<Button form='saveForm' sx={{ px: 4, color: '#000' }} type='submit' variant='contained'>
									Submit
								</Button>
							</Stack>
						),
						content: (
							<ReusableForm
								fields={{
									titleLabel: {
										label: 'Title',
										type: 'label',
									},
									title: {
										label: '',
										type: 'text',
										required: true,
										grid: { xs: 12 },
										default: title.replace(/_/g, ' '),
									},
									descriptionLabel: {
										label: 'Description',
										type: 'label',
									},
									description: {
										label: '',
										type: 'text',
										multiline: true,
										rows: 5,
										grid: { xs: 12 },
										default: `${
											Array.isArray(metadata)
												? metadata.join('.\n')
												: JSON.stringify(metadata, null, 2)
										}. \n\nThis 3D model was created using Masterpiece X - Generate. Check the app out here: https://www.masterpiecex.com/generate`,
									},
									galleryTarget: {
										label: 'Export to',
										type: 'radio',
										options: [
											{
												label: 'Public',
												value: 'public',
												default: true,
											},
											{
												label: 'Private',
												value: 'private',
											},
										],
										grid: { xs: 12 },
									},
								}}
								id='saveForm'
								noSubmitButton
								onSubmit={handleSaveToGallery}
							/>
						),
					});
				}}
			>
				<StorageIcon />
			</IconButton>
		</Tooltip>
	);
}
