/* Get the document element (<html>) to display the page in fullscreen */

/* Close fullscreen */
export function closeFullscreen() {
	if (document.exitFullscreen) {
		document.exitFullscreen();
	} else if (document.webkitExitFullscreen) {
		/* Safari */
		document.webkitExitFullscreen();
	} else if (document.msExitFullscreen) {
		/* IE11 */
		document.msExitFullscreen();
	}
}

/* View in fullscreen */
export function openFullscreen(el: HTMLElement | null): boolean {
	if (!el) {
		return false;
	}

	if (document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
		closeFullscreen();
		return false;
	}

	if (el.requestFullscreen) {
		el.requestFullscreen();
	} else if (el.webkitRequestFullscreen) {
		/* Safari */
		el.webkitRequestFullscreen();
	} else if (el.msRequestFullscreen) {
		/* IE11 */
		el.msRequestFullscreen();
	}

	return true;
}
