import { inAppBrowserAtom, store } from '@mpx-sdk/shared/atoms';
import Env from '@mpx-sdk/shared/configs/env';
import algoliasearch from 'algoliasearch';
import { isEmpty } from 'lodash';

export const defaultSearchConfig = () => {
	let categoryParam: string[] | null = null;
	let queryParam: string | null = null;

	if (typeof window !== 'undefined') {
		categoryParam = new URLSearchParams(window?.location.search).getAll('c');
		queryParam = new URLSearchParams(window?.location.search).get('q');
	}

	const defaultCategory = store.get(inAppBrowserAtom) ? ['model'] : ['animation', 'material', 'model', 'rig'];

	return {
		category: !isEmpty(categoryParam) ? categoryParam : defaultCategory,
		hitsPerPage: store.get(inAppBrowserAtom) ? 12 : 44,
		indexName: 'projects',
		tags: [],
		query: queryParam ?? '',
		sortByIndex: 'projects',
		ruleContexts: ['library'],
		sortByOptions: [
			{ indexName: 'projects', label: 'Most Relevant' },
			{
				indexName: 'projects_alpha_asc',
				label: 'Alphabetical (A-Z)',
			},
			{
				indexName: 'projects_alpha_desc',
				label: 'Alphabetical (Z-A)',
			},
			{
				indexName: 'projects_recent_uploaded',
				label: 'Recently Uploaded',
			},
		],
	};
};

export const searchClient = algoliasearch(Env.ALGOLIA.APP_ID, Env.ALGOLIA.SEARCH_KEY);
