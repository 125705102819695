import { cleanURLSearchParams } from './clean';

export function addSearchParamToURLQuery(paramObject: any) {
	if (paramObject) {
		const url = new URL(window.location.href);

		Object.keys(paramObject).forEach((key) => {
			const value = paramObject[key];
			if (value) {
				url.searchParams.set(key, value);
			}
		});

		cleanURLSearchParams(url);
	}
}
