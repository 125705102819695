export interface UserBalance {
	created_at: any;
	currencies: UserBalanceCurrency[];
	updated_at: any;
}

export interface Currency {
	balance: number;
	currency: 'credits' | 'downloads' | 'exports' | 'generations';
}

export enum CurrencyType {
	Credits = 'credits',
	Downloads = 'downloads',
	Exports = 'exports',
	Generations = 'generations',
}

export interface UserBalanceCurrency {
	balance: number;
	currency: CurrencyType;
}
