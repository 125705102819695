import { defaultThumbnail } from '@mpx-sdk/images';
import { inAppBrowserAtom, store } from '@mpx-sdk/shared/atoms';
import { PublicAsset, PublicAssetFile } from '@mpx-sdk/types';

/**
 * Assume texture type based on texture name
 * @returns {String} Texture type if found (null if not)
 * @example <caption>When trying to figure out which texture type an individual file is, can be assumed basing alias patterns with this function</caption>
 * assumeTextureType("Metal001_Normal.png", "Metal001");
 * // returns "normal"
 * assumeTextureType("PaintedWood001_Color.png", "PaintedPlaster001");
 * // returns "diffuse"
 */
export function assumeTextureType(
	/** Name of texture file */
	textureName: string,
	/** Name of project [optional] - used to cute from texture */
	projectName?: string,
): string | null {
	/** Texture aliases */
	const textureAliases = {
		/** Standard color */
		diffuse: ['diffuse', 'color', 'colour', 'base', 'basecolor', 'basecolour'],
		/** Bump map for perceived depth */
		normal: ['normal', 'bump'],
		/** Both roughness and metallic - Google Model Viewer combines these two */
		metallic: ['roughness', 'metalness', 'metallic'],
		/** Metal, Gloss + Occlusion */
		mgo: ['combine', 'mgo'],
		/** How it will glow in the dark */
		emissive: ['emissive'],
		/** Height map */
		height: ['height'],
		/** Ambient Occlusion - Shadow map/how light bounce */
		occlusion: ['occlusion', 'oa'],
		/** Gloss */
		gloss: ['gloss'],
	};

	// If project name is in texture name, remove it
	if (projectName && textureName.toLowerCase().includes(projectName.toLowerCase())) {
		textureName = textureName.replace(projectName.toLowerCase(), '');
	}

	// Find texture type
	// eslint-disable-next-line no-restricted-syntax
	for (const [textureType, aliases] of Object.entries(textureAliases)) {
		// eslint-disable-next-line no-restricted-syntax
		for (const alias of aliases) {
			if (textureName.toLowerCase().includes(alias)) {
				return textureType;
			}
		}
	}

	return null;
}

/**
 * Find the project's thumbnail's URL based on list of project files given
 * @returns {String} Thumbnail URL (or null if no thumbnail found)
 * @example <caption>Use this to find if there is a thumbnail file given within the project files. Looks for projects that has the name "thumbnail" in it and is in one of the following accepted file formats: jpg, jpeg, png, gif, webp, tiff, or SVG</caption>
 * findProjectThumbnail({name: "thumbnail", downloadUrl: "url/thumbnail.jpg"}, true);
 * // returns "url/thumbnail.jpg"
 * @export
 */
export function findProjectThumbnailUrl(
	/** List of project files. Should contain at least a name and downloadUrl for this to work */
	projectFiles: Array<PublicAssetFile>,
	/** If a possible URL exists within the project files, add here and it will be processed if valid */
	thumbnailUrlString?: string,
	/** Whether to return the default thumbnail if no thumbnail is found [default: true] */
	useDefaultThumbnail = true,
): string | undefined {
	/** Thumbnail URL */
	let thumbnail: any = useDefaultThumbnail && defaultThumbnail ? (defaultThumbnail as any).src : undefined;

	/** What thumbnail file formats are accepted in the web app */
	const acceptedFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'tiff', 'svg'];

	if (thumbnailUrlString) {
		thumbnail = thumbnailUrlString;
	} else if (projectFiles && Array.isArray(projectFiles)) {
		const fileExtension = thumbnail?.split('.')?.pop()?.toLowerCase() ?? '';

		thumbnail =
			// eslint-disable-next-line arrow-body-style
			projectFiles?.find((file) => {
				return (
					file.downloadUrl &&
					file.name &&
					// If URL to file exists
					file.downloadUrl?.length > 0 &&
					// Check if filename includes thumbnail
					file.name?.toLowerCase().includes('thumbnail') &&
					// Check if file type is usable
					(acceptedFileTypes.includes(fileExtension) || fileExtension.includes('tmp'))
				);
			})?.downloadUrl ?? thumbnail;
	}

	const noImagePlaceholderKeywords = ['no image placeholder', 'no-image-placeholder'];

	if (typeof thumbnail !== 'string') {
		thumbnail = thumbnail?.src ?? thumbnail?.url ?? thumbnail?.downloadUrl ?? thumbnail?.uri ?? undefined;

		if (!thumbnail) {
			return undefined;
		}
	}

	const lowercaseThumbnail = thumbnail?.toLowerCase();

	if (lowercaseThumbnail && noImagePlaceholderKeywords.some((keyword) => lowercaseThumbnail.includes(keyword))) {
		thumbnail = (defaultThumbnail as any).src;
	}

	if (!thumbnail && useDefaultThumbnail) {
		thumbnail = (defaultThumbnail as any).src;
	}

	/** The width of the thumbnail to be used. If in app browser, use smaller width to save data */
	const thumbnailWidth: string = store.get(inAppBrowserAtom) ? '235' : '600';

	return thumbnail?.includes('?')
		? `${thumbnail.substring(0, thumbnail.indexOf('?'))}?width=${thumbnailWidth}`
		: `${thumbnail}?width=${thumbnailWidth}`;
}

export function addEmptyAssetSlots(
	projectData: PublicAsset[],
	defaultMaxSlots = 5,
	type: 'private' | 'continue' | 'project' | 'empty' = 'empty',
) {
	// Due to max slots being used as a visual indicator, we only need to add empty projects to let user trigger scroll
	// 5 is the default max slots, 13 the amount of projects loaded at once, if user has more than 5 slots and more than 13 projects, we don't need to add too many empty projects
	const extraSlotsNeeded =
		projectData && projectData.length >= 13 ? 0 : Math.max(defaultMaxSlots - (projectData?.length || 0), 0);

	const emptySlots = Array(Math.min(extraSlotsNeeded, 13)).fill({
		id: 'empty',
		type,
		title: '',
	});

	return projectData ? [...projectData, ...emptySlots] : emptySlots;
}

export function removeEmptyAssetSlots(projectData: PublicAsset[]) {
	return projectData.filter((project) => project.id !== 'empty');
}

/**
 * Converts a given number of bytes to a human-readable file size.
 * @param bytes - The number of bytes.
 * @returns The human-readable file size.
 */
export function convertBytesToSize(bytes: number): string | null {
	const units = ['B', 'KB', 'MB', 'GB'];
	let size = bytes;

	for (let i = 0; i < units.length; i += 1) {
		if (size < 1024 || i === units.length - 1) {
			if (size > 0) {
				return `${size.toFixed(2)} ${units[i]}`;
			}

			return null;
		}
		size /= 1024;
	}

	throw new Error('Invalid bytes value');
}
