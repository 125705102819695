import { FormControlLabel, Radio as MuiRadio, RadioGroup } from '@mui/material';

function Radio({ options, ...props }: { options: { name: string; value: string }[] }) {
	return (
		<RadioGroup {...props}>
			{options?.map((option) => (
				<FormControlLabel key={option.value} control={<MuiRadio />} label={option.name} value={option.value} />
			))}
		</RadioGroup>
	);
}

export default Radio;
