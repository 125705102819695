import { UIHelper } from '@mpx-sdk/helpers/ui';
import { AlertCircleIcon } from '@mpx-sdk/images';
import { CloseOutlined } from '@mui/icons-material';
import { Button, IconButton, Stack, TextField, Tooltip } from '@mui/material';
import { isEmpty, isString } from 'lodash';
import { nanoid } from 'nanoid';
import { ReactElement, ReactNode, cloneElement, useState } from 'react';

export interface ToolTipProps {
	title?: string;
	paragraphs?: (string | ReactNode)[];
	children?: ReactNode;
	images?: string[];
	showGotItButton?: boolean;
	showCloseButton?: boolean;
	showAlertIcon?: boolean; // Add a new prop for showing the alert icon
	alertIconText?: (string | ReactNode)[] | undefined; // Add a new prop for the alert icon text
	alertIconPara?: (string | ReactNode)[] | undefined;
	interactive?: boolean;
}

function IconTooltip({
	title,
	paragraphs,
	images,
	showGotItButton = true,
	showCloseButton = false,
	showAlertIcon = false, // Default value is false
	alertIconText, // Default value is an empty string
	alertIconPara,
	children,
	interactive = false,
}: ToolTipProps): ReactElement {
	const [isTooltipOpen, setTooltipOpen] = useState(false);
	const responsiveView = UIHelper.isResponsiveView();
	const tooltipId = nanoid();

	const handleTooltipToggle = () => {
		setTooltipOpen(!isTooltipOpen);
	};

	const handleCloseTooltip = () => {
		setTooltipOpen(false);
	};

	const isTextField = children && (children as React.ReactElement).type === TextField;

	const child = !isTextField ? (
		<IconButton onClick={handleTooltipToggle}>
			<AlertCircleIcon
				sx={{
					fill: (theme) => theme.palette.icons.greyed,
					height: '1.2rem',
					width: '1.2rem',
				}}
			/>
		</IconButton>
	) : (
		cloneElement(children as ReactElement, {
			onFocus: () => setTooltipOpen(true),
			onBlur: (e) => {
				if (!document.getElementById(tooltipId)?.contains(e?.relatedTarget)) handleCloseTooltip();
			},
		})
	);

	if (isEmpty(paragraphs)) {
		return child;
	}

	return (
		<Tooltip
			arrow
			describeChild
			disableHoverListener
			disableTouchListener
			id={tooltipId}
			onClose={() => {
				if (!interactive) {
					setTooltipOpen(false);
				}
			}}
			open={isTooltipOpen}
			placement={responsiveView ? 'right' : undefined}
			title={
				<Stack
					spacing={2}
					sx={{
						margin: '3%',
						maxHeight: '80vh',
						overflowY: 'auto',
					}}
				>
					{showCloseButton && (
						<IconButton onClick={handleCloseTooltip} style={{ position: 'absolute', top: 0, right: 0 }}>
							<CloseOutlined />
						</IconButton>
					)}

					{title && <div style={{ fontWeight: 'bold', marginBottom: '8px', fontSize: '20px' }}>{title}</div>}

					{paragraphs?.map((paragraph) =>
						isString(paragraph) ? (
							<div key={paragraph} style={{ marginBottom: '8px', margin: '10px' }}>
								{paragraph}
							</div>
						) : (
							paragraph
						),
					)}

					<br />

					{images &&
						Array.isArray(images) &&
						images.map((imageUrl, index) => (
							<div key={imageUrl}>
								<img alt='ToolTip' height={150} src={imageUrl} width='100%' />
								{index < images.length - 1 && <br />}
							</div>
						))}

					{showAlertIcon &&
						alertIconText && ( // Conditionally render the alert icon and text
							<>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										marginBottom: '0px',
										margin: '1px',
									}}
								>
									<img alt='alert triangle' height='10%' src='/alert-triangle.svg' width='7%' />
									<div style={{ marginLeft: '8px' }}>{alertIconText} </div>
									{/* Render the alert icon text */}
								</div>
								<div>{alertIconPara}</div>
							</>
						)}
					{showGotItButton && (
						<Button onClick={() => setTooltipOpen(false)} sx={{ marginTop: 'auto' }} variant='contained'>
							Got it
						</Button>
					)}
				</Stack>
			}
		>
			{child}
		</Tooltip>
	);
}

export default IconTooltip;
