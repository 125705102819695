/* eslint-disable no-nested-ternary */
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { CollaborateIcon, HomeIcon, LearnIcon, LibraryIcon, RemixAIIcon, StorageIcon } from '@mpx-sdk/images';
import {
	adminFeaturesAtom,
	inAppBrowserAtom,
	libraryURLParamsAtom,
	singleAssetViewAtom,
	userAtom,
	userHistoryAtom,
	userRolesAtom,
} from '@mpx-sdk/shared/atoms';
import { CookieNames, fadeInTimeout } from '@mpx-sdk/shared/configs';
import Env from '@mpx-sdk/shared/configs/env';
import { useDialogService } from '@mpx-sdk/ui/components/core/DialogService';
import LinkWrapper from '@mpx-sdk/ui/components/core/LinkWrapper';
import MPXLogo from '@mpx-sdk/ui/components/navigation/MPXLogo';
import NavbarAccountIcon from '@mpx-sdk/ui/components/navigation/NavbarAccountIcon';
import NavbarAccountSlots from '@mpx-sdk/ui/components/navigation/NavbarAccountSlots';
import BuyMoreCredit from '@mpx-sdk/ui/components/purchase/BuyMoreCredit';
import { Menu as MenuIcon, MenuOpen as MenuOpenIcon } from '@mui/icons-material';
import {
	AppBar,
	Box,
	Button,
	Divider,
	Drawer,
	Fade,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Toolbar,
	Tooltip,
	Typography,
} from '@mui/material';
import { getCookie } from 'cookies-next';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

interface MainNavbarProps {
	/** The type of app that the navbar is being used in */
	appType?: 'default' | 'genai';
}

export const drawerStyles = {
	overflow: 'auto',
	'.MuiPaper-root': {
		backgroundColor: '#eee',
		borderRadius: 0,
		color: 'primary.contrastText',
		height: 'fit-content',
		maxHeight: '100%',
		width: '100%',
		svg: {
			color: 'primary.contrastText',
			fill: 'primary.contrastText',
			path: {
				fill: 'primary.contrastText',
			},
		},

		'.MuiList-root': {
			overflowY: 'auto',
		},

		'.MuiListItem-root': {
			paddingLeft: 0,
			paddingRight: 0,

			'&:hover': {
				backgroundColor: 'primary.light',
			},
		},

		'.MuiTypography-root': {
			color: 'primary.contrastText',
		},

		'.MuiMenuItem-root': {
			svg: {
				color: 'primary.contrastText',
				fill: 'primary.contrastText',
				path: {
					fill: 'primary.contrastText',
				},
			},
		},

		'.MuiStack-root': {
			padding: '3%',
		},
	},
};

export default function MainNavbar({ appType = 'default' }: MainNavbarProps) {
	const router = useRouter();
	const { f: phaseDependentState, c: categoryQuery } = router.query;

	const [drawerMenu, setDrawerMenu] = useState(false);
	const [fadeIn, setFadeIn] = useState(false);
	const [inApp, setInApp] = useAtom(inAppBrowserAtom);
	const [tab, setTab] = useState<number>(-1);
	const adminView = useAtomValue(adminFeaturesAtom);
	const currentUser = useAtomValue(userAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);
	const responsiveView = UIHelper.isResponsiveView();
	const setLibraryURLParams = useSetAtom(libraryURLParamsAtom);
	const setSingleAssetView = useSetAtom(singleAssetViewAtom);
	const userHistory = useAtomValue(userHistoryAtom);
	const { showDialog } = useDialogService();

	const pages = useMemo(
		() => [
			{
				icon: <LibraryIcon />,
				id: 'home',
				title: 'Library',
				drawerTitle: 'Community Library',
				url: `${Env.MPX_WEBSITE}/library`,
			},
			{
				disabled: !currentUser?.email,
				icon: <StorageIcon />,
				id: 'storage',
				title: 'My Storage',
				url: `${Env.MPX_WEBSITE}/storage`,
			},
			{
				disabled: !currentUser?.email,
				hide: !currentUser || !adminView || !currentUserRoles?.inDevelopment,
				icon: <CollaborateIcon />,
				id: 'collaborate',
				isComingSoon: !adminView || !currentUserRoles?.inDevelopment,
				title: 'Collaboration',
				tooltip: 'Create projects with others in real-time',
				url: `${Env.MPX_WEBSITE}/collaborate`,
			},
			{
				icon: <LearnIcon />,
				id: 'learn',
				title: 'Learn',
				url: `${Env.MPX_WEBSITE}/learn`,
			},
			{
				hide: inApp && (!currentUser || !adminView || !currentUserRoles?.inDevelopment),
				icon: <RemixAIIcon />,
				id: 'remix',
				title: 'Generate',
				tooltip: 'Generate game ready 3D assets with just a few words!',
				url: Env.MPX_GENAI_WEBSITE,
			},
		],
		[adminView, currentUser, currentUserRoles, inApp],
	);

	/**
	 * Double check whether the in-app atom is being set correctly.
	 * ! @AlexJSully: New issue where despite the cookie being set, the atom is not being set
	 * */
	function doubleCheckInApp() {
		// Extra check for in-app conditions
		if (!inApp) {
			// Check if the in-app cookie is set
			if (Boolean(getCookie(CookieNames.IN_APP)) || (typeof window !== 'undefined' && window?.vuplex)) {
				setInApp(true);
			}
		}
	}

	const handleTabChange = (_event: any, value: any) => {
		if (router.route === '/library') {
			setLibraryURLParams({});
		}
		setTab(value);
	};

	useEffect(() => {
		// Check if tab value is valid
		if (tab < 0 || tab >= pages.length) {
			setTab(-1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tab]);

	useEffect(() => {
		if (router?.pathname) {
			// Remove params from URL
			const pathnameWithoutParams = router.pathname.split('?')[0];
			// Find the index of the page that matches the current URL
			const pageIndex = pages.findIndex((page) => page?.url?.includes(pathnameWithoutParams));

			// If the page index is valid, set the active tab
			if (pathnameWithoutParams?.length > 1 && pageIndex >= 0 && pageIndex < pages.length) {
				setTab(pageIndex);
			} else {
				setTab(-1);
			}
		}

		doubleCheckInApp();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router, pages]);

	useEffect(() => {
		setFadeIn(true);
		doubleCheckInApp();

		setTimeout(() => {
			doubleCheckInApp();
		}, 1500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (phaseDependentState && categoryQuery && inApp) {
		return null;
	}

	return (
		<AppBar
			className='navbar-appbar'
			position='static'
			sx={{
				backgroundColor: 'background.dark',
				backgroundImage: 'none !important',
				borderRadius: '0px !important',
				boxShadow: 'none !important',
				maxWidth: '100vw',
				zIndex: (theme) => (theme?.zIndex?.drawer || 10000) + 1,
				borderBottom: (theme) => `1px solid ${theme.palette.background.offBlack}`,
			}}
		>
			<Toolbar
				className='navbar-container'
				disableGutters
				sx={{
					justifyContent: 'space-between',
					margin: 'min(3%, 10px) min(calc(8% / 4 * 2.5), 45px) !important',
					maxWidth: '100vw',
					padding: 0,
					zIndex: (theme) => (theme?.zIndex?.drawer || 10000) + 1,
				}}
			>
				{!responsiveView && (
					<Box sx={{ flexGrow: 0 }}>
						<Fade in={fadeIn} timeout={fadeInTimeout}>
							<IconButton onClick={() => setDrawerMenu(!drawerMenu)}>
								{drawerMenu ? <MenuOpenIcon /> : <MenuIcon />}
							</IconButton>
						</Fade>

						<Drawer
							anchor='left'
							className='navbar-drawer'
							onClick={() => setDrawerMenu(false)}
							onClose={() => setDrawerMenu(false)}
							open={drawerMenu}
							sx={drawerStyles}
						>
							<Toolbar
								sx={{
									backgroundColor: 'background.dark',
									minHeight: '5rem !important',
								}}
							/>

							{/* Add the pages as options, icon first then text */}
							<List>
								{/* Home button */}
								<ListItem
									key='home-listItem'
									sx={{
										'.MuiTypography-root': {
											fontWeight: tab === -1 ? 'bold !important' : '500',
										},
									}}
								>
									<LinkWrapper href={Env.MPX_WEBSITE}>
										<ListItemButton>
											<ListItemIcon>
												<HomeIcon />
											</ListItemIcon>
											<ListItemText primary='Home' />
										</ListItemButton>
									</LinkWrapper>
								</ListItem>

								{pages.map((page, index) => {
									if (page.hide) return null;

									return (
										<ListItem
											key={`${page.title}-listItem`}
											sx={{
												'.MuiTypography-root': {
													fontWeight: tab === index ? 'bold !important' : '500',
												},
											}}
										>
											<LinkWrapper
												href={page.disabled || page.isComingSoon ? '' : page.url}
												preventNewTab
											>
												<ListItemButton
													onClick={(e) => {
														setSingleAssetView(null);
														handleTabChange(e, index);
													}}
												>
													<ListItemIcon>{page.icon}</ListItemIcon>
													<ListItemText primary={page?.drawerTitle ?? page.title} />
												</ListItemButton>
											</LinkWrapper>
										</ListItem>
									);
								})}

								{currentUser && appType === 'genai' && (
									<Stack
										alignItems='center'
										direction='column'
										justifyContent='center'
										spacing={2}
										sx={{
											marginBottom: '2.5%',
										}}
									>
										<Divider
											// Increase thickness of divider
											sx={{
												width: '95%',
												borderColor: 'icons.greyed',
												margin: 'auto',
											}}
										/>

										<Button
											className='navbar-signup-button'
											onClick={() => {
												showDialog(BuyMoreCredit.getAsDialog());

												DataLayer.triggerMeasurementEvent('buyEvent', {
													event_name: 'click_get_more_credits',
													user_id: currentUser?.id,
													user_number_of_created_models: userHistory?.length ?? 0,
												});
											}}
											sx={{
												background: (theme) => theme.palette.gradient.main,
												backgroundColor: 'original.main',
												borderRadius: '100px',
												fontSize: 'clamp(0.5rem, 1rem, 1rem)',
												fontWeight: '700',
												letterSpacing: '0.0025em',
												lineHeight: '32px',
												marginRight: '0.5rem',
												paddingLeft: '0.7rem',
												paddingRight: '0.7rem',
												textTransform: 'none',
												whiteSpace: 'nowrap',
												width: '95%',
												'&:hover': {
													background: (theme) => theme.palette.gradient.hover,
												},
											}}
											variant='contained'
										>
											Get More Credits
										</Button>
									</Stack>
								)}
							</List>
						</Drawer>
					</Box>
				)}

				{/* Logo for large screens */}
				<Box sx={{ flexGrow: responsiveView ? 0 : 1 }}>
					<MPXLogo handleTabChange={handleTabChange} />
				</Box>

				{responsiveView && fadeIn && (
					<Fade in={fadeIn} timeout={fadeInTimeout}>
						<Box
							sx={{
								flexGrow: 1,
								display: 'flex',
							}}
						>
							<Grid
								container
								justifyContent='center'
								spacing={{ sm: 3, xs: 0.5 }}
								sx={{
									height: '100%',
									margin: 'auto !important',
								}}
							>
								{pages.map((page, index) => {
									if (!page.hide) {
										const commonProps = {
											'aria-label': `Change to ${page.title} tab`,
											onClick: (e) => {
												if (!page?.isComingSoon) {
													setSingleAssetView(null);
													handleTabChange(e, index);
												}
											},
											sx: {
												color:
													// eslint-disable-next-line no-nested-ternary
													tab === index ? 'primary.main' : 'text.primary',
												padding: 1,
												textTransform: 'none',
												'&:hover': {
													color: 'primary.contrastText',
													backgroundColor: 'primary.light',
												},
											},
											'data-tab-id': `navbar-tab-${page.title.toLowerCase().replace(' ', '-')}`,
											disabled: Boolean(page.disabled || page?.isComingSoon || false),
										};

										return (
											<Grid
												key={page.title}
												className='navbar-tab-icon'
												item
												style={{
													position: 'relative',
												}}
											>
												<LinkWrapper
													href={page.disabled || page.isComingSoon ? '' : page.url}
													preventNewTab
												>
													<Tooltip
														arrow
														describeChild
														title={
															page?.isComingSoon ? (
																<Typography
																	sx={{
																		alignItems: 'center',
																		display: 'flex',
																		flexDirection: 'column',
																		fontSize: '1rem',
																		textAlign: 'center',
																	}}
																	variant='caption'
																>
																	{page.title}

																	{page.isComingSoon && (
																		<>
																			<br />
																			Coming Soon!
																			{page.tooltip && (
																				<>
																					<br />
																					{page.tooltip}
																				</>
																			)}
																		</>
																	)}
																</Typography>
															) : null
														}
													>
														<Button {...commonProps} color='inherit' variant='text'>
															{page.title}
														</Button>
													</Tooltip>
												</LinkWrapper>
											</Grid>
										);
									}

									return null;
								})}
							</Grid>
						</Box>
					</Fade>
				)}

				<Fade in={fadeIn} timeout={fadeInTimeout}>
					<Box>
						<Stack alignItems='center' direction='row' justifyContent='flex-end' spacing={1}>
							<NavbarAccountSlots appType={appType} />

							{currentUser && appType === 'genai' && responsiveView && (
								<Button
									className='navbar-signup-button'
									onClick={() => {
										showDialog(BuyMoreCredit.getAsDialog());

										DataLayer.triggerMeasurementEvent('buyEvent', {
											event_name: 'click_get_more_credits',
											user_id: currentUser?.id,
											user_number_of_created_models: userHistory?.length ?? 0,
										});
									}}
									sx={{
										background: (theme) => theme.palette.gradient.main,
										backgroundColor: 'original.main',
										borderRadius: '100px',
										fontSize: 'clamp(0.5rem, 1rem, 1rem)',
										fontWeight: '700',
										letterSpacing: '0.0025em',
										lineHeight: '32px',
										marginRight: '0.5rem',
										paddingLeft: '0.7rem',
										paddingRight: '0.7rem',
										textTransform: 'none',
										whiteSpace: 'nowrap',
										'&:hover': {
											background: (theme) => theme.palette.gradient.hover,
										},
									}}
									variant='contained'
								>
									Get More Credits
								</Button>
							)}

							<NavbarAccountIcon appType={appType} />
						</Stack>
					</Box>
				</Fade>
			</Toolbar>
		</AppBar>
	);
}
