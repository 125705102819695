import { addViewCount, findProjectThumbnailUrl } from '@mpx-sdk/helpers/assets';
import { savEditMode, singleAssetViewAtom } from '@mpx-sdk/shared/atoms';
import Env from '@mpx-sdk/shared/configs/env';
import MetaHead from '@mpx-sdk/ui/components/core/MetaHead';
import Content from '@mpx-sdk/ui/components/single-asset-view/Content';
import SAVHeader from '@mpx-sdk/ui/components/single-asset-view/components/Header';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useAtom } from 'jotai';
import { isEmpty, isEqual, isNumber, mergeWith } from 'lodash';
import { useRouter } from 'next/router';
import { ReactElement, memo, useEffect, useRef } from 'react';

function SingleAssetView(): ReactElement {
	const router = useRouter();
	const { query: { slug = null } = {}, pathname } = useRouter();
	const assetId = pathname.startsWith('/library') && slug;

	const [editMode, setEditMode] = useAtom(savEditMode);
	const [projectData, setProjectData] = useAtom(singleAssetViewAtom);
	const pushStateAdded = useRef(false);
	const urlRef = useRef('');
	const cachedProjectId = useRef<number | string | undefined>(undefined);

	/** Handle closing the project's modal */
	const handleCloseModal = (changeRoute = true): void => {
		setEditMode(false);
		setProjectData(null);
		pushStateAdded.current = false;

		if (changeRoute && typeof window !== 'undefined' && window.history) {
			window.history.pushState({}, '', window.history.state?.prevUrl || urlRef.current || '/library');
		}
	};

	/** If this modal is still open after 2 seconds, add a view to the project */
	function incrementViewCount() {
		// After 2 seconds, add a view to the project
		if (typeof projectData?.id === 'number') {
			setTimeout(() => {
				if (projectData) {
					addViewCount(Number(projectData.id));
				}
			}, 2000);
		}
	}

	async function displayProjectData() {
		if (projectData) {
			// Add a view to the project
			incrementViewCount();

			// Check if the last history URL was not the project page
			if (
				!pushStateAdded.current &&
				isNumber(projectData?.id) &&
				projectData?.type !== 'history' &&
				window.location.origin === Env.MPX_WEBSITE
			) {
				const lastHistoryUrl =
					typeof window !== 'undefined' && window.history?.state?.prevUrl
						? window.history.state?.prevUrl
						: '';

				const lastHistoryPathname = lastHistoryUrl.split('?')[0];

				const currentPathname = `${window.location.origin}/library/${
					projectData?.title
						? `${
								// no special characters, replace underscore and spaces with dash
								projectData.title.replace(/[^a-zA-Z0-9-_ ]/g, '').replace(/[_ ]/g, '-')
						  }-`
						: ''
				}${projectData.id}`;

				if (lastHistoryPathname !== currentPathname && typeof window !== 'undefined' && window.history) {
					window.history.pushState(
						{
							prevUrl: window.location.href,
						},
						'',
						currentPathname,
					);
				}

				pushStateAdded.current = true;
			}

			// Check if in edit mode and exit it
			if (editMode) {
				setEditMode(false);
			}
		}
	}

	// On page change, close the modal if the url is not `/library/:id`
	useEffect(() => {
		if (router.pathname !== '/library/[...slug]') {
			handleCloseModal(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.pathname]);

	useEffect(() => {
		urlRef.current = window.location.href;

		if (!isEmpty(projectData)) {
			if (!isEqual(cachedProjectId.current, projectData?.id)) {
				displayProjectData();
			}

			cachedProjectId.current = projectData?.id;
		} else {
			handleCloseModal(false);

			cachedProjectId.current = undefined;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectData]);

	return (
		<>
			{!isEmpty(projectData) && (
				<MetaHead
					description={projectData?.description}
					image={findProjectThumbnailUrl(projectData?.files ?? [], projectData?.thumbnailUrl)}
					keywords={mergeWith([], projectData?.tags ?? [], projectData?.category ?? [])}
					publishDate={String(projectData?.createdAt)?.split('T')[0]}
					title={projectData?.title ?? 'Untitled Project'}
				/>
			)}

			{assetId ? (
				<div className='single-asset-view-container'>
					<Content key={`${projectData?.id ?? 'project-data'}-modal`} />
				</div>
			) : (
				<Dialog
					className='single-asset-view-container'
					fullWidth
					onClose={() => {
						handleCloseModal();
						setProjectData(null);
					}}
					open={!isEmpty(projectData)}
				>
					<DialogTitle
						sx={{
							backgroundColor: 'background.offBlack',
						}}
					>
						<SAVHeader onClose={handleCloseModal} />
					</DialogTitle>

					{/* Modal content */}
					<DialogContent
						sx={{
							width: '100%',
						}}
					>
						<Content key={`${projectData?.id ?? 'project-data'}-modal`} isDialog />
					</DialogContent>
				</Dialog>
			)}
		</>
	);
}

export default memo(SingleAssetView);
