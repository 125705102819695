import { Typography, TypographyProps } from '@mui/material';

function IconText({ children, key, ...props }: { children: React.ReactNode } & TypographyProps) {
	return (
		<Typography alignItems='center' display='flex' {...props}>
			{children}
		</Typography>
	);
}

export default IconText;
