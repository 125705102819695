import { assetsService } from '@mpx-sdk/api-client';
import { adminFeaturesAtom, userRolesAtom } from '@mpx-sdk/shared/atoms';
import { PublicAsset } from '@mpx-sdk/types';
import WarningSnackbar from '@mpx-sdk/ui/components/admin/WarningSnackbar';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useAtomValue } from 'jotai';
import { ReactElement, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

interface TogglePublicAssetProps {
	/** Whether the button should be displayed using default conditions [true, default] or by force [false] */
	conditionalDisplay?: boolean;
	/** Whether the warning should be hidden [false, default] or shown [true] */
	hideWarning?: boolean;
	/** The project data to toggle */
	projectData: PublicAsset;
}

export default function TogglePublicAssets({
	conditionalDisplay = true,
	hideWarning = false,
	projectData,
}: TogglePublicAssetProps): ReactElement | null {
	const [isPublic, setIsPublic] = useState<boolean>(true);
	const [openWarning, setOpenWarning] = useState<boolean>(false);
	const adminView = useAtomValue(adminFeaturesAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);

	const togglePublicAsset = async () => {
		if (currentUserRoles?.library && adminView) {
			try {
				await assetsService.adminUpdatePublicProject(projectData.id, {
					isPublic: !projectData.isPublic,
				});

				projectData.isPublic = !projectData.isPublic;
				setIsPublic(!isPublic);

				toast.success(`Project ${projectData.title} is now ${projectData.isPublic ? 'public' : 'private'}`);
			} catch (error: any) {
				console.error(error);
				toast.error(error?.message || 'Failed to toggle public asset');
			}
		} else {
			toast.error('Only admins can toggle public assets');
		}
	};

	useEffect(() => {
		setIsPublic(projectData.isPublic ?? false);
	}, [projectData]);

	return currentUserRoles?.library && (adminView || !conditionalDisplay) ? (
		<>
			<Tooltip
				arrow
				describeChild
				title={
					projectData.isPublic
						? `Make ${projectData?.title || 'Untitled project'} private to all but owner`
						: `Make Make ${projectData?.title || 'Untitled project'} public to all users`
				}
			>
				<IconButton
					aria-label='toggle public asset'
					className='admin-edit'
					onClick={togglePublicAsset}
					onMouseOver={() => {
						if (!hideWarning) {
							setOpenWarning(true);
						}
					}}
				>
					{isPublic ? <VisibilityIcon /> : <VisibilityOffIcon />}
				</IconButton>
			</Tooltip>

			<WarningSnackbar
				isOpen={openWarning}
				message={`Pressing this button will make ${projectData?.title || 'Untitled project'} ${
					isPublic ? 'private and hidden from all but owner' : 'public and visible to everyone'
				}. Are you sure you want to do this?`}
				onClose={() => setOpenWarning(false)}
			/>
		</>
	) : null;
}
