import { inAppBrowserAtom, store } from '@mpx-sdk/shared/atoms';
import Env from '@mpx-sdk/shared/configs/env';
import TagManager from 'react-gtm-module';

const gtmId = process.env.NEXT_PUBLIC_GTM_ID;

export class DataLayer {
	static init(initialDataLayerParams?: { [key: string]: any }) {
		const tagManagerArgs = {
			gtmId,
			dataLayer: {
				is_in_app_browser: store.get(inAppBrowserAtom),
				is_production: Env.SENTRY.ENV === 'production',
				...initialDataLayerParams,
			},
		};

		// if (process.env.NEXT_PUBLIC_GTM_AUTH && process.env.NEXT_PUBLIC_GTM_ENV) {
		//   tagManagerArgs.auth = process.env.NEXT_PUBLIC_GTM_AUTH;
		//   tagManagerArgs.env = process.env.NEXT_PUBLIC_GTM_ENV;
		// }

		TagManager.initialize(tagManagerArgs);
	}

	/**
	 * Send an event to the data layer.
	 *
	 * @param event_name The name of the event to track
	 * @param variables The variables to associate with the event, as a dictionary mapping variable names to values
	 */
	static triggerMeasurementEvent(event_name: string, variables: any) {
		window.dataLayer.push({
			event: event_name,
			...variables,
		});
	}

	/**
	 * Set the value of a single variable in the data layer.
	 *
	 * Once a variable is set, it is available to be sent with all events until it is cleared. These variables should
	 * not carry event-specific values; rather they should be values that apply to the page, user or session.
	 *
	 * @param variable_name The name of the variable to set
	 * @param variable_value The value of the variable to set
	 */
	static setVariable(variable_name: string, variable_value: any) {
		window.dataLayer.push({
			[variable_name]: variable_value,
		});
	}

	/**
	 * Set the value of a multiple variables in the data layer.
	 *
	 * Once a variable is set, it is available to be sent with all events until it is cleared. These variables should
	 * not carry event-specific values; rather they should be values that apply to the page, user or session.
	 *
	 * @param variables The variables to set, as a dictionary mapping variable names to values
	 */
	static setVariables(variables: any) {
		window.dataLayer.push(variables);
	}

	/**
	 * Send an error event to the data layer.
	 *
	 * @param attempted_action The name of the action attempted; this should match the event name that would be tracked
	 * had there been no error.
	 * @param error_code An error code, such as an HTTP status code
	 * @param variables The variables to associate with the event, as a dictionary mapping variable names to values
	 */
	static triggerErrorEvent(attempted_action: string, error_code?: string | number, variables?: any) {
		window.dataLayer.push({
			event: 'errorEvent',
			attempted_action,
			error_code,
			...variables,
		});
	}
}
