import { updateProjectData } from '@mpx-sdk/helpers/assets';
import { sanitizeString } from '@mpx-sdk/helpers/input';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { adminFeaturesAtom, savEditMode, singleAssetViewAtom, userAtom, userRolesAtom } from '@mpx-sdk/shared/atoms';
import { PublicAsset } from '@mpx-sdk/types';
import Comments from '@mpx-sdk/ui/components/single-asset-view/components/Comments';
import Description from '@mpx-sdk/ui/components/single-asset-view/components/Description';
import Information from '@mpx-sdk/ui/components/single-asset-view/components/Information';
import Interactions from '@mpx-sdk/ui/components/single-asset-view/components/Interactions';
import Metrics from '@mpx-sdk/ui/components/single-asset-view/components/Metrics';
import Tags from '@mpx-sdk/ui/components/single-asset-view/components/Tags';
import Thumbnail from '@mpx-sdk/ui/components/single-asset-view/components/Thumbnail';
import User from '@mpx-sdk/ui/components/single-asset-view/components/User';
import { Button, Grid, Tooltip } from '@mui/material';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { useAtom, useAtomValue } from 'jotai';
import { isEmpty, isNumber } from 'lodash';
import { ReactElement } from 'react';

interface ContentProps {
	/** If this is a dialog or not [default false] to show dialogue specific controls */
	isDialog?: boolean;
}

/** A component that displays the content of a single asset view. */
export default function Content({ isDialog = false }: ContentProps): ReactElement | null {
	const [editMode, setEditMode] = useAtom(savEditMode);
	const [projectData, setProjectData] = useAtom(singleAssetViewAtom);
	const adminView = useAtomValue(adminFeaturesAtom);
	const currentUser = useAtomValue(userAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);
	const projectPublic = isNumber(projectData?.id);
	const responsiveView = UIHelper.isResponsiveView();

	const subThumbnailContentStyles = {
		margin: 'auto 2%',
	};

	async function handleAssetEdit() {
		if (projectData?.type === 'history') {
			const title = sanitizeString(
				(
					document?.querySelector(
						`[data-modal-project-id="modal-project-${projectData?.id}"][data-modal-entry-type="title"] textarea`,
					) as HTMLInputElement
				)?.value ||
					projectData?.title ||
					'Untitled Project',
			)?.substring(0, 64);

			if (title && title !== projectData?.title) {
				updateDoc(doc(getFirestore(), `ml-requests/${projectData?.originalData?.id}`), {
					projectTitle: title,
				});
			}

			setProjectData({
				...projectData,
				title,
			});

			setEditMode(false);
		} else {
			if (projectData) {
				const newProjectData: PublicAsset | null = await updateProjectData(projectData);

				if (newProjectData) {
					setProjectData(newProjectData);
				}
			}

			setEditMode(false);
		}
	}

	return projectData?.id ? (
		<Grid
			alignItems='flex-start'
			className='single-asset-view'
			container
			direction='row'
			justifyContent='space-between'
			spacing={1}
			sx={
				isDialog
					? {
							backgroundColor: 'background.offBlack',
							padding: !responsiveView ? '1% 3%' : '1%',
							'.asset-model-viewer': {
								borderRadius: '20px',
							},
							width: '100%',
							maxWidth: '100%',
					  }
					: {}
			}
		>
			{/* Asset thumbnail */}
			<Grid className='sav-thumbnail-container' item xs={12}>
				<Thumbnail />
			</Grid>

			{/* Asset description and tags */}
			<Grid container item spacing={2} sx={subThumbnailContentStyles} xs={12}>
				{(projectPublic || !responsiveView) && (
					<Grid alignItems='center' container direction='row' item justifyContent='space-between'>
						<Grid item md={7} sm={12}>
							{projectPublic && <Metrics />}
						</Grid>

						<Grid
							item
							md={5}
							sm={12}
							sx={{
								display: 'flex',
								width: '100%',
							}}
						>
							<Interactions />
						</Grid>
					</Grid>
				)}

				<Grid item md={7} sm={12}>
					<User />
					<Description />
				</Grid>

				<Grid item md={5} sm={12}>
					{!projectPublic && responsiveView && <Interactions />}

					{projectPublic &&
						(!isEmpty(projectData.tags) ||
							currentUser?.id === projectData?.user?.id ||
							currentUser?.id === projectData?.owner?.id ||
							(currentUserRoles?.library && adminView)) && <Tags />}

					<Information />
				</Grid>
			</Grid>

			{(projectPublic || projectData?.type === 'history') && editMode && (
				<Tooltip
					arrow
					describeChild
					title={`Save changes and update "${projectData?.title || 'Untitled Project'}"`}
				>
					<Button
						className={`sav-header-open-button ${
							currentUserRoles?.library && adminView && currentUser?.id !== projectData?.user?.id
								? 'admin-button'
								: ''
						}`}
						color='primary'
						fullWidth
						onClick={() => handleAssetEdit()}
						sx={{
							m: 1,
							margin: '2em auto auto auto',
							maxWidth: '280px',
							backgroundColor: 'primary.main',
							'&:hover': {
								backgroundColor: 'primary.light',
							},
						}}
						variant='contained'
					>
						Save Changes
					</Button>
				</Tooltip>
			)}

			{/* Fast Comments */}
			{projectPublic && currentUser && (
				<Grid item sx={subThumbnailContentStyles} xs={12}>
					<Comments />
				</Grid>
			)}
		</Grid>
	) : null;
}
