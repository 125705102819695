import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { baseTheme } from './base';

export const lightTheme = createTheme({
	...baseTheme,
	palette: {
		mode: 'light',
		primary: {
			main: '#6750A4',
			contrastText: '#fff',
		},
		secondary: {
			light: '#808080',
			main: '#767575',
			contrastText: '#fff',
		},
		error: {
			main: red.A400,
		},
		background: {
			default: '#FFFFFF',
			paper: '#F9F9F9',
		},
	},
});
