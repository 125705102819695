import Layout from '@genai/components/layouts/Layout';
import Navbar from '@genai/components/navbar/Navbar';
import { Box } from '@mui/material';

function AdminLayout({ children }: { children: React.ReactNode }) {
	return (
		<Layout>
			<Box pb={4} pl={12} pr={4}>
				<Navbar />
				{children}
			</Box>
		</Layout>
	);
}

export default AdminLayout;
