import { extname } from 'path';

export const editFileName = (filename) => {
	const fileExtName = extname(filename);
	const randomName = Array(8)
		.fill(null)
		.map(() => Math.round(Math.random() * 16).toString(16))
		.join('');

	return `${randomName}${fileExtName}`;
};
