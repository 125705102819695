/* eslint-disable no-nested-ternary */
import { getUserSlots } from '@mpx-sdk/helpers/assets';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { CreditsIcon, ExportIcon, ImportIcon, RemixIcon } from '@mpx-sdk/images';
import { availableCreditsAtom, userAtom, userHistoryAtom, userSlotsAtom } from '@mpx-sdk/shared/atoms';
import { AssetSlots } from '@mpx-sdk/types';
import { useDialogService } from '@mpx-sdk/ui/components/core/DialogService';
import BuyMoreCredit from '@mpx-sdk/ui/components/purchase/BuyMoreCredit';
import { Box, Button, CircularProgress, Popover, Stack, Tooltip, Typography } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { find } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';

interface NavbarAccountSlotsProps {
	/** The type of app that the navbar is being used in */
	appType?: 'default' | 'genai';
}

/** Displays the number of slots used and credits available for the current user. */
export default function NavbarAccountSlots({ appType = 'default' }: NavbarAccountSlotsProps): ReactElement | null {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [userSlots, setUserSlots] = useAtom(userSlotsAtom);
	const availableCredits = useAtomValue(availableCreditsAtom);
	const currentUser = useAtomValue(userAtom);
	const userHistory = useAtomValue(userHistoryAtom);
	const { showDialog } = useDialogService();

	const style = {
		color: (theme) => theme.palette.primary.main,
		svg: {
			fill: (theme) => theme.palette.primary.main,
			color: (theme) => theme.palette.primary.main,
		},
		'&:hover': {
			color: (theme) => theme.palette.primary.light,
			svg: {
				fill: (theme) => theme.palette.primary.light,
				color: (theme) => theme.palette.primary.light,
			},
		},
	};

	const slotsDisplayOptions: any[] = [
		{
			id: 'projects',
			icon: <RemixIcon />,
			title: 'Remaining project slots',
			popover: appType !== 'default',
			value: (userSlots?.slotsProjects ?? 0) - (userSlots?.slotsProjectsUsed ?? 0),
		},
		{
			id: 'imports',
			icon: <ImportIcon />,
			title: 'Import credits',
			popover: appType !== 'default',
			value: (userSlots?.slotsPrivateImports ?? 0) - (userSlots?.slotsPrivateImportsUsed ?? 0),
		},
		{
			id: 'exports',
			title: 'Export credits',
			icon: <ExportIcon />,
			popover: appType !== 'default',
			value: (userSlots?.slotsPrivateExports ?? 0) - (userSlots?.slotsPrivateExportsUsed ?? 0),
		},
		{
			id: 'credits',
			icon: <CreditsIcon />,
			popover: appType === 'default',
			title: 'Generation credits',
			value: availableCredits ? (
				find(availableCredits?.currencies, {
					currency: 'credits',
				})?.balance ?? 0
			) : (
				<CircularProgress size='1.5rem' />
			),
		},
	];

	async function getSlotsData() {
		const newSlots: AssetSlots | null = await getUserSlots();

		if (newSlots) {
			setUserSlots(newSlots);
		}
	}

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		if (currentUser) {
			getSlotsData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser]);

	if (!currentUser) {
		return null;
	}

	return appType === 'genai' ? (
		<Button
			color='secondary'
			onClick={() => {
				showDialog(BuyMoreCredit.getAsDialog());

				DataLayer.triggerMeasurementEvent('buyEvent', {
					event_name: 'click_get_more_credits',
					user_id: currentUser?.id,
					user_number_of_created_models: userHistory?.length ?? 0,
				});
			}}
			variant='contained'
		>
			<Tooltip arrow describeChild title='Credits'>
				<Typography alignItems='center' component={Stack} direction='row' justifyContent='center'>
					<CreditsIcon />
					&nbsp;
					{availableCredits ? (
						find(availableCredits?.currencies, {
							currency: 'credits',
						})?.balance ?? 0
					) : (
						<CircularProgress size='1.5rem' />
					)}
				</Typography>
			</Tooltip>
		</Button>
	) : userSlots ? (
		<>
			<Stack
				alignItems='center'
				aria-haspopup='true'
				aria-owns={anchorEl ? 'mouse-over-popover' : undefined}
				direction='row'
				justifyContent='center'
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
				spacing={1}
				sx={{
					marginRight: '0.75rem',
				}}
			>
				{slotsDisplayOptions.map(({ id, popover, icon, value, hide }) => {
					if (hide || popover) {
						return null;
					}

					return (
						<Stack
							key={`navbar-account-slots-${id}`}
							alignItems='center'
							direction='row'
							spacing={1}
							sx={style}
						>
							{icon}
							<Box
								sx={{
									color: (theme) => theme.palette.text.primary,
								}}
							>
								{value}
							</Box>
						</Stack>
					);
				})}
			</Stack>

			<Popover
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				disableRestoreFocus
				id='mouse-over-popover'
				onClose={handlePopoverClose}
				open={Boolean(anchorEl)}
				sx={{
					pointerEvents: 'none',
					'& .MuiPopover-paper': {
						borderRadius: '5%',
						padding: '0.75rem',
					},
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Typography
					sx={{
						textAlign: 'center',
						marginBottom: '1rem',
					}}
					variant='h6'
				>
					Credits
				</Typography>

				{/* Display each credit available (if hide, then hide). Need to display Icon, title, value */}
				<Stack
					alignItems='flex-start'
					direction='column'
					spacing={1}
					sx={{
						marginBottom: '0.5rem',
					}}
				>
					{slotsDisplayOptions.map(({ id, icon, title, value, hide }) => {
						if (hide) {
							return null;
						}

						return (
							<Stack
								key={`navbar-account-slots-${id}`}
								alignItems='center'
								direction='row'
								justifyContent='space-between'
								spacing={2}
								sx={{
									width: '100%',
								}}
							>
								<Typography
									sx={{
										alignItems: 'center',
										display: 'flex',
										justifyContent: 'center',
										color: (theme) => theme.palette.text.primary,
									}}
								>
									{title}:
								</Typography>

								<Typography
									sx={{
										alignItems: 'center',
										display: 'flex',
										justifyContent: 'center',
										color: (theme) => theme.palette.text.primary,
										textAlign: 'right',
										svg: {
											color: (theme) => theme.palette.primary.main,
											margin: '0 0.5rem',
										},
									}}
								>
									{icon}
									{value}
								</Typography>
							</Stack>
						);
					})}
				</Stack>
			</Popover>
		</>
	) : null;
}
