/**
 * Handle the horizontal scrolling of a grid component (e.g. horizontal scrolling of asset subsections */
export function scrollDirection(
	/** Grid component as a HTML div element (for example. a React's useRef) */
	gridComponent: HTMLDivElement | null,
	/** Direction to scroll in: 'left' | 'right' */
	direction: 'left' | 'right',
) {
	if (gridComponent) {
		// Scroll the grid by the width of the grid item
		const gridItemWidth = gridComponent.querySelector('[data-grid-item-type="horizontal-asset"]')?.clientWidth;

		if (gridItemWidth && gridItemWidth > 0) {
			/** The horizontal/x position that the grid will scroll to */
			const scrollToPosition: number =
				(Math.round(gridComponent.scrollLeft / gridItemWidth) + (direction === 'left' ? -1 : 1)) *
				gridItemWidth;

			gridComponent.scrollTo({
				left: scrollToPosition > 0 ? scrollToPosition : 0,
				behavior: 'smooth',
			});
		}
	}
}

/**
 * Handle the horizontal resizing of a grid component and determine whether to show the scroll buttons */
export function handleHorizontalGridResize(
	/** Grid component as a HTML div element (for example. a React's useRef) */
	gridComponent: HTMLDivElement | null,
	/** Function to set the showScrollButtons state */
	setScrollStateButtons: (boolean) => void,
) {
	if (gridComponent) {
		// Find the width of the first data-grid-item-type="horizontal-asset" element
		const gridItemWidth = gridComponent?.querySelector('[data-grid-item-type="horizontal-asset"]')?.clientWidth;
		// Find out how many there are of data-grid-item-type="horizontal-asset"
		const gridItemCount = gridComponent?.querySelectorAll('[data-grid-item-type="horizontal-asset"]').length;
		// Find total width of grid by multiplying the width of the grid item by the number of grid items
		const gridWidth = gridItemWidth && gridItemCount ? gridItemWidth * gridItemCount : 0;

		// The width of the "asset-subsection-stack" if exists, if multiple asset subsections are on the page then use any of them as they should all be the same width
		const assetSubsectionStackWidth = document.querySelector('.asset-subsection-stack')?.clientWidth;

		// If the grid width is greater than the asset subsection stack width then show the scroll buttons
		if (gridWidth && assetSubsectionStackWidth && gridWidth > assetSubsectionStackWidth) {
			setScrollStateButtons(true);
		} else {
			setScrollStateButtons(false);
		}
	}
}

/**
 * Handle the horizontal resizing of a user grid component to determine whether to show the scroll buttons and how many avatars should be displayed
 */
export function handleUpdateUserAvatarGrid(
	/** Grid component as a HTML div element (for example. a React's useRef) */
	gridComponent: HTMLDivElement | null,
	/** Function to set the showScrollButtons state */
	setScrollStateButtons: (boolean) => void,
	/** How much to adjust the spacing of the avatars by the grid spacing */
	gridSpacingPixelIncrease: number,
	/** Function to set the displayMaxWidth state */
	setDisplayMaxWidth: (string) => void,
) {
	if (gridComponent) {
		// handleHorizontalGridResize(gridComponent, setScrollStateButtons);

		// Also updating the max width of the asset subsection stack
		let avatarWidth = gridComponent?.querySelector('[data-user-grid-avatar]')?.clientWidth;

		if (avatarWidth && avatarWidth > 0) {
			// Due to MUI grid spacing, need to add the spacing to the avatar width
			avatarWidth += gridSpacingPixelIncrease * 2;

			const sixTimesAvatarWidth = 6 * avatarWidth;

			const closestMultipleOfAvatarWidth = Math.round((window.innerWidth * 0.8) / avatarWidth) * avatarWidth;

			if (closestMultipleOfAvatarWidth < sixTimesAvatarWidth) {
				setDisplayMaxWidth(`${closestMultipleOfAvatarWidth}px`);
			} else {
				setDisplayMaxWidth(`${sixTimesAvatarWidth}px`);
			}
		}
	}
}
