import { adminFeaturesAtom, userRolesAtom } from '@mpx-sdk/shared/atoms';
import { PublicAsset, UserProfile } from '@mpx-sdk/types';
import WarningSnackbar from '@mpx-sdk/ui/components/admin/WarningSnackbar';
import { Button, Dialog, DialogTitle, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { ReactElement, useEffect, useState } from 'react';

interface ConfirmDeleteProps {
	/** If want to give custom function upon click delete, it would be given here  */
	deleteFunction: () => void;
	/** Whether the confirm delete boolean is open or not */
	isOpen: boolean;
	/** If want to give custom function upon closing the delete module, it would be given here  */
	onClose: () => void;
	projectData: PublicAsset;
	currentUser?: Partial<UserProfile> | null;
	/** Where the source of the project is assumed to be (library, continue or private) - Only used for displaying admin warning */
	sourceType?: string;
}

/**
 * Create a confirmation dialog for deleting an asset
 * @returns {ReactElement} A confirmation dialog for deleting an asset with a warning and two buttons: cancel and delete
 */
export default function ConfirmDelete({
	deleteFunction,
	isOpen,
	onClose,
	projectData,
	currentUser,
	sourceType,
}: ConfirmDeleteProps): ReactElement {
	const adminView = useAtomValue(adminFeaturesAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);
	const [adminDisplayWarning, setAdminDisplayWarning] = useState(
		currentUserRoles?.library && adminView && sourceType === 'library' && isOpen,
	);

	useEffect(() => {
		setAdminDisplayWarning(currentUserRoles?.library && adminView && sourceType === 'library' && isOpen);
	}, [isOpen, currentUser, sourceType, adminView, currentUserRoles]);

	return (
		<Dialog key={`delete-dialog-${projectData?.id}`} onClose={onClose} open={isOpen}>
			<DialogTitle>
				<Typography>Are you sure you want to delete {projectData?.title || 'Untitled Project'}?</Typography>
				<Typography>This cannot be undone.</Typography>
			</DialogTitle>
			<Stack alignItems='center' direction='row' justifyContent='space-evenly' spacing={2}>
				<Button
					color='error'
					onClick={() => {
						onClose?.();
						deleteFunction?.();
					}}
					variant='contained'
				>
					Yes
				</Button>
				<Button
					onClick={() => {
						onClose?.();
					}}
					variant='contained'
				>
					No
				</Button>
			</Stack>

			{currentUserRoles?.library && adminView && sourceType === 'library' && (
				<WarningSnackbar
					isOpen={adminDisplayWarning}
					message='You are currently an admin and about to delete a project/asset from the public library. This will remove the files, bookmarks and the asset itself from the original owner. This cannot be undone. NOTE: The files itself will remain on Firebase, just not accessible from the web app or in-app experience anymore.'
					onClose={() => setAdminDisplayWarning(false)}
				/>
			)}
		</Dialog>
	);
}
