import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';

interface UpdateMessage {
	title: string;
	body: string | ReactElement;
}

export default function UpdatesDialog(): ReactElement | null {
	const router = useRouter();

	const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
	const [hasSeenUpdate, setHasSeenUpdate] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState(false);

	const textStyle = {
		fontSize: 'clamp(0.7rem, 1rem, 1.5rem)',
	};

	/** Update this date to show this update dialog again */
	const updateStamp = '2023-10-12';
	const expireUpdate = '2023-10-20';
	const itemName = `hasSeenUpdate-${updateStamp}`;

	const welcomeMessages: UpdateMessage[] = [
		{
			title: 'Masterpiece X - Generate is available now!',
			body: (
				<Typography sx={textStyle}>
					Masterpiece X - Generate is a complete text-to-3D playground designed for rapid exploration &
					prototyping
					<br />
					<br />
					<b>All you need is a keyboard, a browser, a little imagination, and just a few words!</b>
					<br />
					<br />
					Then remix your generated models here on Meta Quest!
					<br />
					<br />
					Login to masterpiecex.com to claim 250 bonus credits and start generating 3D now!
					<br />- Masterpiece Studio Team
				</Typography>
			),
		},
	];

	const handleContinueExploring = () => {
		if (currentMessageIndex < welcomeMessages.length - 1) {
			setCurrentMessageIndex((prevIndex) => prevIndex + 1);
		} else {
			setIsOpen(false);
			if (localStorage) {
				localStorage?.setItem(itemName, 'true');
				setHasSeenUpdate(true);
			}
		}
	};

	const checkHasSeenUpdate = debounce(() => {
		const hasSeenUpdate = localStorage?.getItem(itemName);

		if (hasSeenUpdate || new Date() > new Date(expireUpdate)) {
			setHasSeenUpdate(true);
			setIsOpen(false);
		} else {
			setIsOpen(true);
		}

		if (localStorage) {
			localStorage?.setItem(itemName, 'true');
		}
	}, 1000);

	useEffect(() => {
		checkHasSeenUpdate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (hasSeenUpdate) {
		return null; // Render nothing if the user has already seen the welcome dialog or if the current route is not index
	}

	// Set custom conditions for when this dialog should NOT be shown
	if (router.pathname.startsWith('/genai')) {
		return null;
	}

	const currentMessage = welcomeMessages[currentMessageIndex];

	return (
		<Dialog
			aria-modal='true'
			data-testid='welcome-dialog'
			onClose={() => {
				setIsOpen(false);
			}}
			open={isOpen}
			PaperProps={{
				sx: {
					backgroundColor: '#fff',
					color: 'original.contrastText',
				},
			}}
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				textAlign: 'center',
			}}
		>
			<DialogTitle
				sx={{
					fontWeight: 'bold',
				}}
				variant='h4'
			>
				{currentMessage.title}
			</DialogTitle>
			<DialogContent>
				{currentMessage.body}

				<Stack
					alignItems='center'
					direction='row'
					justifyContent='center'
					spacing={3}
					sx={{
						marginBottom: '1rem',
					}}
				>
					{currentMessageIndex === welcomeMessages.length - 1 ? (
						<Button
							onClick={handleContinueExploring}
							role='button'
							sx={{
								background: (theme) => theme.palette.gradient.main,
								borderRadius: '100px',
								fontSize: 'clamp(0.5rem, 1rem, 1rem)',
								fontWeight: '700',
								letterSpacing: '0.0025em',
								lineHeight: '32px',
								paddingLeft: '1.25rem',
								paddingRight: '1.25rem',
								textTransform: 'none',
								whiteSpace: 'nowrap',
								'&:hover': {
									background: (theme) => theme.palette.gradient.hover,
								},
							}}
							variant='contained'
						>
							Got It!
						</Button>
					) : (
						<Button
							color='secondary'
							onClick={handleContinueExploring}
							role='button'
							sx={{
								width: '100%',
							}}
							variant='contained'
						>
							Next
						</Button>
					)}
				</Stack>
			</DialogContent>
		</Dialog>
	);
}
