import { accessTokenProvider } from '@api-client/services/access-token.provider';
import { urls } from '@mpx-sdk/shared/configs/urls';
import axios, { Method, RawAxiosRequestHeaders, isAxiosError } from 'axios';

abstract class BaseService {
	// eslint-disable-next-line class-methods-use-this
	protected async request<T>(
		url: string,
		method: Method,
		data?: Record<string, unknown> | FormData,
		headers: RawAxiosRequestHeaders = {},
		/** Flag to track if access token has been refreshed */
		isAccessTokenRefreshed = false,
	) {
		// This contains the data or params depending on the method, if it's POST then params will be undefined and vice versa for GET
		const dataOrParam: { data?: any; params?: any } = method.toLowerCase() === 'get' ? { params: data } : { data };
		url = ['http://', 'https://'].some((prefix) => url.startsWith(prefix)) ? url : urls.api.base + url;

		try {
			return (
				await axios<T>({
					url,
					method,
					...dataOrParam,
					headers: {
						...headers,
						Authorization: headers.Authorization ?? `Bearer ${await accessTokenProvider.getAccessToken()}`,
					},
				})
			).data;
		} catch (error) {
			if (isAxiosError(error) && error.response?.status === 401 && !isAccessTokenRefreshed) {
				await accessTokenProvider.getNewAccessToken();
				return this.request(url, method, data, headers, true);
			}
			throw error;
		}
	}

	protected async get<T>(url: string, params?: Record<string, unknown>, headers?: RawAxiosRequestHeaders) {
		return this.request<T>(url, 'GET', params, headers);
	}

	protected async post<T>(url: string, data?: Record<string, unknown>, headers?: RawAxiosRequestHeaders) {
		return this.request<T>(url, 'POST', data, headers);
	}

	protected async put<T>(url: string, data?: Record<string, unknown>, headers?: RawAxiosRequestHeaders) {
		return this.request<T>(url, 'PUT', data, headers);
	}

	protected async delete<T>(url: string, data?: Record<string, unknown>, headers?: RawAxiosRequestHeaders) {
		return this.request<T>(url, 'DELETE', data, headers);
	}

	protected async patch<T>(url: string, data?: Record<string, unknown>, headers?: RawAxiosRequestHeaders) {
		return this.request<T>(url, 'PATCH', data, headers);
	}

	protected async multipartPost<T>(url: string, data: FormData, headers?: RawAxiosRequestHeaders) {
		return this.request<T>(url, 'POST', data, {
			...headers,
			'Content-Type': 'multipart/form-data',
		});
	}
}

export default BaseService;
