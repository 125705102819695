import { inAppBrowserAtom, store } from '@mpx-sdk/shared/atoms';
import { urls } from '@mpx-sdk/shared/configs/urls';
import { AssetSlots, PublicAsset, UserProfile } from '@mpx-sdk/types';
import { isEmpty, memoize } from 'lodash';
import { request } from '../api';

/** Sorted project data that has been retrieved */
export const projectsRetrieved: any = {};
/** All project data retrieved */
export const allProjectData: any = {};

// Functions relating to getting projects from the PostgreSQL database
/**
 * Get the maximum number of projects to retrieve when infinite scrolling based on screen
 * @returns {Number} The maximum number of projects to retrieve [default = 30]
 */
export function getInfiniteScrollingMaxResults(
	/** Whether to paginate (infinite scroll) [true] or not [false, default] */
	pagination = true,
	/** The absolute maximum number of projects to retrieve */
	absoluteMax = 50,
): number {
	/** The maximum number of projects to retrieve */
	let maxResults = 30;

	/** Main document containing the projects */
	const mainDoc = document?.getElementsByTagName('main')?.[0];

	/** Any project's document element */
	const project = document?.getElementsByClassName('asset-card')?.[0] as HTMLElement;

	// If main document and project document exists, get maximum number of projects based on screen
	if (mainDoc && project) {
		/** Main document's height */
		const mainDocHeight = mainDoc.offsetHeight;
		/** Main document's width */
		const mainDocWidth = mainDoc.offsetWidth;
		/** Project's height */
		const projectHeight = project.offsetHeight;
		/** Project's width */
		const projectWidth = project.offsetWidth;

		// Maximum number of projects to call based on height
		maxResults = Math.floor(mainDocHeight / projectHeight) * Math.floor(mainDocWidth / projectWidth);
		// Buffer room
		maxResults += Math.floor(mainDocWidth / projectWidth) * 4;
	}

	// If pagination is not yet requested, double amount of projects to retrieve
	if (!pagination) {
		maxResults *= 2;
	}

	// Ensure max results is a number and less than the absolute max number of calls
	maxResults = Math.floor(maxResults);
	maxResults = maxResults > absoluteMax ? absoluteMax : maxResults;

	// Return the maximum number of projects to retrieve
	return maxResults;
}

/**
 * Given the project IDs list, get which projects were liked by the user
 * @returns {Array} List of project IDs that the user liked
 */
export async function getWhichProjectsLiked(
	/** User ID to check if the projects were liked by the user */
	userId: number,
	/** List of project IDs */
	projectIdList: any,
): Promise<any | null> {
	if (userId && projectIdList?.length > 0) {
		// Check projectIdList to ensure only include integer values
		if (Array.isArray(projectIdList) && projectIdList?.length > 0) {
			projectIdList = projectIdList.filter((projectId) => !Number.isNaN(projectId));
			projectIdList = projectIdList.map((projectId: string) => parseInt(projectId, 10));

			// Now convert to string and trim whitespace
			projectIdList = projectIdList
				.map((projectId: { toString: () => string }) => projectId.toString()?.trim())
				.join(',');
		}

		if (projectIdList?.trim()?.length > 0) {
			// Get list of project IDs that the user liked
			return request(`/project/getUserLikedProjects/${userId}?projectIds=${projectIdList}`, 'GET')
				.then((data) => {
					const organizedData = {};

					if (data?.data?.length > 0) {
						// eslint-disable-next-line no-restricted-syntax
						for (const i in data.data) {
							if (data.data[i]) {
								organizedData[data.data[i].projectId] = {
									liked: data.data[i].liked,
									bookmarked: data.data[i].bookmarked,
								};
							}
						}
					}

					return organizedData;
				})
				.catch((err) => {
					console.error(err);
					return null;
				});
		}

		return null;
	}

	return null;
}

export const infiniteScrollingData = {};
/**
 * Get project data
 * @returns {Array} List of requested project data
 */
export async function getProjectData(
	/** Custom search parameters */
	inputOptions?: any,
	/** User ID to check if the projects were liked by the user */
	userId?: number,
	/** Type of project data: 'liked', 'starred', etc... */
	type?: string,
	/** Whether to retry calling project data [true] or not [false, default] */
	retry = false,
): Promise<PublicAsset[] | null> {
	/** Whether should retrieve data from API [true, default] or not [false] */
	let getData = true;

	// If given project ID(s), see if already retrieved or not first
	if (inputOptions?.projectId) {
		/** Project ID(s) */
		let { projectId } = inputOptions;

		// If not an array, make it one
		if (!Array.isArray(projectId)) {
			if (typeof projectId === 'string') {
				projectId = projectId.split(',').map(Number);
			} else if (typeof projectId === 'number') {
				projectId = [projectId];
			}
		}

		/** The data to return if found */
		const returnData: PublicAsset[] = [];
		// Temporarily set retrieve data to false
		getData = false;
		// Go through each project ID and see if it has been retrieved already
		// eslint-disable-next-line no-restricted-syntax
		for (const i in projectId) {
			if (!allProjectData[projectId[i]]) {
				// If at least one not retrieved, get data from API
				getData = true;
			} else {
				returnData.push(allProjectData[projectId[i]]);
			}
		}

		// If all project IDs were found, return them
		if (!getData) {
			return returnData;
		}
	}

	// Get data from API
	if (getData) {
		/** Maximum number of projects that should be retrieved depending on screen */
		const defaultMaxResults = inputOptions?.maxResults ?? getInfiniteScrollingMaxResults() ?? 20;

		/** Whether to retrieve latest or featured projects */
		let options: Partial<any> = {
			/** Maximum number of projects want returned at once */
			maxResults: defaultMaxResults,
			/** Pagination index */
			paginatingIndex: inputOptions?.paginatingIndex ?? 0,
		};

		options = { ...options, ...inputOptions };

		// If query includes comma, split it into multiple queries
		if (options.query && typeof options.query === 'string') {
			const andKeys = [',', '&', 'and'];
			const orKeys = ['|', 'or'];

			let foundType = false;
			// eslint-disable-next-line no-restricted-syntax
			for (const i in andKeys) {
				if (options.query.toLowerCase().includes(andKeys[i])) {
					foundType = true;

					options.query = [...options.query.split(andKeys[i]).map((entry) => entry.trim())];
					options.queryArrayType = 'and';

					break;
				}
			}
			if (!foundType) {
				// eslint-disable-next-line no-restricted-syntax
				for (const i in orKeys) {
					if (typeof options.query === 'string' && options.query.toLowerCase().includes(orKeys[i])) {
						options.query = [options.query, ...options.query.split(orKeys[i]).map((entry) => entry.trim())];
						options.queryArrayType = 'or';

						break;
					}
				}
			}

			if (Array.isArray(options.query)) {
				// Remove empty entries and trim each entry
				options.query = options.query.filter((entry) => entry).map((entry) => entry.trim());

				options.query = [...new Set(options.query)];
			}
		}

		// Go through all options and remove any undefined values or empty strings
		// eslint-disable-next-line no-restricted-syntax
		for (const [key, value] of Object.entries(options)) {
			if (value === '' || value === undefined) {
				delete options[key];
			}
		}

		// Convert project ID(s) to string for API
		if (inputOptions?.projectId) {
			if (Array.isArray(inputOptions.projectId)) {
				options.projectId = inputOptions.projectId.join(',');
			}
		}

		let apiURL = `/project`;
		if (type === 'liked') {
			apiURL = `/me/likes`;
		}

		/** New data being retrieved */
		return request(apiURL, 'get', options)
			.then(async (data) => {
				/** Project data */
				let projectData = data?.data;

				if (projectData) {
					if (type) {
						infiniteScrollingData[type] = projectData.length < (options?.maxResults ?? 0);
					}

					// If projectData is a single object instead of an array, convert it to an array
					if (!Array.isArray(projectData)) {
						projectData = [projectData];
					}

					// Filter out projects that have files but no downloadUrl for those files
					projectData = projectData.filter(
						(project: {
							files: {
								filter: (arg0: (file: any) => any) => {
									(): any;
									new (): any;
									length: number;
								};
							};
						}) => {
							if (project?.files) {
								return (
									project.files.filter((file: { downloadUrl: any }) => file.downloadUrl).length > 0
								);
							}
							return true;
						},
					);

					// If user is logged in, check if the projects were liked by the user
					if (userId) {
						// Get list of all project IDs
						const projectIds = projectData?.map((project: { id: any }) => project.id);

						// Get list of all project IDs that the user liked
						const likedProjects = (await getWhichProjectsLiked(userId, projectIds)) || [];

						// Go through all projects and see if they are liked
						// eslint-disable-next-line no-restricted-syntax
						for (const i in projectData) {
							if (likedProjects[projectData[i].id]) {
								projectData[i].liked = true;
								projectData[i].bookmarked = true;
							} else {
								projectData[i].liked = false;
								projectData[i].bookmarked = false;
							}
						}
					}

					// Store in allProjectData where project ID is the key
					// eslint-disable-next-line no-restricted-syntax
					for (const i in projectData) {
						if (projectData[i]) {
							allProjectData[projectData[i].id] = projectData[i];
						}
					}
				}

				// Return the data
				return projectData;
			})
			.catch((err) => {
				console.error(err);

				if (!retry) {
					// Try again
					return getProjectData(inputOptions, userId, type, true);
				}

				return null;
			});
	}

	// Default return statement
	return null;
}

export async function getUserData(userId, howMany = 1) {
	return request('/project', 'get', {
		user: userId,
		maxResults: howMany,
		sort: 'new',
	}).then((data) => data?.data?.[0] ?? null);
}

export async function preProcessSingleProject(id: string) {
	return request(`/project`, 'get', {
		projectId: id,
		maxResults: 1,
	}).then((data) => data?.data?.[0] ?? null);
}

/**
 * Get all projects a user has interacted with based on type
 * @returns Array of projects the user has interacted with based on type
 */
export async function getAllProjectsUserInteractedWith(
	userId: UserProfile['id'],
	type: 'bookmarked' | 'liked' = 'bookmarked',
): Promise<Array<any> | null> {
	if (userId) {
		return request(`/project/getAllProjectsUserInteractedWith/${userId}?type=${type}`, 'GET')
			.then((data) => data?.data)
			.catch((err) => {
				console.error(err);
				return null;
			});
	}

	return null;
}

/**
 * Get bookmarked projects for a user
 * @returns Array of bookmarked projects from the user
 */
export async function getBookmarkedProjects(
	currentUser: UserProfile,
	howMany = 5,
	pagination = 0,
	sort = 'new',
): Promise<PublicAsset[] | null> {
	if (currentUser?.id) {
		const bookmarkData = await getAllProjectsUserInteractedWith(currentUser?.id, 'bookmarked');

		if (!isEmpty(bookmarkData)) {
			const options: any = {
				maxResults: howMany,
				bookmarkedBy: currentUser?.id,
				paginatingIndex: pagination,
			};

			const bookmarkIds: Array<PublicAsset['id']> | undefined = bookmarkData?.map((item) => item?.projectId);
			options.projectId = bookmarkIds;

			options.sort = sort;

			const projectData: PublicAsset[] | null = await getProjectData(options, currentUser?.id, 'personal');

			return projectData ?? null;
		}
	}

	return null;
}

export async function getUserSlots(): Promise<AssetSlots | null> {
	/** User's social data */
	const data = await request(`/me/slots`, 'get');

	if (data?.data) {
		const slots = data.data;

		// Convert all values to numbers or 0
		// eslint-disable-next-line no-restricted-syntax
		for (const [key, value] of Object.entries(slots)) {
			slots[key] = Number(value) || 0;
		}

		return slots;
	}

	return null;
}

// Following related to getting cloud/private projects
export async function updateCloudDownloadInfo(originalProjectData: PublicAsset) {
	const projectData: PublicAsset = { ...originalProjectData };

	if (projectData?.id) {
		const downloadLink = await request(`/my-space/download/${projectData.id}`, 'GET');

		if (downloadLink?.data?.glb?.url) {
			// eslint-disable-next-line no-restricted-syntax
			for (const i in projectData.files) {
				if (projectData.files[i].name?.endsWith('.glb')) {
					projectData.files[i].downloadUrl = downloadLink.data.glb.url;
					break;
				}
			}
		}
	}

	return projectData;
}

export async function getCloudPrivateAssetDownloadInfo(data: any[]) {
	const downloadData = Array.isArray(data) ? [...data] : [];

	await Promise.all(
		downloadData.map(async (project) => {
			if (project && project.id) {
				const downloadLink = await request(`/my-space/download/${project.id}`, 'GET');

				if (downloadLink?.data) {
					project.files = [];

					// eslint-disable-next-line no-restricted-syntax
					for (const [key, archive] of Object.entries<{
						name: string;
						url: string;
						size: number;
						filePath: string;
					}>(downloadLink?.data)) {
						const files: Partial<any> = {};

						if (key === 'glb') {
							if (project.metadata) {
								files.metadata = project?.metadata;
							}
						}

						if (archive?.size) {
							files.size = archive?.size;
						}

						if (archive?.filePath) {
							// This is the file name
							files.name = archive?.filePath.split('/').pop();
						}

						if (archive?.url) {
							files.downloadUrl = archive?.url;
						}

						// If files not empty, add to project files
						if (Object.keys(files).length > 0) {
							project.files.push(files);
						}
					}

					if (project.thumbnailUrl) {
						const files: Partial<any> = {};

						files.name = project?.thumbnailUrl.split('?')[0].split('/').pop();

						files.downloadUrl = project?.thumbnailUrl;

						project.files.push(files);

						delete project.thumbnailUrl;
					}
				}
			}
		}),
	);

	return downloadData;
}

export async function processCloudPrivateDataToMPSProjects(
	data: any,
	currentUser: Partial<UserProfile>,
	getDownloadLinksNow = true,
) {
	let processData = [...data];

	// First convert to appropriate format
	processData.forEach(async (project) => {
		if (!isEmpty(project)) {
			// Delete archives and metadata and thumbnailUrl from project
			delete project?.archives;
			delete project?.metadata;

			if (currentUser) {
				if (!project.user) {
					project.user = {};
				}

				project.user = {
					id: currentUser.id,
					photoUrl: currentUser?.photoUrl,
					username: currentUser?.username,
					name: currentUser?.useName
						? `${currentUser?.firstName} ${currentUser?.lastName}`
						: currentUser?.username || currentUser?.id,
				};
			}

			// Trim project title
			if (project?.title) {
				project.title = project.title.trim();
			}
		}
	});

	if (getDownloadLinksNow) {
		processData = await getCloudPrivateAssetDownloadInfo(processData);
	}

	return processData;
}

export async function getCloudPrivateProjectData(
	currentUser: UserProfile,
	optionalData: { limit?: any; offset?: number; sort?: string },
	convertToMPSProjectFormat = true,
	getDownloadLinksNow = true,
) {
	let data: any = await request('/my-space/assets', 'GET', optionalData || {});

	if (data?.data) {
		data = data.data;

		if (convertToMPSProjectFormat && currentUser) {
			data = processCloudPrivateDataToMPSProjects(data, currentUser, getDownloadLinksNow);
		}

		return data;
	}

	return null;
}

/** Trending tags */
let trendingTags = [];
/** Retrieved tags */
const retrievedTags = {};
/**
 * Get related tags for based on given tags
 * @param {String | Array} [tags] Tags to get related tags for (optional)
 * @param {Number} [howMany=5] How many tags to retrieve
 * @returns {Array} Related tags to given tags (or trending if no tags are given)
 * @example <caption>To get related tags, give tags as a string or array of strings or to get trending tags, leave tags empty/undefined</caption>
 * getRelatedTags(5, 'tank')
 * // returns ["vehicle", "Military", "Environment", "Robotics", "Human"] (vehicle and Military are given because of frequency overlap but because that is less than the requested amount, the rest are given based on trending weights)
 * @export @async
 * @see {@link sanitizeString}
 */
export async function getRelatedTags(tags: string | Array<string>, howMany = 5): Promise<any[]> {
	if (tags) {
		// If tags are a string, convert to array
		if (typeof tags === 'string') {
			// If string is an array as string, convert back
			if (tags.startsWith(`[`) && tags.endsWith(`]`)) {
				// Remove the first `['` and last `']`;
				tags = tags.substring(2, tags.length - 2);
				// Split the string by `', '` OR `", "`
				tags = tags.split(/['"][,][ ]*['"]/);
			} else {
				tags = tags.trim()?.split(',');
			}
		}

		// Sort tags alphabetically
		tags.sort();
	}

	// If tags are already retrieved, return them
	if (tags && retrievedTags?.[Array.isArray(tags) ? tags.join('-') : tags]?.length >= howMany) {
		return retrievedTags[Array.isArray(tags) ? tags.join('-') : tags].slice(0, howMany);
	}

	if (!tags && trendingTags?.length >= howMany) {
		return trendingTags.slice(0, howMany);
	}

	return request(`/project/recommendTags/${howMany}/${tags || ''}`, 'GET')
		.then((data) => {
			const useData = data?.data ?? data ?? [];

			if (tags) {
				retrievedTags[Array.isArray(tags) ? tags.join('-') : tags] = useData;
			} else if (!tags) {
				trendingTags = useData;
			}

			return useData;
		})
		.catch((err) => {
			console.error(err);
			return [];
		});
}

/**
 * Get showcase tags (~55% featured tags, ~45% trending tags)
 * @param {Number} [howMany=9] How many tags to return (default 9)
 * @returns {Array} List of showcase tags
 * @example <caption>Use this function to obtain a list of showcase tags. If in the instance that there are less than ~55% of howMany featured tags, the remaining will be trending tags</caption>
 * getShowcaseTags(9)
 * // returns ["Landscape","Environment","vehicle","tank","Military","Robotics","Human","Mannequin","Biped"]
 * @exports @async
 */
export async function getShowcaseTags(howMany: number | string = 9): Promise<any[]> {
	// Make sure howMany is a integer (number)
	howMany = parseInt(Number(howMany).toString(), 10);

	return request(`/project/getShowcaseTags/${howMany}`, 'GET')
		.then((data) => data.data)
		.catch((err) => {
			console.error(err);
			return [];
		});
}

/**
 * Fetches a list of featured tags.
 * @async @function
 * @returns {Promise<Array<string>>} An array of tag names.
 */
export const getFeaturedTags = memoize(
	async (
		/** The number of tags to fetch */
		howMany: number | string = 5,
	): Promise<Array<string>> => {
		// Make sure howMany is an integer (number)
		howMany = parseInt(Number(howMany).toString(), 10);

		try {
			const { data } = await request(`/project/getFeaturedTags/${howMany}`, 'GET');
			return data;
		} catch (err) {
			console.error(err);
			return [];
		}
	},
);

let trendingProjects = {};
export async function getTrendingTagsAndProjects() {
	if (isEmpty(trendingProjects)) {
		// First check if there are any trending tags in the session storage
		if (sessionStorage) {
			const trendingTags = sessionStorage.getItem('mpx-trendingTags');
			if (trendingTags) {
				trendingProjects = JSON.parse(trendingTags);

				return trendingProjects;
			}
		}

		// If there are no trending tags in the local storage, get them from the server
		try {
			const data = await request(
				urls.api.assets.public.getTrendingTags(store.get(inAppBrowserAtom) ? 6 : 18),
				'GET',
			);

			trendingProjects = data.data;

			// Save trending tags to session storage
			if (sessionStorage) {
				sessionStorage.setItem('mpx-trendingTags', JSON.stringify(trendingProjects));
			}

			return trendingProjects;
		} catch (error) {
			console.error(error);
		}
	}

	return trendingProjects;
}
