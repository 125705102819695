export enum CookieNames {
	/** Is true when website is running in-app */
	IN_APP = 'In-App',
	/** The in-app version MPX */
	IN_APP_VERSION = 'app_version',

	/** If it is available that means user has entered the password for site and has access to the site */
	HAS_STAGING_ACCESS = 'next-password-protect',

	/** Authentication token to use with API */
	JWT = 'jwt',

	/** Refresh token to use with API and get new JWT */
	REFRESH_TOKEN = 'refresh-token',

	/** Whether it is the user's first project or not */
	FIRST_PROJECT = 'first_project',

	/** Data sent from the VR app to the web browser */
	APP_DATA = 'app_data',

	/** UserID used for analytics purposes by GTM */
	USER_ID = 'user_id',

	FORCE_STATE = 'forceState',
}
