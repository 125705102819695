import { updateProjectTags } from '@mpx-sdk/helpers/assets';
import { sanitizeString } from '@mpx-sdk/helpers/input';
import { adminFeaturesAtom, singleAssetViewAtom, userAtom, userRolesAtom } from '@mpx-sdk/shared/atoms';
import { PublicAsset } from '@mpx-sdk/types';
import LinkWrapper from '@mpx-sdk/ui/components/core/LinkWrapper';
import AddTags from '@mpx-sdk/ui/components/single-asset-view/components/AddTags';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { Chip, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { useState } from 'react';

export default function Tags() {
	const [displayMoreTags, setDisplayMoreTags] = useState(false);
	const [projectData, setProjectData] = useAtom(singleAssetViewAtom);
	const adminView = useAtomValue(adminFeaturesAtom);
	const currentUser = useAtomValue(userAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);

	async function handleUpdateTags(tag: string) {
		const sanitizedTag = sanitizeString(tag);

		const updatedProjectTags: any = await updateProjectTags(projectData, sanitizedTag);

		const newProjectData = {
			...projectData,
			tags: updatedProjectTags.tags ?? [],
		};

		setProjectData(newProjectData as PublicAsset);
	}

	return (
		<>
			<Typography
				gutterBottom
				sx={{
					marginTop: '3%',
				}}
				variant='h6'
			>
				Tags
			</Typography>

			{/* Display each tag as a button with class search-filters-tags-showcase */}
			<Grid alignItems='flex-start' container direction='row' justifyContent='flex-start' spacing={1}>
				{currentUser &&
					(currentUser?.id === projectData?.user?.id ||
						currentUser?.id === projectData?.owner?.id ||
						(currentUserRoles?.library && adminView)) && (
						<Grid key='tag-add-more' className='sav-search-filters-tags' item>
							<Chip
								key='add-more'
								className={`search-filters-tags-showcase ${
									currentUserRoles?.library && adminView && currentUser?.id !== projectData?.user?.id
										? 'admin-tag'
										: ''
								}`}
								clickable
								data-project-tag='asset-add-more'
								label={
									<Stack
										alignItems='center'
										direction='row'
										justifyContent='space-between'
										onClick={() => {
											setDisplayMoreTags?.(!displayMoreTags);
										}}
										spacing={1}
									>
										<AddIcon
											sx={{
												// If displayMoreTags is true, rotate the icon 45 degrees
												transform: displayMoreTags ? 'rotate(45deg)' : 'rotate(0deg)',
											}}
										/>

										{displayMoreTags ? 'Close tags' : 'Add tags'}
									</Stack>
								}
								sx={{ fontSize: 16 }}
							/>
						</Grid>
					)}

				{projectData?.tags?.map((tag: any) => (
					<Grid key={`tag-${tag}`} className='sav-search-filters-tags' item>
						<Chip
							key={tag}
							className={`search-filters-tags-showcase ${
								currentUserRoles?.library && adminView && currentUser?.id !== projectData?.user?.id
									? 'admin-tag'
									: ''
							} ${tag.toLowerCase().trim() === 'broken' ? 'broken-tag' : ''}  ${
								tag
									.toLowerCase()
									.trim()
									.replace(/[\s_-]/g, '')
									.includes('qatest')
									? 'qa-test-tag'
									: ''
							} ${tag.toLowerCase().trim() === 'nsfw' ? 'nsfw-tag' : ''}`}
							clickable
							data-project-tag={`asset-${tag}`}
							label={
								<Stack
									alignItems='center'
									direction='row'
									justifyContent='space-between'
									onClick={() => setProjectData(null)}
									spacing={1}
								>
									<LinkWrapper
										href={{
											pathname: '/library',
											query: { q: tag },
										}}
									>
										{tag}
									</LinkWrapper>

									{currentUser &&
										(currentUser?.id === projectData?.user?.id ||
											currentUser?.id === projectData?.owner?.id ||
											(currentUserRoles?.library && adminView)) && (
											<Tooltip
												arrow
												describeChild
												title={`Delete ${tag} from ${projectData?.title || 'Untitled asset'}`}
											>
												<CloseIcon
													onClick={async (event) => {
														event.stopPropagation();

														await handleUpdateTags(tag);
													}}
												/>
											</Tooltip>
										)}
								</Stack>
							}
							sx={{ fontSize: 16 }}
						/>
					</Grid>
				))}
			</Grid>

			{currentUser &&
				(currentUser?.id === projectData?.user?.id ||
					currentUser?.id === projectData?.owner?.id ||
					(currentUserRoles?.library && adminView)) && (
					<AddTags
						displayButton={false}
						displayMoreTags={displayMoreTags}
						onUpdate={async (tag: string) => {
							await handleUpdateTags(tag);
						}}
					/>
				)}
		</>
	);
}
