import Env from '@mpx-sdk/shared/configs/env';
import axios from 'axios';
import { SketchfabAuth } from '../auth';
import { DataLayer } from '../measurement';

export class SketchfabClient {
	accessToken: string | null = typeof window !== 'undefined' ? window.localStorage.getItem('sb_token') : null;

	authClient: SketchfabAuth;

	apiUrl: string;

	constructor() {
		this.apiUrl = 'https://api.sketchfab.com/v3';

		this.authClient = new SketchfabAuth({
			client_id: Env.SKETCHFAB_CLIENT_ID,
			hostname: 'sketchfab.com',
			redirect_uri: `${(typeof window !== 'undefined' && window.origin) || ''}/callback`,
		});
	}

	get axios() {
		return new axios.Axios({
			...axios.defaults,
			baseURL: this.apiUrl,
			headers: {
				Authorization: `Bearer ${this.accessToken}`,
			},
		});
	}

	/**
	 * Try getting the user's access token silently and if it fails then open a popup to authorize the user.
	 */
	authenticate() {
		if (!this.accessToken) {
			return this.authClient
				.loginWithPopup()
				.then(({ access_token: accessToken }) => {
					window.localStorage.setItem('sb_token', accessToken);
					console.log('Received sketchfab token with popup: ', accessToken);
					this.accessToken = accessToken;
					// Track the event
					DataLayer.triggerMeasurementEvent('sketchfabEvent', {
						event_name: 'sketchfab_authenticated',
					});
				})
				.catch((error) => {
					console.error('Failed to get sketchfab token with popup: ', error);
					// Track the error
					DataLayer.triggerErrorEvent('sketchfab_authenticated');
				});
		}

		return undefined;
	}

	requireLogin() {
		return this.authenticate();
	}

	search(options: any) {
		return this.axios.get('/search', {
			params: options,
		});
	}

	getMyModels() {
		return this.axios.get('/me/models');
	}

	downloadModel(uid: string) {
		this.requireLogin();
		return this.axios.get(`/models/${uid}/download`);
	}
}

export const sketchfabClient = new SketchfabClient();
