import { ChargebeePlans } from '@mpx-sdk/shared/configs';
import { Add, Remove } from '@mui/icons-material';
import { Box, Button, Card, Chip, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';

function CreditPlanCard({
	onButtonClick,
	isCurrentPlan,
	plan,
}: {
	onButtonClick: (
		/** Id of the plan that has been clicked */
		planId: string,
		quantity: number,
	) => void;
	isCurrentPlan?: boolean;
	plan: (typeof ChargebeePlans.GENAI)[keyof typeof ChargebeePlans.GENAI];
}) {
	const [quantity, setQuantity] = useState(1);
	const { credits, discountedPrice, highlight, id, isSubscription, originalPrice } = plan;
	const handleIncreaseQuantity = () => {
		setQuantity((prev) => prev + 1);
	};

	const handleDecreaseQuantity = () => {
		setQuantity((prev) => (prev > 1 ? prev - 1 : prev));
	};

	return (
		<Card
			border={1}
			component={Stack}
			height='100%'
			spacing={1}
			sx={{
				px: 4,
				bgcolor: 'transparent',
				position: 'relative',
				pt: 1,
				pb: 4,
			}}
		>
			<Box textAlign={highlight?.includes('Most') ? 'center' : 'right'}>
				{highlight ? (
					<Chip label={highlight} sx={{ mb: 2, bgcolor: '#004032', color: '#99FFE9', fontWeight: 'bold' }} />
				) : (
					<br />
				)}
			</Box>
			Credits
			<Stack alignItems='center' direction='row' sx={{ textAlign: 'center', flexGrow: 1 }}>
				<Typography color='primary' fontWeight='bold' variant='h2'>
					{credits * quantity}
				</Typography>
				<Stack
					ml={2}
					sx={{
						display: !isSubscription ? 'block' : 'none',
						position: 'absolute',
						left: '10rem',
					}}
				>
					<IconButton onClick={handleIncreaseQuantity}>
						<Add />
					</IconButton>
					<br />
					<IconButton onClick={handleDecreaseQuantity}>
						<Remove />
					</IconButton>
				</Stack>
			</Stack>
			<div>
				<span>
					<Typography component='p' variant='h4'>
						${((discountedPrice || originalPrice) * quantity).toFixed(2)}
						<Typography component='span' variant='h5'>
							{isSubscription && '/month'}
						</Typography>
					</Typography>
				</span>
				{discountedPrice && (
					<Typography color='text.disabled' component='s' textAlign='left'>
						Was ${originalPrice} /month
					</Typography>
				)}
				{!isSubscription && (
					<Typography color='text.disabled' textAlign='left'>
						Switch to Monthly and Save
					</Typography>
				)}
			</div>
			<br />
			<Button
				color='primary'
				fullWidth
				onClick={() => {
					onButtonClick(id, quantity);
				}}
				sx={{
					justifyContent: 'center',
					margin: 'auto',
					py: 1.5,
				}}
				variant='contained'
			>
				{isCurrentPlan ? 'Your Current Plan' : 'Buy Now'}
			</Button>
		</Card>
	);
}

export default CreditPlanCard;
