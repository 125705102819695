import { getCloudPrivateProjectData, getUserData } from '@mpx-sdk/helpers/assets';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { openSlotsDialog, projectExportsAtom, slotsType, userAtom, userSlotsAtom } from '@mpx-sdk/shared/atoms';
import { AssetType } from '@mpx-sdk/types';
import LinkWrapper from '@mpx-sdk/ui/components/core/LinkWrapper';
import {
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useRef, useState } from 'react';

interface ExportDialogProps {
	isOpen?: boolean;
	onClose?: () => void;
}

/** Dialog that allows user to export a project to the community or to their private projects */
export default function ExportDialog({ isOpen, onClose }: ExportDialogProps): ReactElement {
	const router = useRouter();
	const { query } = router;

	const currentUser = useAtomValue(userAtom);
	/** Used to determine if the dialog is open or not. */
	const [open, setOpen] = useState<boolean>(isOpen ?? false);
	/** Used to determine the name of the project. */
	const [name, setName] = useState('Untitled Project');
	/** Used to determine the destination of the project. */
	const [destination, setDestination] = useState<'community' | 'private'>('community');

	const [loading, setLoading] = useState(false);

	const setProjectExports = useSetAtom(projectExportsAtom);

	// Slots related information
	const slots = useAtomValue(userSlotsAtom);
	const setSlotsType = useSetAtom(slotsType);
	const setOpenMaxSlots = useSetAtom(openSlotsDialog);

	const vuplexListenerAttached = useRef(false);

	/**
	 * Fetches project details for the specified destination and ID.
	 * @returns A Promise that resolves to the project details.
	 */
	async function getProjectDetails(
		/** The destination of the project ('community' or 'private'). */
		destination: 'community' | 'private',
	) {
		let newProjectData: any = null;

		if (destination === 'community') {
			newProjectData = await getUserData(currentUser?.id, 1);
		} else if (destination === 'private' && currentUser?.auth0Id) {
			newProjectData = await getCloudPrivateProjectData(
				currentUser,
				{
					limit: 1,
					sort: 'new',
				},
				true,
				false,
			);
		}

		if (newProjectData) {
			const exportedData: any = newProjectData?.[0] ?? newProjectData;
			exportedData.destination = destination;
			exportedData.progress = 100;
			exportedData.tooltip =
				destination === 'community' ? (
					<Typography>
						Here&apos;s the <b>Public Asset</b> you just made.
						<br />
						<br />
						Your asset will be available to remix once it has been finished processing.
						<br />
						<br />
						Remember, you can find your <b>Public Assets</b> by clicking your avatar and selecting{' '}
						<b>View Profile</b>.
					</Typography>
				) : (
					<Typography>
						Your <b>Private Assets</b> are saved here
					</Typography>
				);

			localStorage.removeItem('mpx-export');
			setProjectExports(exportedData);
		}
	}

	/**
	 * Adds a listener to `window.vuplex` object's 'message' event and if the 'export' type message contains a truthy 'open' property, sets the open state to true as well as other properties if they given
	 */
	function vuplexListener() {
		if (typeof window !== 'undefined' && window?.vuplex && !vuplexListenerAttached.current) {
			vuplexListenerAttached.current = true;
			window.vuplex.addEventListener('message', (event) => {
				let vuplexData = event.data;

				if (typeof vuplexData === 'string') {
					try {
						// Replace single quotes with double quotes to make it valid JSON
						let tempVuplexData = vuplexData.replace(/'/g, '"');
						tempVuplexData = JSON.parse(tempVuplexData);

						if (tempVuplexData) {
							vuplexData = tempVuplexData;
						}
					} catch (error) {
						// Do nothing but log the error
						console.error(error);
					}
				}

				console.log(
					`Vuplex data received: (type: ${typeof vuplexData}, if string, won't do anything - ensure you are sending a valid parsable JSON data)`,
					vuplexData,
				);

				if (vuplexData.type === 'export' && vuplexData.data) {
					if (!vuplexData.data.destination) {
						// Check if the mpx-export is not already defined, if not then
						// Check URL for destination, /library is community, /storage is private
						const exportData = localStorage.getItem('mpx-export');

						if (!exportData) {
							if (window.location.pathname.includes('/library')) {
								setDestination('community');
							} else if (window.location.pathname.includes('/storage')) {
								setDestination('private');
							}
							vuplexData.data.destination = destination;
						} else {
							vuplexData.data.destination = JSON.parse(exportData).destination;
						}
					}

					if (vuplexData.data.progress === 'start' || vuplexData.data.open) {
						setOpen(true);

						if (vuplexData.data?.title) {
							setName(vuplexData.data.title);
						}
						if (vuplexData.data?.destination) {
							setDestination(vuplexData.data.destination);
						}
					} else if (vuplexData.data.progress === 'complete') {
						// Handle redirect to appropriate page with highlight
						getProjectDetails(vuplexData.data.destination);
						setOpen(false);
					} else if (vuplexData.data.progress === 'error') {
						// Handle error
						window.vuplex.postMessage({
							type: 'export',
							data: {
								title: '',
								destination: '',
								cancelled: true,
								error: true,
							},
						});

						setOpen(false);
					} else if (vuplexData.data.progress ?? vuplexData.data.progress === 0) {
						// Check if progress is a number
						const progress = Number(vuplexData.data.progress);

						if (!Number.isNaN(progress)) {
							if (progress >= 100) {
								getProjectDetails(vuplexData.data.destination);
							} else if (progress >= 0) {
								// Update progress bar by updating local storage item (mpx-export)
								const exportData = {
									destination: vuplexData.data.destination,
									id: 'export',
									progress,
									projectId: vuplexData.data.id || null,
									time: Date.now(),
									title: `Exporting . . .`,
								};
								localStorage.setItem('mpx-export', JSON.stringify(exportData));
								setProjectExports(exportData);
							}
						}

						setOpen(false);
					}
				}
			});
		}
	}

	const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value);
	};

	const handleDestinationChange = (event: SelectChangeEvent<string>) => {
		const value = event.target.value as 'community' | 'private';

		if (value && ['community', 'private'].includes(value)) {
			setDestination(value);
		}
	};

	const handleClose = () => {
		// Reset state
		setOpen(false);
		setName('Untitled Project');
		setDestination('community');
		setLoading(false);
		// Remove export query param
		const url = new URL(window.location.href);
		if (url.searchParams.has('export')) {
			url.searchParams.delete('export');

			if (typeof window !== 'undefined' && window.history) {
				window.history.replaceState({}, '', url.toString());
			}
		}
		// Tell Vuplex that process has been cancelled
		if (window?.vuplex) {
			window.vuplex.postMessage({
				type: 'export',
				data: {
					title: '',
					destination: '',
					cancelled: true,
				},
			});
		}

		// If onClose is passed in, call it
		onClose?.();
	};

	const handleConfirm = () => {
		if (destination === 'private' && slots) {
			if (slots.slotsPrivateExportsUsed >= slots.slotsPrivateExports) {
				setOpenMaxSlots(true);
				setSlotsType(AssetType.IMPORT);
				setOpen(false);

				return;
			}
		}

		// Send data tracker event
		DataLayer.triggerMeasurementEvent('assetEvent', {
			destination,
			event_name: `project_export_${destination}`,
			title: name,
			userId: currentUser?.id,
		});

		if (window?.vuplex && !isEmpty(name) && destination) {
			window.vuplex.postMessage({
				type: 'export',
				data: {
					title: name,
					destination,
				},
			});

			setLoading(true);

			// Create local storage item to store export data in case of refresh or page close
			const exportData = {
				destination,
				id: 'export',
				progress: 0,
				projectId: null,
				time: Date.now(),
				title: `Exporting . . .`,
			};
			localStorage.setItem('mpx-export', JSON.stringify(exportData));
			setProjectExports(exportData);

			// Redirect page is necessary. Destination 'community' will redirect to the library page and destination 'private' will redirect to the storage page. Only redirect if the user is not already on the page.
			setTimeout(() => {
				// Set timeout is used to delay the redirect so that the user can see the loading indicator

				if (destination === 'community' && router.pathname !== '/library') {
					router.push('/library?export=inProgress');
				} else if (destination === 'private' && router.pathname !== '/storage') {
					router.push('/storage?tab=my-private-assets&export=inProgress');
				}

				handleClose();
			}, 1000);
		}
	};

	useEffect(() => {
		if (query?.export === 'true' && !open) {
			setOpen(true);

			if (query?.title) {
				setName(query.title as string);
			}
			if (query?.destination) {
				setDestination(query.destination as 'community' | 'private');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	useEffect(() => {
		if (open && slots && slots?.slotsPrivateImportsUsed >= slots?.slotsPrivateImports) {
			setOpen(false);
			setOpenMaxSlots(true);
			setSlotsType(AssetType.IMPORT);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	useEffect(() => {
		if (window?.vuplex) {
			vuplexListener();
		} else {
			window.addEventListener('vuplexready', vuplexListener);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			sx={{
				fontSize: '1.375rem',
				'.MuiDialog-paper': {
					backgroundColor: 'background.paper',
				},
			}}
		>
			<DialogTitle
				sx={{
					fontSize: '2.25rem',
					fontWeight: '500',
					textAlign: 'center',
				}}
			>
				Export
			</DialogTitle>
			<DialogContent
				sx={{
					overflowX: 'hidden',
				}}
			>
				<Typography
					sx={{
						marginBottom: '3%',
					}}
				>
					{currentUser
						? 'Name your new Asset and choose where to share it.'
						: 'You must be logged in to export your project.'}
				</Typography>

				{currentUser && (
					<>
						<FormControl fullWidth margin='normal'>
							<TextField
								InputProps={{
									sx: {
										backgroundColor: '#fff !important',
									},
								}}
								onChange={handleNameChange}
								placeholder='Name your new Asset'
								sx={{
									backgroundColor: '#fff',
									borderRadius: '30px',
									border: 'none !important',
									'.MuiInputBase-root': {
										borderRadius: '30px',
									},
									input: {
										color: '#000',
									},
								}}
								value={name}
							/>
						</FormControl>

						<FormControl fullWidth margin='normal'>
							<Select
								onChange={handleDestinationChange}
								sx={{
									backgroundColor: 'primary.main',
									borderRadius: '30px',
									color: 'primary.contrastText',
									svg: {
										color: 'primary.contrastText',
									},
								}}
								value={destination}
							>
								<MenuItem value='community'>Share To Community Library</MenuItem>
								<MenuItem value='private'>Save to my Private Assets</MenuItem>
							</Select>
						</FormControl>

						<Stack
							alignItems='center'
							direction='row'
							justifyContent='space-evenly'
							spacing={2}
							sx={{
								margin: '16px auto 8px',
								button: {
									padding: '1.5% 15%',
								},
							}}
						>
							{!loading ? (
								<>
									<Button
										onClick={handleClose}
										sx={{
											backgroundColor: 'secondary.main',
											color: 'secondary.contrastText',
											'&:hover': {
												backgroundColor: 'secondary.light',
											},
										}}
										variant='contained'
									>
										Cancel
									</Button>

									<Button
										onClick={handleConfirm}
										sx={{
											backgroundColor: 'primary.main',
											'&:hover': {
												backgroundColor: 'primary.light',
											},
										}}
										variant='contained'
									>
										{destination === 'community' ? 'Share' : 'Confirm'}
									</Button>
								</>
							) : (
								<Button
									disabled
									sx={{
										backgroundColor: 'primary.light',
										color: 'primary.contrastText',
										display: 'flex',
										justifyContent: 'center',
										width: '100%',
										'& .MuiCircularProgress-root': {
											color: 'primary.main',
										},
									}}
									variant='contained'
								>
									<CircularProgress size={24} />
								</Button>
							)}
						</Stack>

						{!isEmpty(destination) && (
							<Typography
								component='div'
								sx={{
									margin: '3% auto',
								}}
							>
								Your remixed asset will be:
								{destination === 'community' ? (
									<>
										<ul>
											<li>
												Released to the public under the Creative Commons Public Domain (CC0)
											</li>
											<li>
												Anyone can freely download, remix, or use, for any purpose by anyone
											</li>
											<li>This process cannot be undone</li>
										</ul>

										<span>
											Learn more about the Creative Commons Public Domain (CC0) here:{' '}
											<LinkWrapper
												href='https://creativecommons.org/publicdomain/zero/1.0/'
												passHref
											>
												<div
													style={{
														color: 'primary.main',
														cursor: 'pointer',
														fontSize: 'clamp(0.7rem, 1rem, 1rem)',
													}}
												>
													creativecommons.org/publicdomain/zero/1.0/
												</div>
											</LinkWrapper>
										</span>
									</>
								) : (
									<ul>
										<li>Available only to you, under the Storage section of the app</li>
										<li>
											Available to export as a .GLB file to your desktop or an online platform
										</li>
									</ul>
								)}
							</Typography>
						)}
					</>
				)}
			</DialogContent>
		</Dialog>
	);
}
