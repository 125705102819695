import BadWords from 'bad-words';
import { isEmpty } from 'lodash';

export * from './sanitize';

const filter = new BadWords();

const allowedBadWords = ['bloody'];
filter.removeWords(...allowedBadWords);

/**
 * Filter out mature words
 * @returns {String} Filtered text
 */
export function matureWordsFilter(
	/** Text to filter */
	text: string,
): string {
	if (typeof text === 'string') {
		text = text.trim();
	}

	if (!text || typeof text !== 'string' || isEmpty(text)) {
		return text;
	}

	try {
		return filter?.clean(text);
	} catch (error) {
		console.error(error);
		return text;
	}
}

export function isWordMature(
	/** Text to check */
	text: string,
) {
	return filter.isProfane(text);
}
