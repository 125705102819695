import { updateProjectShareCounter } from '@mpx-sdk/helpers/assets';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { singleAssetViewAtom } from '@mpx-sdk/shared/atoms';
import {
	Email as EmailIcon,
	Facebook as FacebookIcon,
	LinkedIn as LinkedInIcon,
	Pinterest as PinterestIcon,
	Reddit as RedditIcon,
	Share as ShareIcon,
	Twitter as TwitterIcon,
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useAtomValue } from 'jotai';
import { ReactElement, useMemo } from 'react';

interface SocialSharingIconsProps {
	social: string;
	shareLink: string;
	shareTitle?: string;
	shareDescription?: string;
	projectId?: number;
}

export default function SocialSharingIcons({
	shareLink = document?.location?.href,
	shareTitle,
	social,
	shareDescription,
	projectId,
}: SocialSharingIconsProps): ReactElement | null {
	const projectData = useAtomValue(singleAssetViewAtom);

	shareLink = encodeURIComponent(shareLink);
	shareTitle = encodeURIComponent(shareTitle ?? projectData?.title ?? document?.title ?? 'Untitled Project');
	shareDescription = encodeURIComponent(shareDescription ?? projectData?.description ?? document?.title);

	/** Each social's details */
	const iconDetails = useMemo(
		() => ({
			facebook: {
				title: 'Facebook',
				hoverColour: '#3b5998',
				icon: <FacebookIcon />,
				link: `https://www.facebook.com/sharer/sharer.php?u=${shareLink}`, // Old way to share
				// link: `https://www.facebook.com/dialog/share?
				// 		display=popup
				// 		&href=${shareLink}
				// 		&redirect_uri=${shareLink}`, // New way to share
			},
			twitter: {
				title: 'Twitter',
				hoverColour: '#00acee',
				icon: <TwitterIcon />,
				link: `https://twitter.com/intent/tweet?
					text=${`${shareTitle} - ${shareDescription}`}
					&via=withMPStudio
					${projectData?.tags ? `&hashtags=${projectData.tags.join(',')}` : ''}
					&url=${shareLink}`,
			},
			linkedin: {
				title: 'LinkedIn',
				hoverColour: '#0e76a8',
				icon: <LinkedInIcon />,
				// link: `https://www.linkedin.com/sharing/share-offsite/?url=${shareLink}`, // Old way to share
				link: `https://www.linkedin.com/shareArticle?
					url=${shareLink}
					&title=${shareTitle}
					&summary=${shareDescription}
				`,
			},
			pinterest: {
				title: 'Pinterest',
				hoverColour: '#E60023',
				icon: <PinterestIcon />,
				link: `https://pinterest.com/pin/create/button/?url=${shareLink}&media=&description=${shareTitle}`,
			},
			reddit: {
				title: 'Reddit',
				hoverColour: '#FF4300',
				icon: <RedditIcon />,
				link: `https://reddit.com/submit?url=${shareLink}&title=${shareTitle}`,
			},
			email: {
				title: 'Email',
				hoverColour: '#004f9f',
				icon: <EmailIcon />,
				link: `mailto:?subject=${shareTitle}&body=${shareLink}`,
			},
			navigator: {
				title: 'Navigator',
				hoverColour: '#0eac95',
				icon: <ShareIcon />,
				onClick: async () => {
					if (navigator?.share && typeof navigator.share === 'function') {
						await navigator
							.share({
								text: shareDescription ?? '',
								title: shareTitle ?? 'Masterpiece X',
								url: shareLink ?? 'https://app.masterpiecex.com/',
							})
							.catch(() => {
								// Send analytics event
								DataLayer.triggerMeasurementEvent('share', {
									event_name: 'share_cancel',
									share_link: shareLink ?? 'https://app.masterpiecex.com/',
									share_title: shareTitle ?? 'Masterpiece X',
									share_navigator: true,
									share_method: 'navigator',
								});
							});
					}
				},
				hide: Boolean(typeof navigator === 'undefined' || typeof window === 'undefined'),
			},
		}),
		[projectData?.tags, shareDescription, shareLink, shareTitle],
	);

	if (social) {
		if (iconDetails?.[social]?.icon) {
			/** Social media icon */
			const SocialIcon: ReactElement = iconDetails[social].icon;

			return (
				<Tooltip
					arrow
					describeChild
					title={social === 'email' ? `Share via email` : `Share to ${iconDetails[social].title}`}
				>
					<IconButton
						aria-label={`Share to ${social}`}
						className={`social-sharing-icons social-sharing-icon-${social}`}
						onClick={() => {
							// If ID is given, also increment share counter for project
							if (projectId) {
								updateProjectShareCounter(Number(projectId));
							}

							// Open the link in a new tab
							if (iconDetails[social].link) {
								window.open(iconDetails[social].link, '_blank', 'noopener,noreferrer');
							}

							// If onClick is given, call it
							if (iconDetails[social].onClick) {
								iconDetails[social].onClick();
							}

							// Send analytics event
							DataLayer.triggerMeasurementEvent('share', {
								event_name: 'share_open',
								share_link: iconDetails[social].link,
								share_title: iconDetails[social].title,
								share_navigator: Boolean(
									navigator?.userAgent?.match(
										/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Oculus/i,
									) ||
										(typeof window !== 'undefined' &&
											window.matchMedia('(display-mode: standalone)').matches),
								),
								share_method: social,
							});
						}}
						sx={{
							'&:hover': {
								backgroundColor: iconDetails[social].hoverColour,
							},
						}}
					>
						{SocialIcon}
					</IconButton>
				</Tooltip>
			);
		}
	}

	return null;
}
