import Env from '@mpx-sdk/shared/configs/env';
import { AssetId } from '@mpx-sdk/types';

/**
 * The purpose of this file is to centralize all the URLs used so it's easier to maintain
 */
export const urls = {
	routes: {
		home: '/',
		feedback: `${Env.MPX_WEBSITE}/feedback`,
		learn: `${Env.MPX_WEBSITE}/learn`,
		library: `${Env.MPX_WEBSITE}/library`,
		profile: `${Env.MPX_WEBSITE}/profile`,
		rooms: `${Env.MPX_WEBSITE}/rooms`,
		storage: `${Env.MPX_WEBSITE}/storage`,
		deviceLogin: `/login/device`,
		login: `/login`,
		genai: `${Env.MPX_GENAI_WEBSITE}/genai`,
		toProfileWithUsername: (username: string) => `${Env.MPX_WEBSITE}/user/${username}`,
		toSingleAsset: (id: AssetId) => `${Env.MPX_WEBSITE}/library/${id}`,
	},
	api: {
		base: Env.MPS_API_HOST,
		subscription: { getMySubscription: `/subscription` },
		account: {
			deleteProfile: `/me`,
			getMyBalance: `/me/balance`,
			getMyProfile: `/me`,
			updateAvatar: `/me/avatar`,
			updateProfile: `/me`,
			updateSocials: `/me/socials`,
			resendEmailVerification: '/users/verification-email',
		},
		users: {
			addUserSlots: (userId: number) => `/users/add-user-slots/${userId}`,
			getTopRemixers: (howMany: number) => `/users/top-remixers/${howMany}`,
			getUserByUsername: (username: string) => `/users/${username}`,
			getUserDetails: (userId: string | number) => `/users/user-details/${userId}`,
			updateSelfRoles: `/users/update-roles`,
			admin: {
				updateOtherUsers: (userId: number) => `/users/admin/update-user/${userId}`,
			},
		},
		team: {
			acceptInvitation: (token: string) => `/team/accept-invitation/${token}`,
			getMyTeams: `/team`,
			inviteMember: (id: string) => `/team/${id}/member`,
			removeMember: (teamId: string, memberId: string) => `/team/${teamId}/member/${memberId}`,
		},
		genai: {
			access: `/genai/access`,
			generate: `/genai/generate`,
			downloadGeneratedModel: (modelId: string) => `/genai/download/${modelId}`,
		},
		auth: { getFirebaseCustomToken: `/firebase/auth` },
		assets: {
			public: {
				deleteAsset: (id: AssetId) => `/project/deleteProject/${id}`,
				flagAsset: (id: AssetId) => `/project/flag/${id}`,
				getFeaturedTags: (howMany) => `/project/getFeaturedTags/${howMany}`,
				getFlaggedProjects: `/project/flagged`,
				getProjectById: (id: AssetId) => `/project/${id}`,
				getProjects: `/project`,
				getRecommendedTags: (howMany, tags = '') => `/project/recommendTags/${howMany}/${tags}`,
				getShowcaseTags: (howMany) => `/project/getShowcaseTags/${howMany}`,
				getTrendingTags: (howMany) => `/project/getTrendingTags/${howMany}`,
				getUserInteractedProjects: (userId, type) =>
					`/project/getAllProjectsUserInteractedWith/${userId}?type=${type}`,
				likedProjects: `/me/liked`,
				requestFileUpload: '/project',
				togglePublic: (id: AssetId) => `/project/toggle-public/${id}`,
				unflagAsset: (id: AssetId) => `/project/flag/${id}`,
				updateProject: (id) => `/project/${id}`,
				updateProjectAssetCounter: (projectId: number, type: 'downloads' | 'remixes') =>
					`/project/updateProjectAssetCounter/${projectId}?type=${type}`,
				updateProjectLikes: (projectId, type) => `/project/updateProjectLikes/${projectId}/${type}`,
				updateProjectShareCounter: (projectId) => `/project/updateProjectShareCounter/${projectId}`,
				updateProjectTags: (id, tag) => `/project/updateProjectTags/${id}/${tag}`,
				updateProjectViews: (projectId) => `/project/updateProjectViews/${projectId}`,
				updateSlots: (count) => `/me/slots/project-count/${count}`,
				updateThumbnail: (id: AssetId) => `/project/${id}/thumbnail`,
			},
			private: {
				deleteProject: (id: AssetId) => `/my-space/assets/${id}`,
				downloadProject: (id) => `/my-space/download/${id}`,
				getMyProjects: `/my-space/assets`,
				getSlots: `/me/slots`,
				getUserLikedProjects: (userId, projectIdList) =>
					`/project/getUserLikedProjects/${userId}?projectIds=${projectIdList}`,
				importSketchfabModel: `/my-space/import/sketchfab`,
				requestFileUpload: `/my-space/request-file-upload`,
				toggleFeaturedTags: (tag: string) => `/project/toggleFeaturedTags/${tag}`,
				updateProject: (id: AssetId) => `/my-space/assets/${id}`,
				updateThumbnail: (id: AssetId) => `/my-space/assets/${id}/thumbnail`,
			},
			admin: {
				updateProject: (id: AssetId) => `/project/admin/${id}`,
			},
		},
		roles: {
			createRole: (name: string) => `/roles/${name}`,
			deleteRole: (name: string) => `/roles/${name}`,
			editRoles: (name: string) => `/roles/${name}`,
			getRoleByUser: (userId: number) => `/roles/user/${userId}`,
			getRolesByEmail: (email: string) => `/roles/access/${email}`,
			getRoles: `/roles`,
		},
	},
	app: {
		mps: 'https://masterpiecestudio.com/studio',
		oculus: 'https://www.oculus.com/experiences/quest/5502306219889537',
		steam: 'https://steamcommunity.com/app/1165780',
	},
	zendesk: {
		base: `${Env.ZENDESK.url}/api/v2`,
		tickets: `/tickets.json`,
	},
};
