import { parseQueryString } from '@mpx-sdk/shared/utils';
import { nanoid } from 'nanoid';

export class SketchfabAuth {
	config: any;

	constructor(config: { client_id: string; redirect_uri: string; hostname?: string }) {
		if (!config.hostname) {
			config.hostname = 'sketchfab.com';
		}

		this.config = config;
	}

	getAuthorizeUrl(state?: string) {
		return [
			`https://${this.config.hostname}/oauth2/authorize/?`,
			state && `state=${state}`,
			'&response_type=token',
			`&client_id=${this.config.client_id}`,
			`&redirect_uri=${encodeURIComponent(this.config.redirect_uri)}`,
			'&approval_prompt=auto',
			'&silent=true',
		]
			.filter(Boolean)
			.join('');
	}

	getAccessTokenSilently() {
		// Open the authorizeUrl in side of iframe on current page, keep checking if the url of iframe changes to callback,if the access token is not available then throw error with code USER_LOGIN_REQUIRED
		return new Promise((resolve, reject) => {
			const authorizeUrl = this.getAuthorizeUrl();
			const authIframe = document.createElement('iframe');
			authIframe.src = authorizeUrl;
			authIframe.style.display = 'none';
			document.body.appendChild(authIframe);

			/** Keeps track of number of times iframe was polled */
			let counter = 0;
			const timer = setInterval(() => {
				counter += 1;
				// Timeout after 10 seconds
				if (counter > 10) clearInterval(timer);

				const iframeUrl = authIframe.src;
				if (iframeUrl.includes('access_token')) {
					clearInterval(timer);

					const hash = iframeUrl.substring(1);
					const queryString = parseQueryString(hash);
					const accessToken = queryString.access_token;

					if (accessToken) {
						resolve(accessToken);
					}

					reject(new Error('USER_LOGIN_REQUIRED'));
				}
			}, 1000);
		});
	}

	// TODO: add parameter for approval_prompt=auto to make it reusable
	// TODO: add parameter for window configs
	/**
	 * Opens a popup window to authorize the user. If user already has authorized before then it will just redirect to the callback and close popup.
	 *
	 */
	loginWithPopup(): Promise<SketchfabAuthSuccessResponse> {
		// loginWithPopup(): Promise<SketchfabAuthErrorResponse> {
		return new Promise((resolve, reject) => {
			if (!this.config.client_id) {
				reject(new Error('client_id is missing.'));
				return;
			}

			// @TODO: allow users to pass their own state
			const state = nanoid(25);
			const authorizeUrl = this.getAuthorizeUrl(state);

			// Open the window keep it's instance stored
			const loginPopup: Window | null = window.open(authorizeUrl, 'loginWindow', 'width=640,height=400');

			// Polling new window
			const timer = setInterval(() => {
				try {
					const url = loginPopup?.location.href;

					// User closed popup
					if (url === undefined || url === null) {
						clearInterval(timer);
						reject(new Error('Access denied (User closed popup)'));
						return;
					}

					// User canceled or was denied access
					if (url.indexOf('?error=access_denied') !== -1) {
						clearInterval(timer);
						reject(new Error('Access denied (User canceled)'));
						return;
					}

					// Worked?
					if (url.indexOf(this.config.redirect_uri) !== -1) {
						clearInterval(timer);

						if (loginPopup) {
							const { hash } = loginPopup.location;
							let grant;
							const accessTokenRe = /access_token=([^&]+)/;
							if (hash?.match(accessTokenRe)) {
								grant = parseQueryString(hash.substring(1));
								loginPopup.close();
								resolve(grant);
							} else {
								loginPopup.close();
								reject(new Error('Access denied (missing token)'));
							}
						}
					}
				} catch (e) {
					console.error(e);
				}
			}, 1000);
		});
	}
}
