import _ from 'lodash';

export default class Env {
	static readonly MPS_API_HOST = process.env.NEXT_PUBLIC_MPS_API_HOST;

	static readonly ZENDESK = {
		url: process.env.NEXT_PUBLIC_ZENDESK_URL,
		email: process.env.NEXT_PUBLIC_ZENDESK_EMAIL,
		apiKey: process.env.NEXT_PUBLIC_ZENDESK_API,
	};

	static readonly AUTH0 = {
		AUDIENCE: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
		DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
		CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
		BASE_URL: process.env.NEXT_PUBLIC_AUTH0_BASE_URL,
		IN_APP_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_IN_APP_CLIENT_ID,
		CLIENT_SECRET: process.env.AUTH0_CLIENT_SECRET,
	};
}

const optionalKeys = ['CLIENT_SECRET'];

// Make sure all env variables are set
Object.keys(Env).forEach((key) => {
	if (optionalKeys.includes(key)) return;

	if (_.isEmpty(Env[key])) {
		throw new Error(`Missing env variable ${key}`);
	} else if (_.isObject(Env[key])) {
		Object.keys(Env[key]).forEach((subKey) => {
			if (optionalKeys.includes(subKey)) return;

			if (_.isEmpty(Env[key][subKey])) {
				throw new Error(`Missing env variable ${key}.${subKey}`);
			}
		});
	}
});
