import { customAlphabet } from 'nanoid';

export const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890', 20);

/**
 * Generates random alphanumeric string of given length by nanoid library
 * @param length The length of the string to be generated. Default is 20
 * @returns Alphanumeric string
 */
export function nanoidLength(length = 20): string {
	return nanoid(length);
}
