const required = (value: any) => (value ? undefined : 'Required');

const mustBeNumber = (value: any) => (Number.isNaN(value) ? 'Must be a number' : undefined);

const minValue = (min: any) => (value: any) =>
	Number.isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;

const maxWords = (min: any) => (value: any) => {
	if (value && value.trim().split(/\s+/).length > min) {
		return "Oops. Your prompt is over 5 words. You can still proceed, but our AI can give you better results if you simplify what you're making in fewer words.";
	}
	return undefined;
};

const composeValidators =
	(...validators: any) =>
	(value: any) =>
		validators.reduce((error: any, validator: any) => error || validator(value), undefined);

const minLength = (min: number) => (value: string) =>
	value.length < min ? `Must be ${min} characters or more` : undefined;

const mustBePostalCode = (value: string) => {
	// Must match canadian postal code format
	const postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
	return postalCodeRegex.test(value) ? undefined : 'Must be a valid postal code in format A5A 5A5';
};

const mustBeEmail = (value: string) => {
	// Must match email format
	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return emailRegex.test(value) ? undefined : 'Must be a valid email address';
};

export const validators = {
	required,
	minWords: maxWords,
	mustBeNumber,
	minValue,
	composeValidators,
	minLength,
	mustBePostalCode,
	mustBeEmail,
};
