import { DataLayer } from '../measurement';
import { request } from './request';

export async function inviteTeamMembers(teamId: number, emails: string | string[]) {
	if (typeof emails === 'string') {
		emails = [emails];
	}

	const res = await Promise.all(
		emails.map((member) =>
			request(
				`/team/${teamId}/member`,
				'POST',
				JSON.stringify({
					email: member.trim(),
					role: 'user',
				}),
			),
		),
	);

	return Promise.all(res.map((r: any) => r.data)).then((data) => {
		// Track the event
		DataLayer.triggerMeasurementEvent('teamEvent', {
			event_name: 'team_invite_members',
			team_id: teamId,
		});
		return data;
	});
}

export async function removeTeamMember(teamId: number, memberId: number) {
	// Track the event
	DataLayer.triggerMeasurementEvent('teamEvent', {
		event_name: 'team_remove_member',
	});
	return (await request(`/team/${teamId}/member/${memberId}`, 'DELETE')).data;
}

export const acceptMemberInvite = async (token) => {
	const res = await request(`/team/accept-invitation/${token}`, 'POST');

	if (res.status >= 200 && res.status < 300) {
		// Track the event
		DataLayer.triggerMeasurementEvent('teamEvent', {
			event_name: 'team_accept_invite',
		});
		return res.data;
	}
	DataLayer.triggerErrorEvent('team_accept_invite', res.status);

	return Promise.reject(new Error(res?.data?.message));
};
