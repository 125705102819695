import { isObject } from 'lodash';

/**
 * Converts object keys to camel case
 * Sometimes some APIs return keys in snake case or kebab case
 * We want to keep our code consistent and use camel case, use this function to convert keys to camel case
 */
export function convertKeysToCamelCase(obj: Record<string, any>) {
	if (Array.isArray(obj)) {
		return obj.map((item) => convertKeysToCamelCase(item));
	}

	if (isObject(obj)) {
		const newObj = {};
		Object.keys(obj).forEach((key) => {
			const newKey = key.replace(/([-_][a-z])/gi, ($1) => $1.toUpperCase().replace('-', '').replace('_', ''));
			newObj[newKey] = convertKeysToCamelCase(obj[key]);
		});
		return newObj;
	}

	return obj;
}
