import { CreditsIcon, PurchaseSuccessfulIcon } from '@mpx-sdk/images';
import { availableCreditsAtom } from '@mpx-sdk/shared/atoms';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { find } from 'lodash';
import { useDialogService } from '../core';

function PurchaseSuccess() {
	const { hideDialog } = useDialogService();
	const userCurrencies = useAtomValue(availableCreditsAtom);
	const userCredits = find(userCurrencies?.currencies, { currency: 'credits' })?.balance;

	return (
		<Stack alignItems='center' justifyContent='center' pt={4}>
			<PurchaseSuccessfulIcon sx={{ fill: 'none', width: '4rem', height: '4rem' }} />
			<Typography color='text.primary' fontWeight='bold' mt={2} variant='h4'>
				Purchase Successful!🎉
			</Typography>
			<Typography color='text.disabled' mt={2} variant='h6'>
				You now have:
			</Typography>
			<Typography component={Stack} direction='row' fontWeight='bold' mb={1} ml={-1} variant='h6'>
				{userCredits ? (
					<>
						<CreditsIcon sx={{ height: 30, width: 40 }} /> {userCredits}
					</>
				) : (
					<CircularProgress />
				)}
			</Typography>
			<Typography color='text.disabled' variant='subtitle1'>
				Credits
			</Typography>
			<Button
				onClick={() => {
					hideDialog();
				}}
				sx={{
					mt: 4,
					width: '50%',
					fontWeight: 700,
					background: (theme) => theme.palette.gradient.main,
					backgroundColor: 'original.main',
					fontSize: '1.1rem',
					'&:hover': {
						background: (theme) => theme.palette.gradient.hover,
					},
				}}
				variant='contained'
			>
				Start Generating
			</Button>
		</Stack>
	);
}

export default PurchaseSuccess;
