export default class Env {
	static MPS_API_HOST = process.env.NEXT_PUBLIC_MPS_API_HOST;

	static CB = {
		PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_CB_PUBLISHABLE_KEY,
		SITE: process.env.NEXT_PUBLIC_CB_SITE ?? process.env.NEXT_PUBLIC_MPS_CHARGEBEE_SITE,
	};

	static PASSWORD_PROTECTED_SITE = process.env.PASSWORD_PROTECTED_SITE === 'true';

	static ANALYTICS_ENABLED = process.env.NEXT_PUBLIC_ANALYTICS_ENABLED;

	static SKETCHFAB_CLIENT_ID = process.env.NEXT_PUBLIC_SKETCHFAB_CLIENT_ID;

	static FASTCOMMENTS = {
		API_KEY: process.env.FASTCOMMENTS_API_KEY ?? process.env.NEXT_PUBLIC_FASTCOMMENTS_API_KEY,
		CLIENT_ID: process.env.NEXT_PUBLIC_FASTCOMMENTS_CLIENT_ID,
	};

	static AUTH0 = {
		AUDIENCE: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
		BASE_URL: process.env.NEXT_PUBLIC_AUTH0_BASE_URL,
		CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
		CLIENT_SECRET: process.env.AUTH0_CLIENT_SECRET,
		DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
		IN_APP_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_IN_APP_CLIENT_ID,
	};

	static MPX_WEBSITE = process.env.NEXT_PUBLIC_MPX_WEBSITE;

	static MPX_GENAI_WEBSITE = process.env.NEXT_PUBLIC_MPX_GENAI_WEBSITE;

	static SENTRY = {
		DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
		ENV: process.env.NEXT_PUBLIC_SENTRY_ENV,
	};

	static FIREBASE_CONFIG = process.env.NEXT_PUBLIC_FIREBASE_CONFIG
		? JSON.parse(process.env.NEXT_PUBLIC_FIREBASE_CONFIG)
		: {
				apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
				appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
				authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
				databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
				messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
				projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
				storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
				// measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
		  };

	static ALGOLIA = {
		APP_ID: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
		SEARCH_KEY: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
	};

	static ZENDESK = {
		apiKey: process.env.NEXT_PUBLIC_ZENDESK_API,
		email: process.env.NEXT_PUBLIC_ZENDESK_EMAIL,
		url: process.env.NEXT_PUBLIC_ZENDESK_URL,
	};

	static get isProduction() {
		return process.env.NEXT_PUBLIC_SENTRY_ENV === 'production';
	}

	static COOKIE_DOMAIN = process.env.NEXT_PUBLIC_SENTRY_ENV === 'production' ? '.masterpiecex.com' : undefined;

	static setEnvVariables(env: NodeJS.ProcessEnv) {
		Env.MPS_API_HOST = env.NEXT_PUBLIC_MPS_API_HOST;

		Env.CB = {
			PUBLISHABLE_KEY: env.NEXT_PUBLIC_CB_PUBLISHABLE_KEY,
			SITE: env.NEXT_PUBLIC_CB_SITE ?? env.NEXT_PUBLIC_MPS_CHARGEBEE_SITE,
		};

		Env.PASSWORD_PROTECTED_SITE = env.PASSWORD_PROTECTED_SITE === 'true';

		Env.ANALYTICS_ENABLED = env.NEXT_PUBLIC_ANALYTICS_ENABLED;

		Env.SKETCHFAB_CLIENT_ID = env.NEXT_PUBLIC_SKETCHFAB_CLIENT_ID;

		Env.FASTCOMMENTS = {
			API_KEY: process.env.FASTCOMMENTS_API_KEY ?? process.env.NEXT_PUBLIC_FASTCOMMENTS_API_KEY,
			CLIENT_ID: env.NEXT_PUBLIC_FASTCOMMENTS_CLIENT_ID,
		};

		Env.AUTH0 = {
			AUDIENCE: env.NEXT_PUBLIC_AUTH0_AUDIENCE,
			BASE_URL: env.NEXT_PUBLIC_AUTH0_BASE_URL,
			CLIENT_ID: env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
			CLIENT_SECRET: env.AUTH0_CLIENT_SECRET,
			DOMAIN: env.NEXT_PUBLIC_AUTH0_DOMAIN,
			IN_APP_CLIENT_ID: env.NEXT_PUBLIC_AUTH0_IN_APP_CLIENT_ID,
		};

		Env.MPX_WEBSITE = env.NEXT_PUBLIC_MPX_WEBSITE;

		Env.MPX_GENAI_WEBSITE = env.NEXT_PUBLIC_MPX_GENAI_WEBSITE;

		Env.SENTRY = {
			DSN: env.NEXT_PUBLIC_SENTRY_DSN,
			ENV: env.NEXT_PUBLIC_SENTRY_ENV,
		};

		Env.FIREBASE_CONFIG = env.NEXT_PUBLIC_FIREBASE_CONFIG
			? JSON.parse(env.NEXT_PUBLIC_FIREBASE_CONFIG)
			: {
					apiKey: env.NEXT_PUBLIC_FIREBASE_API_KEY,
					appId: env.NEXT_PUBLIC_FIREBASE_APP_ID,
					authDomain: env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
					databaseURL: env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
					messagingSenderId: env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
					projectId: env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
					storageBucket: env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
					// measurementId: env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
			  };

		Env.ALGOLIA = {
			APP_ID: env.NEXT_PUBLIC_ALGOLIA_APP_ID,
			SEARCH_KEY: env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
		};

		Env.ZENDESK = {
			apiKey: env.NEXT_PUBLIC_ZENDESK_API,
			email: env.NEXT_PUBLIC_ZENDESK_EMAIL,
			url: env.NEXT_PUBLIC_ZENDESK_URL,
		};
	}
}
