/* eslint-disable no-nested-ternary */
import { convertBytesToSize } from '@mpx-sdk/helpers/assets';
import { matureWordsFilter } from '@mpx-sdk/helpers/input';
import { savEditMode, singleAssetViewAtom, userAtom } from '@mpx-sdk/shared/atoms';
import LinkWrapper from '@mpx-sdk/ui/components/core/LinkWrapper';
import EditText from '@mpx-sdk/ui/components/single-asset-view/components/EditText';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useAtomValue } from 'jotai';
import { isEmpty } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';

export default function Description(): ReactElement | null {
	const [createdAtDate, setCreatedAtDate] = useState<Date | null>(null);
	const [displayText, setDisplayText] = useState<any>(null);
	const [totalSize, setTotalSize] = useState<number>(0);
	const currentUser = useAtomValue(userAtom);
	const editMode = useAtomValue(savEditMode);
	const projectData = useAtomValue(singleAssetViewAtom);
	const themes = useTheme();

	function generateDisplayText() {
		let displayText: string | ReactElement = projectData?.description ?? 'No description provided';

		if (projectData?.description) {
			// Apply mature filter if applicable
			if (
				(currentUser?.matureFilter || !currentUser) &&
				typeof displayText === 'string' &&
				!isEmpty(displayText)
			) {
				displayText = matureWordsFilter(displayText);
			}

			// Check if there are any links in the description and if so, wrap with <LinkWrapper />
			const linkRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
			const linkMatches = displayText.match(linkRegex);

			if (linkMatches && typeof displayText === 'string') {
				const parts: (string | ReactElement)[] = [];
				let lastIndex = 0;

				// eslint-disable-next-line no-restricted-syntax
				for (const match of linkMatches) {
					const index = displayText.indexOf(match, lastIndex);
					if (index !== -1) {
						parts.push(displayText.substring(lastIndex, index));
						parts.push(
							<LinkWrapper key={match} href={match} openNewTab>
								{match}
							</LinkWrapper>,
						);
						lastIndex = index + match.length;
					}
				}

				if (lastIndex < displayText.length) {
					parts.push(displayText.substring(lastIndex));
				}

				// eslint-disable-next-line react/jsx-no-useless-fragment
				displayText = <>{parts}</>;
			}
		}

		setDisplayText(
			<Typography
				gutterBottom
				sx={{
					a: { color: `${themes.palette.text.action} !important` },
				}}
				variant='body1'
			>
				{!isEmpty(displayText) ? displayText : 'No description provided'}
			</Typography>,
		);
	}

	useEffect(() => {
		generateDisplayText();

		if (projectData?.createdAt) {
			setCreatedAtDate(new Date(projectData.createdAt));
		}

		if (projectData?.files) {
			setTotalSize(projectData.files?.reduce((acc, asset) => acc + (asset.size ?? 0), 0) ?? 0);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectData]);

	return projectData?.id ? (
		<Stack
			alignItems='flex-start'
			direction='column'
			justifyContent='flex-start'
			spacing={2}
			sx={{
				marginBottom: '1rem',
			}}
		>
			{editMode && (
				<Box
					sx={{
						width: '100%',
					}}
				>
					<Typography
						className='sav-section-headers'
						gutterBottom
						sx={{
							marginTop: '1rem',
						}}
						variant='h6'
					>
						Edit Title:
					</Typography>

					{editMode ? <EditText projectData={projectData} type='title' /> : displayText}
				</Box>
			)}

			{/* General description */}
			{(projectData?.type !== 'history' || projectData?.description) && (
				<Box
					sx={{
						width: '100%',
					}}
				>
					<Typography
						className='sav-section-headers'
						gutterBottom
						sx={{
							marginTop: '1rem',
						}}
						variant='h6'
					>
						Description:
					</Typography>

					{editMode ? <EditText projectData={projectData} type='description' /> : displayText}
				</Box>
			)}

			{/* Gen AI - Paint prompt */}
			{projectData?.metadata?.genAIData?.['Paint Looks Like'] && (
				<Box>
					<Typography
						className='sav-section-headers'
						gutterBottom
						sx={{
							marginTop: '1rem',
						}}
						variant='h6'
					>
						Paint prompt:
					</Typography>

					{projectData?.metadata?.genAIData?.['Paint Looks Like']}
				</Box>
			)}

			{/* General information */}
			<Stack alignItems='flex-start' direction='row' justifyContent='flex-start' spacing={2}>
				{projectData?.metadata?.genAIData?.Shape && (
					<>
						{/* Gen AI - Shape */}
						<Box>
							<Typography
								className='sav-section-headers'
								gutterBottom
								sx={{
									marginTop: '1rem',
								}}
								variant='h6'
							>
								Shape:
							</Typography>

							{projectData.metadata.genAIData.Shape}
						</Box>
					</>
				)}

				{/* Total size */}
				{totalSize > 0 && (
					<Box>
						<Typography
							className='sav-section-headers'
							gutterBottom
							sx={{
								marginTop: '1rem',
							}}
							variant='h6'
						>
							Size:
						</Typography>

						{convertBytesToSize(totalSize)}
					</Box>
				)}

				{/* Date */}
				{createdAtDate && (
					<Box>
						<Typography
							className='sav-section-headers'
							gutterBottom
							sx={{
								marginTop: '1rem',
							}}
							variant='h6'
						>
							Date:
						</Typography>

						{`${createdAtDate.getFullYear()}/${createdAtDate.getMonth() + 1}/${createdAtDate.getDate()}`}
					</Box>
				)}
			</Stack>
		</Stack>
	) : null;
}
