class API {
	apiBaseUrl: string;

	constructor() {
		this.apiBaseUrl = process.env.NEXT_PUBLIC_MPS_API_HOST || '';
	}

	request = async (accessToken: string, method: 'GET' | 'POST' | 'PUT', path: string, body?: any) => {
		try {
			let url = `${this.apiBaseUrl}${path}`;
			if (path === '/firebase/auth') {
				url = `${process.env.NEXT_PUBLIC_TOKEN_API_URL}${path}`;
			}
			const response = await fetch(url, {
				method,
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'Content-Type': path !== '/firebase/auth' ? 'application/json' : 'text/plain',
				},
				body: JSON.stringify(body),
			});

			const data = await response.json();

			return response.ok ? data : await Promise.reject(data);
		} catch (error) {
			console.error(error);
			return null;
		}
	};

	getFirebaseToken = async (accessToken: string) => {
		const data = await this.request(accessToken, 'GET', '/firebase/auth');

		const customToken = data?.token || data?.customToken;

		return customToken;
	};

	createPublicProject = async (accessToken: string, project: any) => {
		const response = await this.request(accessToken, 'POST', `/project`, project);
		return response;
	};

	createPrivateProject = async (accessToken: string, project: any) => {
		const response = await this.request(accessToken, 'POST', `/my-space/request-file-upload`, project);
		return response;
	};

	getPublicProject = async (accessToken: string, projectId: any) => {
		const response = await this.request(accessToken, 'GET', `/project/${projectId}`);
		return response;
	};

	getPrivateProject = async (accessToken: string, data: any) => {
		const response = await this.request(accessToken, 'GET', `/my-space/project?${new URLSearchParams(data)}`);
		return response;
	};

	updateProjectTag = async (accessToken: string, projectId: any, tag: string) => {
		const response = await this.request(accessToken, 'POST', `/project/updateProjectTags/${projectId}/${tag}`);
		return response;
	};

	getMyProfile = async (accessToken: string) => {
		const response = await this.request(accessToken, 'GET', `/me`);
		return response;
	};

	getMyRoles = async (accessToken: string, userId: string) => {
		const response = await this.request(accessToken, 'GET', `/roles/user/${userId}`);
		return response;
	};

	getMySlots = async (accessToken: string) => {
		const response = await this.request(accessToken, 'GET', `/me/slots`);
		return response;
	};

	checkAccessAllowed = async (accessToken: string, email: string) => {
		const response = await this.request(accessToken, 'GET', `/genai/access/${email}`);
		return response;
	};
}

const api = new API();
export default api;
