import Env from '@api-client/configs/env';
import BaseService from '@api-client/services/base.service';
import { urls } from '@mpx-sdk/shared/configs/urls';

class ZendeskService extends BaseService {
	async createTicket(ticketData: any) {
		// Construct the Zendesk API endpoint URL
		const zendeskUrl = urls.zendesk.base + urls.zendesk.tickets;

		try {
			// Make a POST request to create the ticket
			const response = await this.post(zendeskUrl, ticketData, {
				Authorization: `Basic ${Buffer.from(`${Env.ZENDESK.email}/token:${Env.ZENDESK.apiKey}`).toString(
					'base64',
				)}`,
			});
			return response;
		} catch (error) {
			// On failed request, log the data so Sentry can grab it and throw the error
			console.log('Zendesk failed data:', ticketData);
			// Handle any errors
			console.error('Error creating Zendesk ticket:', error);
			throw error;
		}
	}
}

const zendeskService = new ZendeskService();
export default zendeskService;
