import { getRelatedTags, updateProjectTags } from '@mpx-sdk/helpers/assets';
import { sanitizeString } from '@mpx-sdk/helpers/input';
import { adminFeaturesAtom, singleAssetViewAtom, userAtom, userRolesAtom } from '@mpx-sdk/shared/atoms';
import WarningSnackbar from '@mpx-sdk/ui/components/admin/WarningSnackbar';
import { Add as AddIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, Autocomplete, Button, TextField, Tooltip } from '@mui/material';
import { useAtomValue } from 'jotai';
import { isEqual } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';

interface AddTagsProps {
	/** Whether to display the more tags button or not */
	displayButton?: boolean;
	/** Whether to display the more tags or not */
	displayMoreTags?: boolean;
	/** The function to call when the project's tags are updated */
	onUpdate?: (...args: any) => void;
}

export default function AddTags({ displayButton = true, displayMoreTags, onUpdate }: AddTagsProps): ReactElement {
	const [open, setOpen] = useState(false);
	const [autofillOptions] = useState([]);
	/** Related tags to the project's tags */
	const [relatedTags, setRelatedTags] = useState<string[]>([]);
	/** Autocomplete's value */
	const [autocompleteValue, setAutocompleteValue] = useState('');

	const adminView = useAtomValue(adminFeaturesAtom);
	const currentUser = useAtomValue(userAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);
	const projectData = useAtomValue(singleAssetViewAtom);

	const [isAdmin, setIsAdmin] = useState(false);
	const [showAdminWarning, setShowAdminWarning] = useState(false);

	/**
	 * Find related tags to the project's current tags
	 * @param {Number} [howMany=5] The number of related tags to find
	 * @example <caption>Finds related tags (or trending tags if project has no existing tags) and recommends new tags for the project</caption>
	 * findRelatedTags();
	 * // returns null (does not return anything but update suggested tags in the project's modal)
	 * @async
	 * @see {@link getRelatedTags}
	 */
	async function findRelatedTags(howMany = 5) {
		/** Newly suggested tags based on existing tags for the project (no existing tags, gets trending tags) */
		let newRelatedTags = await getRelatedTags(projectData?.tags ?? '', howMany);

		// If not same as current related tags, then update
		if (Array.isArray(newRelatedTags) && !isEqual(relatedTags?.sort(), newRelatedTags?.sort())) {
			// Remove any related tags that contain the word broken in it, or qatest or qa-test
			newRelatedTags = newRelatedTags.filter(
				(tag) =>
					!tag.toLowerCase().includes('broken') &&
					!tag
						.toLowerCase()
						.replace(/[\s_-]/g, '')
						.includes('qatest'),
			);

			setRelatedTags(newRelatedTags);
		}
	}

	/**
	 * Adding (or removing if duplicate) tag from project
	 * @param {String} newTag The tag to add (or removing if duplicate) to the project
	 * @example <caption>Adding (or removing if duplicate) tag from project</caption>
	 * addNewTag('test')
	 * // returns null (does not return anything but instead add 'test' if not existing tag, or remove 'test' if duplicate)
	 * @see {@link sanitizeString}, {@link updateProjectTags}
	 */
	async function addNewTag(newTag: string, addTagElement: any = undefined) {
		/** Sanitized tag */
		const tag = sanitizeString(newTag);

		// If tag is not empty, then add it to the project's tags
		if (tag?.length > 2) {
			if (onUpdate) {
				await onUpdate(tag);
			} else {
				await updateProjectTags(projectData, tag);
			}

			if (addTagElement?.value) {
				// Emptying the input field
				addTagElement.value = '';
			}

			await findRelatedTags();
		}
	}

	useEffect(() => {
		if (currentUserRoles?.library && adminView && currentUser?.id !== projectData?.user?.id) {
			setIsAdmin(true);
		}
	}, [adminView, currentUser?.id, currentUserRoles?.library, projectData?.user?.id]);

	useEffect(() => {
		findRelatedTags();

		if (currentUserRoles?.library && adminView && currentUser?.id !== projectData?.user?.id) {
			setIsAdmin(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{isAdmin && (
				<WarningSnackbar
					isOpen={showAdminWarning}
					message='You are not the owner of this project. Be careful when adding thumbnails to this asset.'
					onClose={() => setShowAdminWarning(false)}
				/>
			)}
			{displayButton && (
				<Tooltip arrow describeChild title={`Add more tags to "${projectData?.title}"`}>
					<Button
						key={`${projectData?.id}-add-more-tags`}
						className={`search-filters-tags-showcase ${open ? 'tag-showcase-active' : ''} ${
							isAdmin ? 'admin-tag' : ''
						}`}
						data-project-tag={`${projectData?.id}-add-more-tags`}
						onClick={() => {
							setOpen(!open);

							if (isAdmin) {
								setShowAdminWarning(true);
							}
						}}
						onMouseEnter={() => {
							if (currentUserRoles?.library && adminView && currentUser?.id !== projectData?.user?.id) {
								setShowAdminWarning(true);
							}
						}}
						startIcon={<AddIcon />}
						sx={{
							marginTop: '5% !important',
						}}
					>
						<span>{open ? 'Close tags' : 'Add tags'}</span>
					</Button>
				</Tooltip>
			)}
			<Accordion
				expanded={displayMoreTags ?? open}
				sx={{
					display: displayMoreTags ?? open ? 'block' : 'none',
				}}
			>
				{/* Accordion details which will contain a close button, input to add new tags and recommended tags */}
				<AccordionDetails>
					{/* Header saying Add Tags */}
					<span>Add Tags</span>
					{/* Input to add new tags */}
					<Autocomplete
						className='sav-tags-autocomplete'
						freeSolo
						onKeyPress={(e) => {
							const target = e.target as HTMLInputElement;

							if (target?.value) {
								// If press enter
								if (e?.key === 'Enter') {
									addNewTag(target.value);

									// Emptying the input field
									setAutocompleteValue('');
								} else if (target.value !== autocompleteValue) {
									setAutocompleteValue(target.value);
								}
							}
						}}
						options={autofillOptions}
						renderInput={(params) => (
							<TextField
								{...params}
								aria-label='Add tags'
								margin='normal'
								placeholder='Add tags...'
								variant='outlined'
							/>
						)}
						value={autocompleteValue}
					/>

					{/* Suggesting new tags */}
					{relatedTags?.length > 0 ? (
						<>
							<br />
							{/* Suggesting new tags */}
							<span>Suggested Tags</span>
							<br />
							{/* Go through each suggested tag and add as a button */}
							{relatedTags?.map((tag) => (
								<Button
									key={tag}
									className={`search-filters-tags-showcase ${isAdmin ? 'admin-tag' : ''}`}
									data-project-tag={`${projectData?.id}-suggested-${tag}`}
									onClick={async () => {
										await addNewTag(tag);
									}}
									sx={{
										margin: '2% !important',
									}}
								>
									{tag}
								</Button>
							))}
						</>
					) : null}
				</AccordionDetails>
			</Accordion>{' '}
		</>
	);
}
